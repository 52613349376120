import React from 'react'
import { Card, CardActionArea, CardContent, Grid, withStyles } from '@material-ui/core'
import {
	darkB,
	negativeB,
	positiveB,
	primaryC,
	warningB,
	secondaryB,
	negativeC,
	positiveC,
	warningC,
	defaultB,
	primaryB,
	cloudB,
  greyB,
  greyC,
  primaryD,
  purpleC,
} from '../constants/colors'
import PropTypes from 'prop-types'
import '../fonts/style.css'
import CdaTypography from '../Typography/CdaTypography'

const styledBy = (property, mapping) => props => mapping[props[property]]

const styles = {
	root: {
		background: defaultB,
    padding: '0px',
		borderRadius: '8px',
    border: '1px solid ' + greyC,
    width: '310px'
	},
  content: {
    padding: '16px',
    paddingBottom: '16px !important'
  },
  icon: {
    padding: '8px',
    height: '48px',
    width: '48px',
		borderRadius: '8px',
    marginRight: '12px',
    background: styledBy('color', {
			classic: cloudB,
			primary: secondaryB,
			secondary: secondaryB,
			negative: negativeC,
			positive: positiveC,
			warning: warningC,
      purple: purpleC
		})
  }
}

const CdaCardTitle = withStyles(styles)(({ classes, color, icon, label, info, ...other }) => (
		<Card 
      clicable
			className={classes.root} 
			{...other} 
    >
      <CardActionArea>
        <CardContent className={classes.content}>
          <Grid container direction={'row'} justify={'flex-start'} alignItems={'center'}>
            {!!icon &&
              <Grid item container justify={'center'} alignItems={'center'} className={classes.icon}>
                {icon}
              </Grid>
            }
            <Grid item>
              {!!label &&
                <CdaTypography type={'bm'} label={label} style={{marginBottom: 8}}/>
              }
              {!!info &&
                <CdaTypography type={'label'} color={'grey'} label={info} />
              }
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
))

export default CdaCardTitle

CdaCardTitle.propTypes = {
	color: PropTypes.oneOf([
		'classic',
		'primary',
		'secondary',
		'negative',
		'positive',
		'warning',
    'purple'
	]),
  icon: PropTypes.node,
	label: PropTypes.string,
  info: PropTypes.string,
}
