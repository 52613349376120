import React from 'react'
import { Checkbox, FormControlLabel, makeStyles, withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { darkB, greyB } from '../constants/colors'
import CdaTypography from '../Typography/CdaTypography'

const styles = {
  root: {
    padding: 8,
    '&:hover': {
      backgroundColor: 'transparent !important',
    }
  },
  icon: {
    width: 16,
    height: 16,
    border: '1px solid' + darkB,
    borderRadius: 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    'input:disabled  ~ &': {
      border: '1px solid' + greyB,
    }
  },
  checkedIcon: {
    width: 16,
    height: 16,
    border: '1px solid #2B6BB2',
    borderRadius: 5,
    backgroundColor: '#2B6BB2',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    'input:disabled  ~ &': {
      border: '1px solid' + greyB,
      backgroundColor: greyB,
    }
  }
}
const StyledCheckbox = withStyles(styles)(({ classes, ...other }) => (
  <Checkbox
    {...other}
    className={classes.root}
    disableRipple
    checkedIcon={
      <span className={classes.checkedIcon}>
        <svg width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.66667 1.66699L3.70833 7.66699L1 4.93972" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>          
			</span>
    }
    icon={<span className={classes.icon} />}
  />
))

const useStyle=makeStyles(theme=>({
  root:{
    marginTop: '2px',
    cursor: 'pointer'
  }
}))
const CdaCheckbox = ({ label, ...props }) => {
  const classes=useStyle()
  return (
    <FormControlLabel
      classes={{label:classes.root}}
      control={<StyledCheckbox {...props} />} 
      label={
        <CdaTypography label={label} color={!!props.disabled ? 'grey' : 'classic'} type={'s'} style={{cursor: !props.disabled && 'pointer'}} />
      }
    />
  )
}

export default CdaCheckbox

CdaCheckbox.propTypes = {
  color: PropTypes.oneOf(['primary']),
  label: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func
}
