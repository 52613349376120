import React from 'react'

const IconFree = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M41.7265 20.874C43.4525 30.664 36.9145 40 27.1265 41.726C17.3365 43.452 8.00048 36.916 6.27448 27.126C4.54848 17.336 11.0845 7.99999 20.8745 6.27399C30.6645 4.54799 40.0005 11.084 41.7265 20.874" stroke="#31D08D" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M14.0898 8.99219L16.9818 13.3722" stroke="#31D08D" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M33.9096 39.0079L31.0176 34.6279" stroke="#31D08D" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M20.532 28.578C21.048 29.424 21.932 30.006 22.998 30.006H25.19C26.74 30.006 27.998 28.75 27.998 27.198C27.998 25.91 27.122 24.786 25.874 24.474L22.126 23.534C20.876 23.22 20 22.096 20 20.808C20 19.258 21.256 18 22.808 18H25C26.066 18 26.95 18.584 27.466 19.428" stroke="#31D08D" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M24 16V18" stroke="#31D08D" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M24 32V30" stroke="#31D08D" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
);

export default IconFree;
