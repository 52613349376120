import React from 'react'
import { darkB } from "../../../cda-UIkit/constants/colors"

const YouTube = ({size = '26', color = darkB, style}) => (
  <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.3359 17L17.2639 13L10.3359 9V17Z" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7 1H19.0053C22.316 1 25 3.684 25 6.99467V19.0067C25 22.316 22.316 25 19.0053 25H6.99467C3.684 25 1 22.316 1 19.0053V7C1 3.68667 3.68667 1 7 1V1Z" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>

);

export default YouTube;