import React, {useEffect, useState} from 'react'
import { Card, CardContent, Grid, withStyles } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import CdaTypography from '../cda-UIkit/Typography/CdaTypography';
import 'moment/locale/ru';
import { cloudB, defaultB, greyB, primaryC } from '../cda-UIkit/constants/colors';
import Footer from '../components/Footer';
import Instagram from '../img/icons/social/Instagram';
import Telegram from '../img/icons/social/Telegram';
import TikTok from '../img/icons/social/TikTok';
import YouTube from '../img/icons/social/YouTube';

const styles = {
	root: {
		background: defaultB,
    padding: '0px',
		borderRadius: '24px',
    border: 'none',
    width: '100%'
	},
  content: {
    padding: '16px',
    paddingBottom: '16px !important'
  }
}

const ContactPage = withStyles(styles)(({ classes, CW }) => {
  const { t } = useTranslation()

  useEffect(() => {
    window.scroll(0,0)
  }, [])

  return (
    <Grid container 
      direction={'column'} 
      alignItems={'center'} 
      justify={'space-between'}
      style={{minHeight: '100vh', width: '100vw'}}
    >
      <Grid item container 
        direction={'row'} 
        alignItems={'flex-start'} 
        justify={'center'}
        style={{padding: CW > 1070 ? '64px' : '56px 8px', overflowX: 'hidden', overflowY: 'hidden', maxWidth: 1600}}
        spacing={6}
      >
        <Grid item xs={12} md={12}>
          <CdaTypography type={'h2'} label={t('footer.contact')} style={{fontWeight: 600}} />
        </Grid>
        <Grid item container xs={12} md={12}
          direction={'row'}
          alignItems={'flex-start'}
          justify={'center'}
          style={{marginBottom: 24}}
        >
          <Grid item container xs={12} md={7}
            direction={'row'}
            alignItems={'flex-start'}
            justify={CW > 1070 ? 'center' : 'flex-start'}
            spacing={6}
            style={{marginBottom: 24}}
          >
            <Grid item container xs={12} md={6}
              direction={'row'}
              alignItems={'flex-start'}
              justify={'center'}
              spacing={2}
            >
              <Grid item xs={12} md={12}>
                <CdaTypography type={'l'} label={t('contactPage.globalMail')} />
              </Grid>
              <Grid item xs={12} md={12}>
                <CdaTypography type={'bm'} label={'academy@cda.kz'} style={{fontWeight: 600}} />
              </Grid>
            </Grid>
            <Grid item container xs={12} md={6}
              direction={'row'}
              alignItems={'flex-start'}
              justify={'center'}
              spacing={2}
            >
              <Grid item xs={12} md={12}>
                <CdaTypography type={'l'} label={t('contactPage.contactCentre')} />
              </Grid>
              <Grid item xs={12} md={12}>
                <CdaTypography type={'bm'} label={'+7 777 773 66 03'} style={{fontWeight: 600}} />
              </Grid>
            </Grid>
            <Grid item container xs={12} md={6}
              direction={'row'}
              alignItems={'flex-start'}
              justify={'center'}
              spacing={2}
            >
              <Grid item xs={12} md={12}>
                <CdaTypography type={'l'} label={t('contactPage.press')} />
              </Grid>
              <Grid item xs={12} md={12}>
                <CdaTypography type={'bm'} label={'press@cda.kz'} style={{fontWeight: 600}} />
              </Grid>
            </Grid>
            <Grid item container xs={12} md={6}
              direction={'row'}
              alignItems={'flex-start'}
              justify={'center'}
              spacing={2}
            >
              <Grid item xs={12} md={12}>
                <CdaTypography type={'l'} label={t('contactPage.CQ')} />
              </Grid>
              <Grid item xs={12} md={12}>
                <CdaTypography type={'bm'} label={'+7 777 773 66 03'} style={{fontWeight: 600}} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item container xs={12} md={5}
            direction={'row'}
            alignItems={'flex-start'}
            justify={'center'}
          >
            <Card
              className={classes.root}
              elevation={4}
            >
              <CardContent className={classes.content}>
                <Grid item container xs={12} md={12}
                  direction={'row'}
                  alignItems={'flex-start'}
                  justify={'center'}
                  spacing={2}
                >
                  <Grid item container xs={12} md={12}
                    direction={'row'}
                    alignItems={'flex-start'}
                    justify={'center'}
                    spacing={2}
                  >
                    <Grid item xs={12} md={12}>
                      <CdaTypography type={'l'} label={t('contactPage.CC')} color={'grey'} />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <CdaTypography type={'h3'} label={'+7 777 773 66 03'} color={'primary'} style={{fontWeight: 600}} />
                    </Grid>
                  </Grid>
                  <Grid item container xs={12} md={6}
                    direction={'row'}
                    alignItems={'flex-start'}
                    justify={'center'}
                    style={{marginBottom: CW < 950 && 16}}
                  >
                    <Grid item xs={12} md={12} style={{marginBottom: 12, marginLeft: 8}}>
                      <CdaTypography type={'l'} label={t('contactPage.coName')} color={'grey'} />
                    </Grid>
                    <Grid item xs={12} md={12} style={{ marginLeft: 8}}>
                      <CdaTypography type={'l'} label={t('product')} />
                    </Grid>
                  </Grid>
                  <Grid item container xs={12} md={6}
                    direction={'row'}
                    alignItems={'flex-start'}
                    justify={'center'}
                  >          
                    <Grid item container
                      direction={'row'}
                      alignItems={'center'} 
                      justifyContent={'flex-start'}
                      style={{marginBottom: 24}}
                    >
                      <Grid item 
                        style={{
                          padding: '12px 12px 7px 12px', 
                          borderRadius: 8, 
                          background: defaultB,
                          border: '1px solid' + greyB,
                          marginRight: 12
                        }}
                        onClick={() => window.open('https://www.instagram.com/academy.cda/')}
                      >
                        <Instagram color={primaryC} />
                      </Grid>
                      <Grid item 
                        style={{
                          padding: '10px 11.5px 6px 9.5px', 
                          borderRadius: 8, 
                          background: defaultB,
                          border: '1px solid' + greyB,
                          marginRight: 12
                        }}
                        onClick={() => window.open('https://t.me/academycda')}
                      >
                        <Telegram color={primaryC} />
                      </Grid>
                      <Grid item 
                        style={{
                          padding: '10px 11.5px 6px 9.5px', 
                          borderRadius: 8, 
                          background: defaultB,
                          border: '1px solid' + greyB,
                          marginRight: 12
                        }}
                        onClick={() => window.open('https://www.tiktok.com/@academy.cda')}
                      >
                        <TikTok color={primaryC} />
                      </Grid>
                      <Grid item 
                        style={{
                          padding: '12px 12px 7px 12px', 
                          borderRadius: 8, 
                          background: defaultB,
                          border: '1px solid' + greyB
                        }}
                        onClick={() => window.open('https://www.youtube.com/@academycda')}
                      >
                        <YouTube color={primaryC} />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item container xs={12} md={12}
                    direction={'row'}
                    alignItems={'flex-start'}
                    justify={'center'}
                    spacing={2}
                  >
                    <Grid item xs={12} md={12}>
                      <CdaTypography type={'l'} label={t('contactPage.adress')} color={'grey'} />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <CdaTypography type={'l'} label={t('A10A3F6, Казахстан, г. Алматы, 12 микрорайон, 16')} />
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Grid item style={{width: '100%'}}>
        <Footer CW={CW} />
      </Grid>
    </Grid>
  );
})

export default ContactPage;