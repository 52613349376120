import React, {useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { cardLine } from '../lib/helpers'
import CdaTypography from '../cda-UIkit/Typography/CdaTypography';
import CdaButton from '../cda-UIkit/Buttons/CdaButton';
import CdaInstructorCard from '../cda-UIkit/Cards/CdaInstructorCard'
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import "./rcs.css";

const InstructorsCarousel = ({ CW, instructors, title, subTitle }) => {
  const { t } = useTranslation()
  const [carousel, setCarousel] = useState(0)

  const switchCarousel = (orient) => !!instructors && setCarousel(carousel + (!!orient ? 1 : -1))

  return (
    <>
      <Grid item container 
        direction={'row'} 
        justify={"space-between"} 
        alignItems={'center'} 
        xs={12} md={12} 
        style={{marginBottom:  CW > 600 ? 36 : 12}}
      >
        <Grid item xs={12} md={6}
          container 
          direction={'row'} 
          justify={'flex-start'} 
          alignItems={'flex-start'} 
          spacing={2}
        >
          {!!title &&
            <Grid item xs={12} md={12}>
              <CdaTypography type={'h3'} label={title} style={{fontWeight: 600}}/>
            </Grid>
          }
          {!!subTitle &&
            <Grid item xs={12} md={12}>
              <CdaTypography type={'bm'} label={subTitle} />
            </Grid>
          }
        </Grid>
        <Grid item style={{marginTop: CW < 600 && 8}}>
          <CdaButton 
            size={'s'} 
            color={'secondary'}
            onClick={() => switchCarousel(0)}
            disabled={!!(carousel <= 0)}
            style={{width: 32, marginRight: 8}}
          >
            <ChevronRightIcon 
              style={{
                transform: 'rotate(180deg)'
              }}
            />
          </CdaButton>
          <CdaButton 
            size={'s'} 
            color={'secondary'}
            onClick={() => switchCarousel(1)}
            disabled={!!(carousel >= Math.ceil(instructors?.length / cardLine(CW)) - 1)}
            style={{width: 32}}
          >
            <ChevronRightIcon />
          </CdaButton>
        </Grid>          
      </Grid>
      <Carousel
        showStatus={false}
        showThumbs={false}
        showArrows={false}
        statusFormatter={false}
        showIndicators={false}
        selectedItem={carousel}
        autoPlay={true}
        infiniteLoop={true}
        justifyContent={'center'}
      >
        {!!instructors?.length > 0 ? instructors?.filter((r, i, a) => !(i % cardLine(CW))).map((r, i) => (
          <Grid item xs={12} md={12} id={'12312'}
            container direction={'row'}
            alignItems={'flex-start'}
            justify={'center'}
          >
            {instructors?.map((e, p, b) => (p >= (i * cardLine(CW)) && p < ((i * cardLine(CW)) + cardLine(CW))) && (
              <Grid item style={{marginRight: 16, marginLeft: (CW > 330 && cardLine(CW) === 1) && ((CW - 336) / 2)}}>
                <CdaInstructorCard
                  label={e.name}
                  description={e.description}
                  icon={e.photo}
                  i={p - (i * cardLine(CW))}
                  cardLine={cardLine(CW)}
                  l={b?.length}
                />
              </Grid>
            ))}
          </Grid>
        )) : <></>}
      </Carousel>
    </>
  );
}

export default InstructorsCarousel;