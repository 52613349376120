import React, {useEffect, useState} from 'react';
import { Avatar, Card, CardActionArea, CardContent, Grid, withStyles } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import {useSnackbar, withSnackbar} from 'notistack'
import CdaTextField from '../cda-UIkit/Inputs/CdaTextField';
import '../App.css';
import '../cda-UIkit/fonts/style.css';
import DialogCda from '../cda-UIkit/Dialog/DialogCda';
import CdaTypography from '../cda-UIkit/Typography/CdaTypography';
import Slide from '@material-ui/core/Slide';
import { history } from '../lib/history';
import { defaultB } from '../cda-UIkit/constants/colors';
import { thousandSeparator } from '../lib/helpers';
import visa from '../img/visa.png';
import mastercard from '../img/mastercard.png';
import kaspi from '../img/kaspi.png';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = {
	root: {
		background: defaultB,
    padding: '0px',
		borderRadius: '12px',
    width: '100%'
	},
  content: {
    padding: '12px'
  },
  avatar: {
    borderRadius: 0
  }
}

const PaymentDialog = withStyles(styles)(({ classes, open, handleClose, cost, duration, toLocalMonths, CW }) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()


  return (
    <DialogCda
      maxWidth={'md'}
      open={!!open}
      onClose={handleClose}
      close={handleClose}
      TransitionComponent={Transition}
      title={open === 'basic' ? t('PersonalSpace.basicBuyTitle') : t('PersonalSpace.proBuyTitle')}
      content={
      <Grid container 
        direction={'row'} 
        alignItems={'center'} 
        justify={'center'} 
        spacing={2}
        style={{padding: CW > 800 ? '16px 20px' : 0}}
      >
        <Grid item xs={12} md={12}
          container direction={'row'}
          alignItems={'flex-start'}
          justify={'flex-start'}
          spacing={2}
        >
          <Grid item xs={12} md={12}>
            <CdaTypography type={'l'} color={'classic'} label={t('PersonalSpace.paymentDialogTitle')} style={{fontWeight: 600}} />
          </Grid>
          <Grid item xs={12} md={12}>
            <CdaTypography type={'bm'} color={'classic'} label={t('PersonalSpace.paymentDialogSubtitle')} />
          </Grid>
        </Grid>
        <Grid item xs={12} md={12}
          container direction={'row'}
          alignItems={'flex-start'}
          justify={'flex-start'}
          spacing={2}
        >
          <Grid item xs={12} md={6}>
            <Card
              elevation={2}
              className={classes.root}
            >
              <CardActionArea>
                <CardContent className={classes.content}>
                  <Grid container
                    direction={'row'}
                    alignItems={'flex-start'}
                    justify={'flex-start'}
                    spacing={2}
                  >
                    <Grid item xs={12} md={12}>
                      <CdaTypography type={'bm'} color={'classic'} label={t('PersonalSpace.fullPriceBuy')} style={{fontWeight: 600}} />
                    </Grid>
                    <Grid item xs={12} md={12} container direction={'row'} spacing={2}>
                      <Grid item>
                        <CdaTypography type={'l'} color={'grey'} label={thousandSeparator(cost?.toString()) + ' ₸'} style={{textDecoration: 'line-through'}} />
                      </Grid>
                      <Grid item>
                        <CdaTypography type={'l'} color={'primary'} label={thousandSeparator((cost * 0.9)?.toString()) + ' ₸'} />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={12} container direction={'rov'} spacing={2}>
                      <Grid item>
                        <Avatar alt="Visa" src={visa} className={classes.avatar} style={{height: 12, width: 37}} />
                      </Grid>
                      <Grid item>
                        <Avatar alt="MasterCard" src={mastercard} className={classes.avatar} style={{height: 16, width: 25.9, marginTop: -1.3}}/>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card
              elevation={2}
              className={classes.root}
            >
              <CardActionArea>
                <CardContent className={classes.content}>
                  <Grid container
                    direction={'row'}
                    alignItems={'flex-start'}
                    justify={'flex-start'}
                    spacing={2}
                  >
                    <Grid item xs={12} md={12}>
                      <CdaTypography type={'bm'} color={'classic'} label={t('PersonalSpace.monthlyBuy')} style={{fontWeight: 600}} />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <CdaTypography type={'l'} color={'primary'} label={thousandSeparator(Math.round(cost / duration)?.toString()) + ' ₸/' + toLocalMonths()} />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Avatar alt="Kaspi" src={kaspi} className={classes.avatar} style={{height: 24, width: 72, marginBottom: -4.8, marginTop: -4.8}} />
                    </Grid>
                  </Grid>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      }
    />
  );
})

export default withSnackbar(PaymentDialog);