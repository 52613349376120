import React, {useEffect, useState} from 'react'
import { Card, CardContent, Grid, withStyles } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import CdaTypography from '../cda-UIkit/Typography/CdaTypography';
import 'moment/locale/ru';
import { defaultB, secondaryC } from '../cda-UIkit/constants/colors';
import Footer from '../components/Footer';
import FeedBackForm from '../components/FeedBackForm';
import moment from 'moment';
import { dateDiff, smartEnds } from '../lib/helpers';
import { useInterval } from '../hooks/useInterval';

const styles = {
	root: {
		background: defaultB,
    padding: '0px',
		borderRadius: '24px',
    border: 'none',
    width: '100%'
	},
  content: {
    padding: '16px',
    paddingBottom: '16px !important'
  }
}

const limit = moment('2023-11-15 00:00:00.0600')

const Grants = withStyles(styles)(({ classes, CW }) => {
  const { t } = useTranslation()
  const [exp, setExp] = useState(
    CW > 950 ? 
    (
      limit - moment() > 86400000 ?
      smartEnds(moment(limit - moment()).format('DDD') - 1, ' день', ' день', ' дня', 'дней', true) :
      dateDiff(moment(limit - moment()), true)
    ) : (
      limit - moment() > 86400000 ?
      smartEnds(moment(limit - moment()).format('DDD') - 1, ' день', ' день', ' дня', 'дней', true) :
      moment(limit - moment()).utc(false).format('HH:mm:ss')
    )
  )
  
  useInterval(() => setExp(
    CW > 950 ? 
    (
      limit - moment() > 86400000 ?
      smartEnds(moment(limit - moment()).format('DDD') - 1, ' день', ' день', ' дня', 'дней', true) :
      dateDiff(moment(limit - moment()), true)
    ) : (
      limit - moment() > 86400000 ?
      smartEnds(moment(limit - moment()).format('DDD') - 1, ' день', ' день', ' дня', 'дней', true) :
      moment(limit - moment()).utc(false).format('HH:mm:ss')
    )
  ), 1000)
  useEffect(() => {
    window.scroll(0,0)
  }, [])

  return (
    
    <Grid container 
      direction={'column'} 
      alignItems={'center'} 
      justify={'space-between'}
      style={{minHeight: '100vh', width: '100vw'}}
    >
      <Grid item container 
        direction={'row'} 
        alignItems={'flex-start'} 
        justify={'center'}
        style={{padding: CW > 1070 ? '64px' : '56px 8px', overflowX: 'hidden', overflowY: 'hidden', maxWidth: 1600}}
        spacing={6}
      >
        <Grid item xs={12} md={12}>
          <CdaTypography type={'h2'} label={t('grants.title')} style={{fontWeight: 600}} />
        </Grid>
        <Grid item container 
          direction={'row'}
          alignItems={'flex-start'} 
          justify={'flex-start'}
          spacing={2}
          style={{marginBottom: 0, marginLeft: 0, marginRight: 0}}
        >
          <Grid item xs={12} md={12}>
            <CdaTypography type={'bm'} label={t('grants.subtitle')} />
          </Grid>
          <Grid item xs={12} md={12}>
            <CdaTypography type={'bm'} label={t('grants.title') + t('grants.description')} />
          </Grid>
        </Grid>
        {limit > moment() ?
          <>
            <Grid item xs={12} style={{marginBottom: '32px'}}>
              <CdaTypography type={'h3'} color={'primary'} label={t('grantsExp')} style={{textAlign: 'center'}} />
              <CdaTypography type={'h2'} color={'primary'} label={exp} style={{textAlign: 'center'}} />
            </Grid>
            <FeedBackForm
              grant
              color={secondaryC}
              title={t('grants.sendTitle')}
              subTitle={t('grants.sendReason')}
              CW={CW}
              page={'grants'}
            />
          </>
          :
          <Grid item xs={12}>
            <CdaTypography type={'h3'} color={'primary'} label={t('grantsNo')} style={{textAlign: 'center'}} />
          </Grid>
        }
      </Grid>
      <Footer CW={CW} />
    </Grid>
  );
})

export default Grants;