import React, {useEffect, useState} from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Collapse, Grid, MenuItem, Typography, withStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { useTranslation } from "react-i18next";
import CdaTypography from '../cda-UIkit/Typography/CdaTypography';
import 'moment/locale/ru';
import Footer from '../components/Footer';
import { getStudentsForCv, getStudentsRating } from '../requests/students';
import { secondaryB, secondaryC } from '../cda-UIkit/constants/colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CdaButton from '../cda-UIkit/Buttons/CdaButton';
import CdaTextField from '../cda-UIkit/Inputs/CdaTextField';
import CdaSelect from '../cda-UIkit/Inputs/CdaSelect';
import { getProfsInfo } from '../requests/courses';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});

const RatingPage = ({ CW }) => {
  const classes = useStyles();
  const { t } = useTranslation()
  const [students, setStudents] = useState([])
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false)
  const [filter, setFilter] = useState({})
  const [clear, setClear] = useState(false)
  const [profs, setProfs] = useState([])

  const columns = [
    { id: 'place', label: '№', minWidth: 170 },
    { id: 'student', label: t('rating.student'), minWidth: 170 },
    { id: 'prof', label: t('rating.profs'), minWidth: 170 },
    { id: 'rating', label: t('rating.rating'), minWidth: 170, align: 'right'}
  ];

  const getStudents = () => 
    getStudentsRating(filter, 10, page * 10).then(res => {
      if (!!res) {
        setStudents(res)
      }
    })

  const handleChangePage = (event, newPage) => {
    setOpen(false)
    setPage(newPage);
  };

  const handleChange = (e) => {
    setFilter(prevState => ({...prevState, [e.target.name]: e.target.value}))
  }

  const clearFilter = () => {
    setClear(true)
    setPage(0)
    setFilter({})
  }

  const filterComponent = () => (
    <Grid container 
      alignItems={'center'} 
      justify={'space-between'}
      spacing={2}
    >
      <Grid item xs={12} md={4}>
        <CdaTextField
          fullWidth 
          name={'firstName'}
          value={filter?.firstName || ''}
          onChange={handleChange}
          label={t('rating.firstNameBack')} 
          labelTop={t('rating.firstName')}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <CdaTextField
          fullWidth 
          name={'lastName'}
          value={filter?.lastName || ''}
          onChange={handleChange}
          label={t('rating.lastNameBack')} 
          labelTop={t('rating.lastName')}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <CdaSelect
          fullWidth 
          name={'professionId'}
          value={filter?.professionId || null}
          onChange={handleChange}
          label={t('rating.course')} 
          labelTop={t('rating.profs')}
        >
          {profs?.map(e => 
            <MenuItem key={e.id} value={e.id}>{e.name}</MenuItem>
          )}
        </CdaSelect>
      </Grid>
      <Grid item xs={12} container justify={'space-between'} alignItems={'center'}>
        <Grid item>
          <CdaButton
            color={'negativeLight'}
            size={'l'}
            onClick={clearFilter} 
          >
            {t('rating.clear')}
          </CdaButton>
        </Grid>
        <Grid item>
          <CdaButton
            color={'primary'}
            size={'l'} 
            onClick={getStudents}
          >
            {t('rating.search')}
          </CdaButton>
        </Grid>
      </Grid>
    </Grid>
  )

  useEffect(() => {
    window.scroll(0,0)
  }, [])

  useEffect(() => {
    if (Object.keys(filter)?.length === 0 && !!clear) {
      getStudents()
      setClear(false)
    }
  }, [filter, clear])

  useEffect(() => {
    getStudents()
  }, [localStorage.lang, page])

  useEffect(() => {
    getProfsInfo().then(r => {
      if(!!r?.length) {
        setProfs(r/*?.sort((a,b) => a.id > b.id ? 1 : -1)*/)
      }
    })
  }, [localStorage.lang])

  return (
    <Grid container 
      direction={'column'} 
      alignItems={'center'} 
      justify={'space-between'}
      style={{minHeight: '100vh', width: '100vw'}}
    >
      <Grid item container 
        direction={'row'} 
        alignItems={'flex-start'} 
        justify={'center'}
        style={{padding: CW > 1070 ? '64px' : '56px 36px', overflowX: 'hidden', overflowY: 'hidden', maxWidth: 1600, paddingTop: 64}}
        spacing={6}
      >
        <Grid item xs={12}>
          <CdaTypography type={'h3'}
            color={'classic'} 
            label={t('rating.title')} 
            style={{fontWeight: 600}}
            mobile
          />
        </Grid>
        <Grid item xs={12}>
          {CW > 1070 ?
            filterComponent()
            :
            <Accordion elevation={0}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <CdaTypography type={'l'} label={t('rating.filter')} />
              </AccordionSummary>
              <AccordionDetails>
                {filterComponent()}
              </AccordionDetails>
            </Accordion>
          }
        </Grid>
        <Paper className={classes.root}>
          <TableContainer>
            <Table stickyHeader aria-label="collapsible table">
              <TableHead style={{}}>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ backgroundColor: secondaryB }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {students?.list?.map((row, i) => {
                  return (
                    <>
                      <TableRow hover role="checkbox" 
                        style={{cursor: 'pointer'}} tabIndex={-1} key={i} 
                        onClick={() => setOpen((open !== i + 1) ? (i + 1) : false)}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                          <Collapse in={open === i + 1} timeout="auto" unmountOnExit>
                            <Grid container xs={12} 
                              direction={'column'} 
                              alignItems={'flex-start'} 
                              justify={'flex-start'} 
                              spacing={2}
                              style={{padding: 12}}
                            >
                              {row?.other?.map(e =>
                                <Grid item container direction={'row'} spacing={1}>
                                  <Grid item>
                                    <CdaTypography type={'label'} label={e?.course + ': '}/>
                                  </Grid>
                                  <Grid item>
                                    <CdaTypography type={'label'} label={e?.rating} style={{fontWeight: 600}}/>
                                  </Grid>
                                </Grid>
                              )}
                            </Grid>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={students.count}
            rowsPerPage={10}
            page={page}
            onPageChange={handleChangePage}
          />
        </Paper>
      </Grid>
      <Footer CW={CW} />
    </Grid>
  );
}

export default RatingPage;