import React from 'react'
import { darkB } from "../../cda-UIkit/constants/colors"

const LogOut = ({size = '24', color = darkB, style}) => (
  <div style={style}>
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M11 19.498V8.00298C11 7.31298 10.645 6.67198 10.06 6.30698L6.06 3.80698C4.728 2.97498 3 3.93198 3 5.50298V16.997C3 17.687 3.355 18.328 3.94 18.693L7.94 21.193C9.272 22.026 11 21.068 11 19.498Z" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M21 11.5H15" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M17 9.5L15 11.5L17 13.5" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M11 19.5H15C16.105 19.5 17 18.605 17 17.5V16.5" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M17 6.5V5.5C17 4.395 16.105 3.5 15 3.5H5" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </div>
);

export default LogOut;