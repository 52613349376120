import RuPdf from './offer-ru.pdf'

const offers = {
  // kz: KzPdf,
  ru: RuPdf,
  // en: EnPdf
}

const lang = localStorage.getItem('lang') || 'ru'
const OfferPdf = offers[lang]

export  { OfferPdf }