import React from 'react'
import { Avatar, Card, CardActionArea, CardContent, Grid, withStyles } from '@material-ui/core'
import {
	darkB,
	negativeB,
	positiveB,
	primaryC,
	warningB,
	secondaryB,
	negativeC,
	positiveC,
	warningC,
	defaultB,
	primaryB,
	cloudB,
  greyB,
  greyC,
  primaryD,
} from '../constants/colors'
import PropTypes from 'prop-types'
import '../fonts/style.css'
import CdaTypography from '../Typography/CdaTypography'

const styledBy = (property, mapping) => props => mapping[props[property]]

const styles = {
	root: {
		background: defaultB,
    padding: '0px',
		borderRadius: '24px',
    maxWidth: '800px',
	},
  content: {
    position: 'relative',
    padding: '28px',
    paddingBottom: '16px !important',
    minHeight: '260px'
  },
  avatar: {
    position: 'absolute',
    zIndex: 0,
    width: '100%',
    height: '100%',
    borderRadius: '24px'
  },
  rootMobile: {
		background: defaultB,
    padding: '0px',
		borderRadius: '8px'
	},
  contentMobile: {
    position: 'relative',
    padding: '12px 20px',
    paddingBottom: '8px !important',
    minHeight: '48px'
  },
  avatarMobile: {
    position: 'absolute',
    zIndex: 0,
    width: '100%',
    height: '100%',
    borderRadius: '8px'
  }
}

const CdaCardDiscount = withStyles(styles)(({ classes, color, label, info, background, mobile, ...other }) => (
		<Card 
      clicable
			className={!mobile ? classes.root : classes.rootMobile} 
			{...other} 
    >
      <CardActionArea>
      {!!background && <Avatar className={!mobile ? classes.avatar : classes.avatarMobile} alt="" src={background} />}
        <CardContent className={!mobile ? classes.content : classes.contentMobile} 
          style={{background: !background && `linear-gradient(270deg, ${primaryD}, ${positiveB})`}}
        >
          <Grid container direction={'row'} justify={'flex-start'} alignItems={'flex-start'} style={{zIndex: 1}}>
            <Grid item xs={12} md={12}>
              <CdaTypography type={!mobile ? 'subTitle' : 'l'} label={label} color={color} style={{marginBottom: 12}} />
            </Grid>
            <Grid item xs={12} md={12}>
              <CdaTypography type={!mobile ? 'bm' : 'label'} label={info} color={color} />
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
))

export default CdaCardDiscount

CdaCardDiscount.propTypes = {
	color: PropTypes.oneOf([
		'classic',
		'primary',
		'secondary',
		'negative',
		'positive',
		'warning',
		'grey',
		'cloud',
    'white'
	]),
	label: PropTypes.string,
  info: PropTypes.string,
  background: PropTypes.string,
  mobile: PropTypes.bool
}
