import React, {useEffect, useState} from 'react'
import { Card, CardContent, Grid, withStyles } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import CdaTypography from '../cda-UIkit/Typography/CdaTypography';
import 'moment/locale/ru';
import { defaultB, primaryB } from '../cda-UIkit/constants/colors';
import Footer from '../components/Footer';
import FeedBackForm from '../components/FeedBackForm';
import CdaButton from '../cda-UIkit/Buttons/CdaButton';
import MetaIcon from '../img/partners/MetaIcon';
import { history } from '../lib/history';
import ShevronIcon from '../img/icons/ShevronIcon';
import { partners } from '../lib/constants';

const styles = {
  root: {
    background: defaultB,
    padding: '0px',
    borderRadius: '12px',
    width: '100%',
  },
  content: {
    padding: '16px',
  },
  avatar: {
    borderRadius: 0
  }
}

const CarearCentre = withStyles(styles)(({ classes, CW }) => {
  const { t } = useTranslation()
  const [state, setState] = useState(0)

  useEffect(() => {
    window.scroll(0,0)
  }, [])

  return (
    
    <Grid container 
      direction={'column'} 
      alignItems={'center'} 
      justify={'space-between'}
      style={{minHeight: '100vh', width: '100vw'}}
    >
      <Grid item container 
        direction={'row'} 
        alignItems={'flex-start'} 
        justify={'center'}
        style={{padding: CW > 1070 ? '64px' : '56px 8px', overflowX: 'hidden', overflowY: 'hidden', maxWidth: 1600}}
        spacing={6}
      >
        <Grid item xs={12} md={12} container direction={'row'} spacing={1}>
          <Grid item xs={12} md={12}>
            <CdaTypography type={'h2'} color={'classic'} label={t('carearCentre.title')} style={{fontWeight: 600}} />
          </Grid>
          <Grid item xs={12} md={8}>
            <CdaTypography type={'l'} color={'classic'} label={t('carearCentre.subTitle')} />
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} container direction={'row'}>
          <Grid item xs={12} md={4} container direction={'row'} spacing={1}>
            <Grid item xs={6} md={6}>
              <CdaButton size={'l'} color={!state ? 'primary' : 'secondary'} onClick={() => setState(0)} fullWidth>
                {t('carearCentre.student')}
              </CdaButton>
            </Grid>
            <Grid item xs={6} md={6}>
              <CdaButton size={'l'} color={!!state ? 'primary' : 'secondary'} onClick={() => setState(1)} fullWidth>
                {t('carearCentre.company')}
              </CdaButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}
          container 
          direction={'row'} 
          alignItems={'flex-start'} 
          justify={'flex-start'}
          spacing={2}
        >
          <Grid item xs={12} md={12}>
            <CdaTypography type={'subTitle'} color={'classic'} style={{fontWeight: 600}} label={!!state ? t('carearCentre.companyTitle') : t('carearCentre.studentTitle')} />
          </Grid>
          <Grid item xs={12} md={12}>
            <CdaTypography type={'bm'} color={'classic'} label={!!state ? t('carearCentre.companySubTitle') : t('carearCentre.studentSubTitle')} />
          </Grid>
          {!!state &&
            <Grid item xs={12} md={12} style={{marginTop: 16}}>
              <CdaButton
                color={'secondary'}
                size={'l'}
                onClick={() => history.push('for_employer')}
              >
                {t('carearCentre.more')}
                <ShevronIcon 
                  color={primaryB} 
                  size={'20'} 
                  style={{
                    transform: 'rotate(-90deg)', 
                    marginLeft: 8,
                    marginTop: 3, 
                    marginRight: -10
                  }}
                />
              </CdaButton>
            </Grid>
          }
        </Grid>
        <Grid item xs={12} md={6}
          container 
          direction={'row'} 
          alignItems={'flex-start'} 
          justify={'flex-start'}
          spacing={2}
        >
          <Grid item xs={12} md={!!state ? 12 : 6}>
            <Card
              elevation={2}
              className={classes.root}
            >
              <CardContent className={classes.content}>
                <Grid container
                  direction={'column'}
                  alignItems={'flex-start'}
                  justify={'flex-end'}
                  spacing={1}
                  style={{minHeight: 160}}
                >
                  <Grid item>
                    <CdaTypography type={'l'} color={'classic'} 
                      label={!!state ? t('carearCentre.companyCard3Title') : t('carearCentre.studentCard1Title')} 
                      style={{fontWeight: 600}} 
                    />
                  </Grid>
                  <Grid item>
                    <CdaTypography type={'bm'} color={'classic'} 
                      label={!!state ? t('carearCentre.companyCard3SubTitle') : t('carearCentre.studentCard2SubTitle')} 
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card
              elevation={2}
              className={classes.root}
            >
              <CardContent className={classes.content}>
                <Grid container
                  direction={'column'}
                  alignItems={'flex-start'}
                  justify={'flex-end'}
                  spacing={1}
                  style={{minHeight: 160}}
                >
                  <Grid item>
                    <CdaTypography type={'l'} color={'classic'} 
                      label={!!state ? t('carearCentre.companyCard1Title') : t('carearCentre.studentCard2Title')} 
                      style={{fontWeight: 600}} 
                    />
                  </Grid>
                  <Grid item>
                    <CdaTypography type={'bm'} color={'classic'} 
                      label={!!state ? t('carearCentre.companyCard1SubTitle') : t('carearCentre.studentCard2SubTitle')} 
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={!!state ? 6 : 12}>
            <Card
              elevation={2}
              className={classes.root}
            >
              <CardContent className={classes.content}>
                <Grid container
                  direction={'column'}
                  alignItems={'flex-start'}
                  justify={'flex-end'}
                  spacing={1}
                  style={{minHeight: 160}}
                >
                  <Grid item>
                    <CdaTypography type={'l'} color={'classic'} 
                      label={!!state ? t('carearCentre.companyCard2Title') : t('carearCentre.studentCard3Title')} 
                      style={{fontWeight: 600}} 
                    />
                  </Grid>
                  <Grid item>
                    <CdaTypography type={'bm'} color={'classic'} 
                      label={!!state ? t('carearCentre.studentCard3SubTitle') : t('carearCentre.studentCard2SubTitle')} 
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12}
          container 
          direction={'row'} 
          alignItems={'flex-start'} 
          justify={'flex-start'}
          spacing={2}
        >
          <Grid item xs={12} md={12}>
            <CdaTypography type={'subTitle'} color={'classic'} style={{fontWeight: 600}} label={t('carearCentre.partnersTitle')} />
          </Grid>
          <Grid item xs={12} md={12}>
            <CdaTypography type={'bm'} color={'classic'} label={t('carearCentre.partnersSubTitle')} />
          </Grid>
          <Grid item xs={12} md={12}
            container 
            direction={'row'} 
            justify={'center'} 
            alignItems={'center'}
            spacing={CW > 950 ? 8 : (CW > 369 ? 4 : 2)}
            style={{padding: CW > 950 ? 36 : 0, paddingTop: CW > 950 ? 36 : 18, marginBottom: 36}}
          >
            {partners.map(p =>
              <Grid item>
                <img src={p} height={CW > 950 ? '36px' : '24px'}/>
              </Grid>
            )}
          </Grid>
        </Grid>
        <FeedBackForm
          title={t('chooseHelpForm.feedBack')}
          subTitle={t('home.chooseHelpInfo')}
          CW={CW}
          page={'carear_centre'}
          itemId={state}
        />
      </Grid>
      <Footer CW={CW} />
    </Grid>
  );
})

export default CarearCentre;