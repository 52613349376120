import axios from "axios";
import { AUTH_API_URL } from "../lib/env";

/**
 * @async axios
 * @function signIn(data)
 * @return  {Object} {[{...}, {...}, ...]}
 * @example signIn(data)
 *
 */ 
export const signIn = async (data) => {
	try {
		const res = await axios.post(AUTH_API_URL + '/signin', data,
		{
      headers: {
        lang: localStorage.getItem('lang')
      }
    })
		return res;
	} catch (e) {
		return e;
	}
}

/**
 * @async axios
 * @function check(data)
 * @return  {Object} {[{...}, {...}, ...]}
 * @example check(data)
 *
 */ 
export const check = async () => {
	try {
		const res = await axios.get(AUTH_API_URL + '/check',
		{
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
		return res.data;
	} catch (e) {
		return e;
	}
}

/**
 * @async axios
 * @function sendFeedBack(data)
 * @return  {Object} {[{...}, {...}, ...]}
 * @example sendFeedBack(data)
 *
 */ 
export const feedBack = async (data) => {
	try {
		const res = await axios.post(AUTH_API_URL + '/feedBack', data,
		{
      headers: {
        lang: localStorage.getItem('lang')
      }
    })
		return res;
	} catch (e) {
		return e;
	}
}

/**
* @async axios
* @function repareEmail(data)
* @return  {Object} {}
* @example repareEmail(data)
*
*/ 
export const repareEmail = async (data) => {
	try {
		const res = await axios.post(AUTH_API_URL + '/repare/email', data,
		{
			headers: {
				lang: localStorage.getItem('lang')
			}
		})
		return res;
	} catch (e) {
		return e;
	}
}

/**
* @async axios
* @function repareCode(data)
* @return  {Object} {}
* @example repareCode(data)
*
*/ 
export const repareCode = async (data) => {
	try {
		const res = await axios.post(AUTH_API_URL + '/repare/code', data,
		{
			headers: {
				lang: localStorage.getItem('lang')
			}
		})
		return res;
	} catch (e) {
		return e;
	}
}

/**
* @async axios
* @function reparePassword(data)
* @return  {Object} {}
* @example reparePassword(data)
*
*/ 
export const reparePassword = async (data) => {
	try {
		const res = await axios.post(AUTH_API_URL + '/repare/password', data,
		{
			headers: {
				lang: localStorage.getItem('lang')
			}
		})
		return res;
	} catch (e) {
		return e;
	}
}