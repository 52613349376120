import React from 'react'
import { darkB } from "../../cda-UIkit/constants/colors"

const EtlDeveloperIcon = ({size = '24', color = darkB, style}) => (
  <svg width={24} height={24} viewBox={`0 0 ${24} ${24}`} fill="none" xmlns="http://www.w3.org/2000/svg"
    style={{
      ...style,
      transform: `scale(${size / 24})`,
      transformOrigin: 'center'
    }}
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.453 17.0908H19.544C20.9 17.0908 21.999 18.1898 21.999 19.5458V19.5458C21.998 20.9008 20.899 21.9998 19.543 21.9998H14.452C13.097 21.9998 11.998 20.9008 11.998 19.5448V19.5448C11.998 18.1898 13.097 17.0908 14.453 17.0908Z" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M19.4918 19.51C19.4718 19.53 19.4718 19.561 19.4918 19.581C19.5118 19.601 19.5428 19.601 19.5628 19.581C19.5828 19.561 19.5828 19.53 19.5628 19.51C19.5438 19.491 19.5118 19.491 19.4918 19.51" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.0078 19.347L12.3568 14.846C12.4378 13.804 13.3068 13 14.3518 13H19.6488C20.6938 13 21.5618 13.804 21.6428 14.845L21.9918 19.346" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.546 6.09082H4.455C3.099 6.09082 2 7.18982 2 8.54582V8.54582C2.001 9.90082 3.1 10.9998 4.456 10.9998H9.547C10.902 10.9998 12.001 9.90082 12.001 8.54482V8.54482C12.001 7.18982 10.902 6.09082 9.546 6.09082Z" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4.50816 8.51002C4.52816 8.53002 4.52816 8.56102 4.50816 8.58102C4.48816 8.60102 4.45716 8.60102 4.43716 8.58102C4.41716 8.56102 4.41716 8.53002 4.43716 8.51002C4.45616 8.49102 4.48816 8.49102 4.50816 8.51002" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.9938 8.347L11.6448 3.846C11.5638 2.804 10.6948 2 9.64977 2H4.35277C3.30777 2 2.43977 2.804 2.35877 3.845L2.00977 8.346" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M2.5 17.5L4 16L5.5 17.5" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4 16V18C4 19.657 5.343 21 7 21" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M21.5 6.5L20 8L18.5 6.5" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M20 8V6C20 4.343 18.657 3 17 3" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
);

export default EtlDeveloperIcon;