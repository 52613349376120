import React, {useEffect, useState} from 'react'
import { Avatar, Card, CardContent, Grid, withStyles } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import CdaTypography from '../cda-UIkit/Typography/CdaTypography';
import 'moment/locale/ru';
import { defaultB, secondaryC } from '../cda-UIkit/constants/colors';
import Footer from '../components/Footer';
import FeedBackForm from '../components/FeedBackForm';
import CdaButton from '../cda-UIkit/Buttons/CdaButton';
import MetaIcon from '../img/partners/MetaIcon';
import p1 from '../img/persons/p1.png';
import p2 from '../img/persons/p2.png';
import p3 from '../img/persons/p3.png';
import StudentsCarousel from '../components/StudentsCarousel';
import { getStudentsForCv } from '../requests/students';
import IconFree from '../img/forEmployer/IconFree';
import IconQuality from '../img/forEmployer/IconQuality';
import IconCv from '../img/forEmployer/IconCv';
import CdaBetterCard from '../cda-UIkit/Cards/CdaBetterCard';
import { partners } from '../lib/constants';

const styles = {
  root: {
    background: defaultB,
    padding: '0px',
    borderRadius: '12px',
    width: '100%',
    boxShadow: '0px 0px 15px rgba(74, 85, 104, 0.1)'
  },
  content: {
    padding: '16px',
  },
  avatar: {
    borderRadius: 0
  }
}

const ForEmployer = withStyles(styles)(({ classes, CW }) => {
  const { t } = useTranslation()
  const [students, setStudents] = useState([])

  useEffect(() => {
    window.scroll(0,0)
  }, [])

  useEffect(() => {
    getStudentsForCv().then(r => !!r?.length && setStudents(r))
  }, [localStorage.lang])

  return (
    
    <Grid container 
      direction={'column'} 
      alignItems={'center'} 
      justify={'space-between'}
      style={{minHeight: '100vh', width: '100vw'}}
    >
      <Grid item container 
        direction={'row'} 
        alignItems={'flex-start'} 
        justify={'center'}
        style={{padding: CW > 1070 ? '64px' : '56px 8px', overflowX: 'hidden', overflowY: 'hidden', maxWidth: 1600}}
        spacing={6}
      >
        <Grid item xs={12} md={12} container 
          direction={'row'} 
          alignItems={'center'} 
          justify={'space-around'} 
          style={{
            borderRadius: 24, 
            backgroundColor: secondaryC, 
            minHeight: CW > 960 && 400,
            position: 'relative',
            overflow: 'hidden',
            margin: '0 24px 0 24px'
          }}
        >
          <Grid item xs={12} md={6} container 
            direction={'row'} 
            alignItems={'center'} 
            justify={'center'}
            spacing={2} 
          >
            <Grid item xs={12} md={12}>
              <CdaTypography type={CW > 850 ? 'h2' : 'subTitle'} color={'classic'} label={t('forEmployer.title')} style={{fontWeight: 600}} />
            </Grid>
            <Grid item xs={12} md={12}>
              <CdaTypography type={'bm'} color={'classic'} label={t('forEmployer.subTitle')} />
            </Grid>
          </Grid>
          {CW > 960 &&
            <>
              <Grid item xs={12} md={4}>
                
              </Grid>
              <Grid container direction={'column'} spacing={2} 
                style={{position: 'absolute', zIndex: 0, top: -150, right: -(CW > 1600 ? (1600 / 1.7) : (CW / 1.7))}}
              >
                <Grid item>
                  <Avatar
                    alt={''} 
                    src={p1} 
                    style={{
                      height: 220, 
                      width: 200,
                      borderRadius: 16
                    }} 
                  />
                </Grid>
                <Grid item>
                  <Avatar
                    alt={''} 
                    src={p2} 
                    style={{
                      height: 220, 
                      width: 200,
                      borderRadius: 16
                    }} 
                  />
                </Grid>
                <Grid item>
                  <Avatar
                    alt={''} 
                    src={p3} 
                    style={{
                      height: 220, 
                      width: 200,
                      borderRadius: 16
                    }} 
                  />
                </Grid>
              </Grid>
              <Grid container direction={'column'} spacing={2} 
                style={{position: 'absolute', zIndex: 0, top: -50, right: -((CW > 1600 ? (1600 / 1.7) : (CW / 1.7)) + 224)}}
              >
                <Grid item>
                  <Avatar
                    alt={''} 
                    src={p1} 
                    style={{
                      height: 220, 
                      width: 200,
                      borderRadius: 16
                    }} 
                  />
                </Grid>
                <Grid item>
                  <Avatar
                    alt={''} 
                    src={p3} 
                    style={{
                      height: 220, 
                      width: 200,
                      borderRadius: 16
                    }} 
                  />
                </Grid>
                <Grid item>
                  <Avatar
                    alt={''} 
                    src={p2} 
                    style={{
                      height: 220, 
                      width: 200,
                      borderRadius: 16
                    }} 
                  />
                </Grid>
              </Grid>
            </>
          }
        </Grid>
        <Grid item xs={12} md={12} style={{margin: CW > 1070 ? '32px 0 -20px 0' : '32px 24px -20px 24px'}}>
          <CdaTypography type={'subTitle'} color={'classic'} style={{fontWeight: 600}} label={t('forEmployer.offerTitle')} />
        </Grid>
        <Grid item xs={12} md={12}
          container 
          direction={'row'} 
          alignItems={'flex-start'} 
          justify={'flex-start'}
          spacing={2}
        >
          <Grid item xs={12} md={4}>
            <Card
              elevation={0}
              className={classes.root}
            >
              <CardContent className={classes.content}>
                <Grid container
                  direction={'column'}
                  alignItems={'flex-start'}
                  justify={'center'}
                  spacing={2}
                  style={{minHeight: 160}}
                >
                  <Grid item>
                    <CdaTypography type={'l'} color={'classic'} 
                      label={t('forEmployer.offerCard1Title')}
                      style={{fontWeight: 600}}
                    />
                  </Grid>
                  <Grid item>
                    <CdaTypography type={'bm'} color={'classic'} label={t('forEmployer.offerCard1Text')} />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card
              elevation={0}
              className={classes.root}
            >
              <CardContent className={classes.content}>
                <Grid container
                  direction={'column'}
                  alignItems={'flex-start'}
                  justify={'center'}
                  spacing={2}
                  style={{minHeight: 160}}
                >
                  <Grid item>
                    <CdaTypography type={'l'} color={'classic'} 
                      label={t('forEmployer.offerCard2Title')}
                      style={{fontWeight: 600}}
                    />
                  </Grid>
                  <Grid item>
                    <CdaTypography type={'bm'} color={'classic'} label={t('forEmployer.offerCard2Text')} />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card
              elevation={0}
              className={classes.root}
            >
              <CardContent className={classes.content}>
                <Grid container
                  direction={'column'}
                  alignItems={'flex-start'}
                  justify={'center'}
                  spacing={2}
                  style={{minHeight: 160}}
                >
                  <Grid item>
                    <CdaTypography type={'l'} color={'classic'} 
                      label={t('forEmployer.offerCard3Title')}
                      style={{fontWeight: 600}}
                    />
                  </Grid>
                  <Grid item>
                    <CdaTypography type={'bm'} color={'classic'} label={t('forEmployer.offerCard3Text')} />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container 
        direction={'row'} 
        alignItems={'center'} 
        justify={'center'}
        style={{
          overflowX: 'hidden', 
          overflowY: 'hidden', 
          maxWidth: '100vw',
          background: secondaryC
        }}
      >
        <Grid item container 
          direction={'row'} 
          alignItems={'flex-start'} 
          justify={'center'}
          style={{
            padding: CW > 1070 ? '36px 64px' : '36px 36px', 
            overflowX: 'hidden', 
            overflowY: 'hidden', 
            maxWidth: 1600,
          }}
          spacing={2}
        >
          <StudentsCarousel
            CW={CW} 
            students={students} 
            title={t('forEmployer.studentsTitle')} 
            subTitle={t('forEmployer.studentsSubTitle')}
          />
        </Grid>
      </Grid>
      <Grid item container 
        direction={'row'} 
        alignItems={'flex-start'} 
        justify={'center'}
        style={{padding: CW > 1070 ? '64px' : '56px 8px', overflowX: 'hidden', overflowY: 'hidden', maxWidth: 1600}}
        spacing={6}
      >
        <Grid item xs={12} md={12} style={{margin: CW > 1070 ? '0 0 -20px 0' : '0 24px -20px 24px'}}>
          <CdaTypography type={'subTitle'} color={'classic'} style={{fontWeight: 600}} label={t('forEmployer.betterTitle')} />
        </Grid>
        <Grid item xs={12} md={12}
          container 
          direction={'row'} 
          alignItems={'flex-start'} 
          justify={'flex-start'}
          spacing={2}
          style={{marginBottom: 16}}
        >
          <Grid item xs={12} md={4}>
            <CdaBetterCard icon={<IconFree />} title={t('forEmployer.betterCard1Title')} text={t('forEmployer.betterCard1Text')} />
          </Grid>
          <Grid item xs={12} md={4}>
            <CdaBetterCard icon={<IconQuality />} title={t('forEmployer.betterCard2Title')} text={t('forEmployer.betterCard2Text')} />
          </Grid>
          <Grid item xs={12} md={4}>
            <CdaBetterCard icon={<IconCv />} title={t('forEmployer.betterCard3Title')} text={t('forEmployer.betterCard3Text')} />
          </Grid>
        </Grid>
        <Grid item xs={12} md={12}
          container 
          direction={'row'} 
          alignItems={'flex-start'} 
          justify={'flex-start'}
          spacing={2}
        >
          <Grid item xs={12} md={12} style={{margin: CW > 1070 ? 0 : '0 16px 0 16px'}}>
            <CdaTypography type={'subTitle'} color={'classic'} style={{fontWeight: 600}} label={t('carearCentre.partnersTitle')} />
          </Grid>
          <Grid item xs={12} md={12} style={{margin: CW > 1070 ? 0 : '0 16px 0 16px'}}>
            <CdaTypography type={'bm'} color={'classic'} label={t('carearCentre.partnersSubTitle')} />
          </Grid>
          <Grid item xs={12} md={12}
            container 
            direction={'row'} 
            justify={'center'} 
            alignItems={'center'}
            spacing={CW > 950 ? 8 : (CW > 369 ? 4 : 2)}
            style={{padding: CW > 950 ? 36 : 0, paddingTop: CW > 950 ? 36 : 18, marginBottom: 36}}
          >
            {partners.map(p =>
              <Grid item>
                <img src={p} height={CW > 950 ? '36px' : '24px'}/>
              </Grid>
            )}
          </Grid>
        </Grid>
        <FeedBackForm
          title={t('chooseHelpForm.feedBack')}
          subTitle={t('home.chooseHelpInfo')}
          CW={CW}
          color={secondaryC}
          page={'for_employer'}
        />
      </Grid>
      <Footer CW={CW} />
    </Grid>
  );
})

export default ForEmployer;