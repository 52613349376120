import React, {useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next';
import { Avatar, Card, CardContent, Grid, withStyles } from '@material-ui/core';
import { cardLine } from '../lib/helpers'
import CdaTypography from '../cda-UIkit/Typography/CdaTypography';
import CdaButton from '../cda-UIkit/Buttons/CdaButton';
import CdaInstructorCard from '../cda-UIkit/Cards/CdaInstructorCard'
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import moment from 'moment';
import 'moment/locale/ru';
import { defaultB } from '../cda-UIkit/constants/colors';

const styles = {
  root: {
    background: defaultB,
    padding: '0px',
    borderRadius: '12px',
    width: '100%',
  },
  content: {
    padding: '16px',
  }
}

const StudentsCarousel = withStyles(styles)(({ classes, CW, students, title, subTitle}) => {
  const { t } = useTranslation()
  const [carousel, setCarousel] = useState(0)

  const switchCarousel = (orient) => !!students && setCarousel(carousel + (!!orient ? 1 : -1))

  useEffect(() => {
    moment.locale(localStorage.lang)
  }, [localStorage.lang])

  return (
    <>
      <Grid item container 
        direction={'row'} 
        justify={"space-between"} 
        alignItems={'center'} 
        xs={12} md={12}
      >
        <Grid item xs={12} md={8}
          container 
          direction={'row'} 
          justify={'flex-start'} 
          alignItems={'flex-start'} 
          spacing={2}
        >
          {!!title &&
            <Grid item xs={12} md={12}>
              <CdaTypography type={'h3'} label={title} style={{fontWeight: 600}}/>
            </Grid>
          }
          {!!subTitle &&
            <Grid item xs={12} md={12}>
              <CdaTypography type={'bm'} label={subTitle} />
            </Grid>
          }
        </Grid>
        <Grid item>
          <CdaButton 
            size={'s'} 
            color={'secondary'}
            onClick={() => switchCarousel(0)}
            disabled={!!(carousel <= 0)}
            style={{width: 32, marginRight: 8}}
          >
            <ChevronRightIcon 
              style={{
                transform: 'rotate(180deg)'
              }}
            />
          </CdaButton>
          <CdaButton 
            size={'s'} 
            color={'secondary'}
            onClick={() => switchCarousel(1)}
            disabled={!!(carousel >= Math.ceil(students?.length / cardLine(CW)) - 1)}
            style={{width: 32}}
          >
            <ChevronRightIcon />
          </CdaButton>
        </Grid>
      </Grid>
      <Carousel
        showStatus={false}
        showThumbs={false}
        showArrows={false}
        statusFormatter={false}
        showIndicators={false}
        selectedItem={carousel}
      >
        {students?.filter((r, i, a) => !(i % cardLine(CW))).map((r, i) => (
          <Grid item xs={12} md={12}
            container direction={'row'}
            alignItems={'flex-start'}
            justify={'center'}
            style={{marginTop: 16}}
          >
            {students?.map((e, p) => (p >= (i * cardLine(CW)) && p < ((i * cardLine(CW)) + cardLine(CW))) && (
              <Grid item
                style={{maxWidth: 320, marginRight: 16, marginBottom: 16}}
              >
                <Card
                  elevation={0}
                  className={classes.root}
                >
                  <CardContent className={classes.content}>
                    <Grid item container
                      direction={'column'}
                      alignItems={'flex-start'}
                      justify={'center'}
                      spacing={1}
                    >
                      <Grid item style={{marginBottom: 16}}>
                        <CdaTypography type={'l'} align={'left'} label={e.first_name + ' ' + e.last_name} style={{fontWeight: 600}}/>
                      </Grid>
                      <Grid item>
                        <CdaTypography type={'bm'} align={'left'} label={e.course} />
                      </Grid>
                      <Grid item>
                        <CdaTypography type={'bm'} align={'left'} label={!!e?.birth_date && (
                          (moment().from(e?.birth_date).replace(/[^\d]/g, '') === '' ? '1 ' : '') +
                          (localStorage.lang !== 'kz' ?
                            moment().from(e?.birth_date)
                              .replace('через ', '')
                              .replace('in ', '')
                              .replace('a ', '')
                            :
                            moment().from(e?.birth_date)
                              .replace('через ', '')
                              .replace('in ', '')
                              .replace('года', 'жаста')
                              .replace('год', 'жаста')
                              .replace('лет', 'жаста')
                              .replace('месяцев', 'ай')
                              .replace('месяца', 'ай')
                              .replace('месяц', 'ай')
                              .replace('a year', 'жаста')
                              .replace('years', 'жаста')
                              .replace('a month', 'ай')
                              .replace('months', 'ай')
                            )
                          )} 
                        />
                      </Grid>
                      <Grid item>
                        <CdaTypography type={'bm'} align={'left'} label={t('forEmployer.studentsWant')} />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        ))}
      </Carousel>
    </>
  );
})

export default StudentsCarousel;