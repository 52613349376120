import React, {useEffect, useState} from 'react'
import { Grid, Menu, withStyles } from '@material-ui/core'
import { darkB } from '../../cda-UIkit/constants/colors'
import PropTypes from 'prop-types'
import '../../cda-UIkit/fonts/style.css'
import InputMask from 'react-input-mask'
import { collection } from './collection'
import twemoji from 'twemoji'
import Field from './Field'
import ShevronIcon from '../../img/icons/ShevronIcon'
import CdaButton from '../../cda-UIkit/Buttons/CdaButton'
import CdaTextField from '../../cda-UIkit/Inputs/CdaTextField'
import { useTranslation } from 'react-i18next'

const styles = {
  menuPaper: {
    borderRadius: '8px',
    padding: '0px 8px',
    marginTop: 40,
		maxHeight: 600
  }
}

const PhoneInput = withStyles(styles)(({
	classes,
	className,
	labelTop,
	labelBottom,
	bottomColor,
	topColor,
	error = false,
	value,
	onChange,
	name,
	type = 'text',
	styles,
	helperText,
	disabled = false,
	variant = 'outlined',
	fullWidth,
	id
}) => {
	const { t } = useTranslation()
  const [reg, setReg] = useState(collection.find(f => f.min === 'KZ'))
	const [open, setOpen] = useState(false)
	const [filter, setFilter] = useState('')

	const handleFilter = (e) => setFilter(e.target.value)

	return (
		<>
			<InputMask
				mask={reg.mask}
				name={name}
				maskChar={''}
				value={value}
				onChange={onChange}
				disabled={disabled}
			>
				{inputProps => (
					<Field
						{...inputProps}
						className={className}
						disabled={disabled}
						fullWidth={fullWidth}
						type={type}
						style={styles}
						error={Boolean(error)}
						helperText={helperText}
						label={reg.code || ''}
						labelTop={labelTop}
						labelBottom={labelBottom}
						bottomColor={bottomColor}
						topColor={topColor}
						variant={variant}
						id={id}
						icon={
							<Grid container
								direction={'row'}
								alignItems={'center'}
								justify={'center'}
							>
								<Grid item>
									<img alt="" src={twemoji.parse(reg.flag)?.split('src="')[1]?.split('"')[0]} height={'16px'}/>
								</Grid>
								<Grid item>
									<ShevronIcon />
								</Grid>
							</Grid>
						}
						iconAction={(e) => setOpen(e.currentTarget)}
					/>
				)}
			</InputMask>
			<Menu
			elevation={2}
			variant={'selectedMenu'}
			anchorEl={open}
			keepMounted
			open={Boolean(open)}
			onClose={() => setOpen(false)}
			classes={{paper: classes.menuPaper}}
		>
			<Grid container 
				direction={'column'} 
				alignItems={'flex-start'} 
				justify={'center'}
			>
				<Grid item
					style={{
						padding: 8
					}}
				>
					<CdaTextField 
						fullWidth 
						label={t('filter')}
						onChange={(e) => handleFilter(e)}
						value={filter}
					/>
				</Grid>
				{collection.filter(f => 
					f.ru.toLowerCase().includes(filter.toLowerCase()) || f.en.toLowerCase().includes(filter.toLowerCase())
				).map(c => 
					<Grid item>
						<CdaButton color={'tertiary'} size={'l'} onClick={() => {setReg(c); setOpen(false); setFilter('')}}>
							<Grid style={{color: c.min === reg.min ? null : darkB}}>
								<img alt="" src={twemoji.parse(c.flag)?.split('src="')[1]?.split('"')[0]} height={'16px'}/>
								{' ' + (localStorage.lang === 'ru' ? c.ru : c.en)}
							</Grid>
						</CdaButton>
					</Grid>
				)}
			</Grid>
		</Menu>
	</>
	)
})

export default PhoneInput

PhoneInput.propTypes = {
	name: PropTypes.string.isRequired,
	labelTop: PropTypes.string,
	topColor: PropTypes.string,
	helperText: PropTypes.string,
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
	type: PropTypes.oneOf(['text', 'tel']),
	variant: PropTypes.oneOf(['outlined', 'contained', 'default']),
	fullWidth: PropTypes.bool
}