import React, {useEffect, useState} from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Grid } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import CdaTypography from '../cda-UIkit/Typography/CdaTypography';
import CdaCourseCard from '../cda-UIkit/Cards/CdaCourseCard';
import IconConnector from '../components/IconConnector';
import CdaButton from '../cda-UIkit/Buttons/CdaButton';
import ShevronIcon from '../img/icons/ShevronIcon';
import { darkB, greyC, positiveB, primaryB } from '../cda-UIkit/constants/colors';
import CdaSwitch from '../cda-UIkit/Inputs/CdaSwitch';
import { history } from '../lib/history';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckIcon from '@material-ui/icons/Check';
import VisableIcon from '../img/icons/VisableIcon';
import ClosedIcon from '../img/icons/ClosedIcon';
import CdaVideoCard from '../cda-UIkit/Cards/CdaVideoCard';
import CdaCardHome from '../cda-UIkit/Cards/CdaCardHome';
import moment from 'moment';
import 'moment/locale/ru';
import { getCourseInfoById, getProfsCoursesInfo, getTeachers } from '../requests/courses';
import InstructorsCarousel from '../components/InstructorsCarousel';
import ReviewCarousel from '../components/ReviewCarousel';
import FeedBackForm from '../components/FeedBackForm';
import Footer from '../components/Footer';
import PaymentCards from '../components/PaymentCards';
import { jsonToObject, toElem } from '../lib/helpers';
import { getReviewes } from '../requests/students';
import CVPattern from '../components/CVPattern';

const CoursePage = ({ CW }) => {
  const { t, i18n } = useTranslation()
  const [expanded, setExpanded] = useState(false)
  const [noFinished, setNoFinished] = useState(false)
  const [isAuth, setIsAuth] = useState(!!localStorage.getItem('token'))
  const [courseFull, setCourseFull] = useState({})
  const [instructors, setInstructors] = useState([])  
  const [reviewes, setReviewes] = useState([])

  useEffect(() => {
    setIsAuth(!!localStorage.getItem('token'))
  }, [localStorage.token])

  useEffect(() => {
    getCourseInfoById(document.location.pathname.split('/')[2]).then(r => {
      if(!!r?.data) {
        if (r?.status === 206 || !!r?.data?.basic) {
          setIsAuth(false)
        }
        setCourseFull(r?.data/*?.sort((a,b) => a.id > b.id ? 1 : -1)*/)
        if (!isAuth) {
          getReviewes(r?.data?.profession?.[0]).then(r => {
            if(!!r?.length) {
              setReviewes(r)
            }
          })
        } 
      }
    })
    moment.locale(localStorage.lang)
  }, [localStorage.lang, window.location.pathname])

  useEffect(() => {
    getTeachers(document.location.pathname.split('/')[2]).then(r => setInstructors(r))
    window.scroll(0,0)
  }, [])

  return (
    <Grid container 
      direction={'row'} 
      alignItems={'center'} 
      justify={'center'}
    >
      <Grid container 
        direction={'row'} 
        alignItems={'flex-start'} 
        justify={'center'}
        style={{
          padding: CW > 950 ? '64px' : '64px 8px 50px 8px', 
          overflowX: 'hidden',
          overflowY: 'hidden',
          maxWidth: 1600,
          marginBottom: '-12px'
        }}
        spacing={8}
      >
        <Grid item xs={12} md={12}
          container
          direction={'row'}
          alignItems={'center'} 
          justify={'space-between'}
          spacing={2}
          style={{paddingBottom: 0, paddingTop: 0}}
        >
          <Grid item>
            <CdaButton color={'tertiary'} size={'l'} onClick={() => history.goBack()}>
              <Grid container
                direction={'row'} 
                alignItems={'center'} 
                justifyContent={'flex-start'}
              >
                <Grid item>
                  <ShevronIcon style={{transform: 'rotate(90deg)', marginRight: 4, marginTop: -6, marginLeft: -10}} color={primaryB} />
                </Grid>
                <Grid item>{t('back')}</Grid>
              </Grid>
            </CdaButton>
          </Grid>
          {!!isAuth ?
            <Grid item>
              <Grid item>
                <CdaSwitch 
                  label={t('PersonalSpace.hideFinished')} 
                  labelPlacement={'start'}
                  checked={!!noFinished}
                  onChange={() => setNoFinished(!noFinished)}
                />
              </Grid>
            </Grid>
            :
            <Grid item>
            </Grid>
          }
        </Grid>
        <Grid item xs={12} md={4}
          container
          direction={'column'}
          alignItems={'center'} 
          justify={'flex-start'}
          spacing={2}
          style={{paddingTop: 28}}
        >
          <Grid item xs={12} md={12}>
            {!!isAuth ?
              (!!courseFull?.id &&
              <CdaCourseCard 
                elevation={2}
                onClick={() => {''}}
                icon={<IconConnector icon={!!courseFull?.logo ? jsonToObject(courseFull?.logo)?.icon : ''} color={!!courseFull?.logo ? jsonToObject(courseFull?.logo)?.color : ''} />}
                color={!!courseFull?.logo ? jsonToObject(courseFull?.logo)?.color : ''} 
                label={courseFull?.name} 
                info={{v: {count: Number(courseFull?.videos_count), total: Number(courseFull?.videos_total)}, t: {count: Number(courseFull?.tasks_count), total: Number(courseFull?.tasks_total)}}}
              />
              ) : (!!courseFull?.id &&
              <CdaCardHome
                elevation={0}
                onClick={() => toElem('onbuy')}
                style={{boxShadow: '0px 0px 15px rgba(74, 85, 104, 0.1)', width: '100%'}}
                price={courseFull?.price}
                profsName={courseFull?.profsName}
                icon={!!courseFull?.logo && 
                  <IconConnector 
                    icon={JSON.parse(courseFull?.logo)?.icon} 
                    color={JSON.parse(courseFull?.logo)?.color}
                  />
                } 
                color={!!courseFull?.logo && JSON.parse(courseFull?.logo)?.color} 
                label={courseFull?.name} 
                info={!!courseFull?.duration && (
                  (moment(courseFull?.duration).from('1970-01-01').replace(/[^\d]/g, '') === '' ? '1 ' : '') +
                  (localStorage.lang !== 'kz' ?
                    moment(courseFull?.duration).from('1970-01-01')
                      .replace('через ', '')
                      .replace('in ', '')
                      .replace('a ', '')
                    :
                    moment(courseFull?.duration).from('1970-01-01')
                      .replace('через ', '')
                      .replace('in ', '')
                      .replace('года', 'жыл')
                      .replace('год', 'жыл')
                      .replace('лет', 'жыл')
                      .replace('месяцев', 'ай')
                      .replace('месяца', 'ай')
                      .replace('месяц', 'ай')
                      .replace('a year', 'жыл')
                      .replace('years', 'жыл')
                      .replace('a month', 'ай')
                      .replace('months', 'ай')
                  )
                )}
              />
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} md={8}
          container
          direction={'column'}
          alignItems={'flex-start'} 
          justify={'flex-start'}
          spacing={2}
        >
          {!!isAuth ?
            <Grid item xs={12} md={12}>
              <CdaTypography type={'l'} 
                color={'classic'} 
                label={t('PersonalSpace.lessons')} 
                style={{marginBottom:'24px', fontWeight: 600}}
              />
            </Grid>
            :
            <>
              <Grid item xs={12} md ={12}>
                <CdaTypography type={'l'} 
                  color={'classic'}
                  label={t('PersonalSpace.aboutCourse')} 
                  style={{fontWeight: 600}}
                />
              </Grid>
              <Grid item xs={12} md ={12}>
                <CdaTypography 
                  type={'bm'}
                  style={{
                    textIndent: '20px',
                  }}
                  label={courseFull.description?.replaceAll('\\n', '<br>')} 
                />
              </Grid>
            </>
          }
          {!isAuth &&
            <Grid item xs={12} md={12}>
              <CdaTypography type={'l'} 
                color={'classic'} 
                label={t('PersonalSpace.lessons')} 
                style={{fontWeight: 600}}
              />
            </Grid>
          }
          <Grid item container
            xs={12} md={12}
            direction={'row'}
            alignItems={'center'} 
            justify={'center'}
            style={{marginBottom: '12px'}}
          >
            {!!courseFull?.lessons?.length && courseFull?.lessons?.map((l, i) =>
              <Grid item xs={12} md={12} 
                style={{
                  borderBottom: '1px solid' + greyC,
                  borderTop: i === 0 ? '1px solid' + greyC : 'none'
                }}
              >
                <Accordion elevation={0} expanded={expanded === i} onChange={() => setExpanded(expanded !== i ? i : false)}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <CdaTypography type={'bm'} label={`${i + 1}. ${l?.name}`} />
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container 
                      alignItems={'flex-start'} 
                      justify={'flex-start'}
                      spacing={2}
                    >
                      {!!isAuth ? <>
                        <Grid item xs={12} md={12} style={{paddingBottom: 24}}>
                          <CdaTypography 
                            type={'bm'} 
                            label={l?.description} 
                            style={{
                              textIndent: '20px',
                            }}
                          />
                        </Grid>
                        {!!l?.videos?.length && l?.videos?.map((v, ii) => (!!noFinished ? !v?.flag : true) &&
                          <Grid item xs={12} md={4} style={{position: 'relative'}}>
                            <CdaVideoCard
                              onClick={() => !!v?.clicable ? history.push(`/lesson/${v.id}`) : null}
                              i={i} 
                              ii={ii} 
                              label={v?.name} 
                              complited={!!v?.flag} 
                              clicable={!!v?.clicable}
                              icon={v?.preview}
                            />
                          </Grid>
                        )}
                        {!!l?.tasks?.length && l?.tasks?.map((v, ii) => (!!noFinished ? !v?.flag : true) &&
                          <Grid item xs={12} md={4} style={{position: 'relative'}}>
                            <CdaVideoCard
                              onClick={() => !!v?.clicable ? history.push(`/lesson/${v.id}`) : null}
                              i={i} 
                              ii={ii} 
                              label={v?.name} 
                              complited={!!v?.flag} 
                              clicable={!!v?.clicable}
                              icon={'task'}
                            />
                          </Grid>
                        )}
                      </>
                      :
                      <Grid item xs={12} md ={12}>
                        <CdaTypography 
                          type={'bm'} 
                          label={l?.description} 
                          style={{
                            textIndent: '20px',
                          }}
                        />
                      </Grid>
                    }
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            )}
          </Grid>
        </Grid>
        {!isAuth &&
          <Grid item xs={12} md={12}
            container
            direction={'row'}
            alignItems={'center'} 
            justify={'center'}
            spacing={CW > 600 ? 2 : 0}
            style={{paddingBottom: 0, paddingTop: 0}}
          >
            {!!courseFull?.cvItems && courseFull?.cvPrice &&
              <CVPattern items={courseFull?.cvItems} name={courseFull?.name} price={courseFull?.cvPrice} isCourse />
            }
            <Grid item xs={12} md={12}
              container 
              direction={'row'} 
              justify={'flex-start'} 
              alignItems={'flex-start'} 
              spacing={2}
              style={{marginBottom: 36}}
            >
              <InstructorsCarousel
                title={t('PersonalSpace.instructors')}
                instructors={instructors}
                CW={CW}
              />
            </Grid>
            <PaymentCards 
              CW={CW}
              costBasic={courseFull.basic}
              costPro={courseFull.price}
              style={{marginBottom: '24px'}}
              duration={Math.round(moment.duration(moment(courseFull?.duration) - moment('1970-01-01')).asMonths())}
            />
            <Grid item xs={12} md={12}
              container 
              direction={'row'} 
              justify={'flex-start'} 
              alignItems={'flex-start'} 
              spacing={2}
              style={{marginBottom: 36}}
            >
              <ReviewCarousel
                title={t('PersonalSpace.reviewes')}
                reviewes={reviewes}
                CW={CW}
              />
            </Grid>
          </Grid>
        }
        {!isAuth &&
          <Grid item xs={12} md={12}
            container
            direction={'row'}
            alignItems={'center'} 
            justify={'center'}
            spacing={2}
            style={{padding: 8}}
          >
            <FeedBackForm
              title={t('chooseHelpForm.feedBack')}
              subTitle={t('home.chooseHelpInfo')}
              CW={CW}
              page={'course'}
              itemId={courseFull?.id || document.location.pathname.split('/')[2]}
            />
          </Grid>
        }
      </Grid>
      {!isAuth &&
        <Footer CW={CW} />
      }
    </Grid>
  );
}

export default CoursePage;
