import axios from "axios";
import { COURSE_API_URL } from "../lib/env";

/**
 * @async axios
 * @function getTeachers
 * @return  {Object} {[{...}, {...}, ...]}
 * @example getTeachers()
 *
 */ 
export const getTeachers = async (courseId) => {
	try {
		const res = await axios.get(COURSE_API_URL + '/getTeachers', {
      headers: {
        lang: localStorage.getItem('lang'),
				courseId
      }
    })
		return res.data;
	} catch (e) {
		return e;
	}
}

/**
 * @async axios
 * @function getCoursesInfo
 * @return  {Object} {[{...}, {...}, ...]}
 * @example getCoursesInfo(data)
 *
 */ 
export const getCoursesInfo = async () => {
	try {
		const res = await axios.get(COURSE_API_URL + '/getCoursesInfo', {
      headers: {
        lang: localStorage.getItem('lang')
      }
    })
		return res.data;
	} catch (e) {
		return e;
	}
}

/**
 * @async axios
 * @function getProfsInfo
 * @return  {Object} {[{...}, {...}, ...]}
 * @example getProfsInfo(data)
 *
 */ 
export const getProfsInfo = async () => {
	try {
		const res = await axios.get(COURSE_API_URL + '/getProfsInfo', {
      headers: {
        lang: localStorage.getItem('lang')
      }
    })
		return res.data;
	} catch (e) {
		return e;
	}
}

/**
 * @async axios
 * @function getCourseInfoById
 * @return  {Object} {[{...}, {...}, ...]}
 * @example getCourseInfoById(data)
 *
 */ 
export const getCourseInfoById = async (id) => {
	try {
		const res = await axios.get(COURSE_API_URL + '/getCourseInfoById/' + id, {
      headers: {
        lang: localStorage.getItem('lang'),
				'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
		return res;
	} catch (e) {
		return e;
	}
}

/**
 * @async axios
 * @function getProfInfoById
 * @return  {Object} {[{...}, {...}, ...]}
 * @example getProfInfoById(data)
 *
 */ 
export const getProfInfoById = async (id) => {
	try {
		const res = await axios.get(COURSE_API_URL + '/getProfInfoById/' + id, {
      headers: localStorage.getItem('token') ? {
        lang: localStorage.getItem('lang'),
				'Authorization': 'Bearer ' + localStorage.getItem('token')
      } : { lang: localStorage.getItem('lang') }
    })
		return res;
	} catch (e) {
		return e;
	}
}

/**
 * @async axios
 * @function getProfsCoursesInfo
 * @return  {Object} {[{...}, {...}, ...]}
 * @example getProfsCoursesInfo(data)
 *
 */ 
export const getProfsCoursesInfo = async () => {
	try {
		const res = await axios.get(COURSE_API_URL + '/getProfsCoursesInfo', {
      headers: {
        lang: localStorage.getItem('lang')
      }
    })
		return res.data;
	} catch (e) {
		return e;
	}
}

/**
 * @async axios
 * @function getCarearTest(data)
 * @return  {Object} {[{...}, {...}, ...]}
 * @example getCarearTest(data)
 *
 */ 
 export const getCarearTest = async (data) => {
	try {
		const res = await axios.post(COURSE_API_URL + '/getCarearTest', data,
		{
      headers: {
        lang: localStorage.getItem('lang')
      }
    })
		return res.data;
	} catch (e) {
		return e;
	}
}

/**
 * @async axios
 * @function getLessonById(id)
 * @return  {Object} {...}
 * @example getLessonById(id)
 *
 */ 
export const getLessonById = async (data) => {
	try {
		const res = await axios.get(COURSE_API_URL + '/getLessonById/' + data,
		{
      headers: {
        lang: localStorage.getItem('lang')
      }
    })
		return res.data;
	} catch (e) {
		return e;
	}
}

/**
 * @async axios
 * @function downloadFile(id)
 * @return  {Object} {...}
 * @example downloadFile(id)
 *
 */ 
export const downloadFile = async (data) => {
	try {
		const res = await axios.get(data,
		{
      headers: {
        lang: localStorage.getItem('lang')
      }
    })
		return res;
	} catch (e) {
		return e;
	}
}