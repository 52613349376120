import React, {useEffect, useState} from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Button, Grid} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import CdaTypography from '../cda-UIkit/Typography/CdaTypography';
import CdaCourseCard from '../cda-UIkit/Cards/CdaCourseCard';
import IconConnector from '../components/IconConnector';
import CdaButton from '../cda-UIkit/Buttons/CdaButton';
import ShevronIcon from '../img/icons/ShevronIcon';
import { darkB, greyC, positiveB, primaryB } from '../cda-UIkit/constants/colors';
import CdaSwitch from '../cda-UIkit/Inputs/CdaSwitch';
import { history } from '../lib/history';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckIcon from '@material-ui/icons/Check';
import VisableIcon from '../img/icons/VisableIcon';
import ClosedIcon from '../img/icons/ClosedIcon';
import CdaVideoCard from '../cda-UIkit/Cards/CdaVideoCard';
import { COURSE_API_URL } from '../lib/env';
import axios from 'axios';
import { downloadFile, getLessonById } from '../requests/courses';
import GetAppIcon from '@material-ui/icons/GetApp';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const VideoPage = ({CW}) => {
  const { t, i18n } = useTranslation()
  const [noFinished, setNoFinished] = useState(false)
  const [video, setVideo] = useState({})
  
  useEffect(() => {
    getLessonById(window.location.pathname.split('/lesson/')[1]).then(res => !!res && setVideo(res))
  },[])
  return (
    <Grid container 
      direction={'row'} 
      alignItems={'flex-start'} 
      justify={'flex-start'}
      style={{padding: CW > 1070 ? '64px' : '56px 8px', overflowX: 'hidden', overflowY: 'hidden'}}
      spacing={4}
    >
      <Grid item xs={12} md={12}
        container
        direction={'row'}
        alignItems={'center'} 
        justify={'space-between'}
        spacing={2}
        style={{paddingBottom: 0, paddingTop: 0}}
      >
        <Grid item>
          <CdaButton color={'tertiary'} size={'l'} onClick={() => history.push('/course/' + video?.course)}>
            <Grid container
              direction={'row'} 
              alignItems={'center'} 
              justifyContent={'flex-start'}
            >
              <Grid item>
                <ShevronIcon style={{transform: 'rotate(90deg)', marginRight: 4, marginTop: -6, marginLeft: -10}} color={primaryB} />
              </Grid>
              <Grid item>{t('back')}</Grid>
            </Grid>
          </CdaButton>
        </Grid>
        <Grid item>
          <Grid item>
            <CdaSwitch 
              label={t('PersonalSpace.hideFinished')} 
              labelPlacement={'start'}
              checked={!!noFinished}
              onChange={() => setNoFinished(!noFinished)}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12}>
        <CdaTypography type={CW > 850 ? 'h2' : 'subTitle'}
          color={'classic'} 
          label={video?.name}
        />
      </Grid>
      <Grid item xs={12} md={12}
        container
        direction={'column'}
        alignItems={'center'} 
        justify={'center'}
        spacing={2}
      >
        <Grid item container
          xs={12} md={12}
          direction={'row'}
          alignItems={'space-between'} 
          justify={'center'}
          style={{marginBottom: '12px'}}
          spacing={2}
        >
          <Grid item container
            direction={'row'} 
            alignItems={'center'} 
            justifyContent={'center'}
            style={{ position: 'relative' }}
            spacing={0}
          >
            {!!video?.navigation?.find(f => f?.period === video?.period - 1)?.id &&
              <Button item
                container
                direction={'row'}
                alignItems={'center'}
                style={{
                  height: '100%',
                  width: 48,
                  padding: 16,
                  position: 'absolute',
                  left: CW > 1070 ? -56 : -12
                }}
                onClick={() => window.open('/lesson/' + video?.navigation?.find(f => f?.period === video?.period - 1)?.id, '_self')}
              >
                <ArrowBackIosIcon style={{height: 24, width: 24, color: '#555'}} />
              </Button>
            }
            {!!video?.link &&
              <iframe 
                allowfullscreen=""
                width={document.querySelector('body')?.offsetWidth / 1.5} 
                height={document.querySelector('body')?.offsetWidth / 2.75} 
                src={
                  video?.link
                } 
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share">
              </iframe>
              /*<video controls controlsList="nodownload"
                width={document.querySelector('body')?.offsetWidth / 1.5} 
                height={document.querySelector('body')?.offsetWidth / 2.75}
                src={COURSE_API_URL + '/videoStream/' + video?.id}
              />*/
            }
            
            {!!video?.navigation?.find(f => f?.period === video?.period + 1)?.id &&
              <Button item
                container
                direction={'row'}
                alignItems={'center'}
                style={{
                  height: '100%',
                  width: 48,
                  padding: 16,
                  position: 'absolute',
                  right: CW > 1070 ? -80 : -32
                }}
                onClick={() => window.open('/lesson/' + video?.navigation?.find(f => f?.period === video?.period + 1)?.id, '_self')}
              >
                <ArrowBackIosIcon style={{height: 24, width: 24, color: '#555', transform: 'rotate(180deg)', marginLeft: -8}} />
              </Button>
            }
          </Grid>
          <Grid item xs={12}>
            <CdaTypography type={'bm'}
              color={'classic'} 
              label={video?.description}
            />
          </Grid>
          <Grid item xs={12}>
            {!!video?.files?.length && video?.files?.map(e => 
              <CdaTypography type={'bm'}
                color={'primary'}
                link={true}
                label={
                  <a href={e?.link} style={{color: primaryB, textDecoration: 'none'}}>
                    <GetAppIcon style={{marginBottom: -6}}/>
                    {e?.name}
                  </a>
                }
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default VideoPage;
