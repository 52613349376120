import React from 'react'
import { Avatar, Card, CardActionArea, CardContent, Grid, withStyles } from '@material-ui/core'
import {
	secondaryB,
	negativeC,
	positiveC,
	warningC,
	defaultB,
	cloudB,
  greyC,
  primaryB,
  negativeB,
  positiveB,
  warningB,
  greyB,
  primaryC,
  purpleC,
} from '../constants/colors'
import PropTypes from 'prop-types'
import '../fonts/style.css'
import CdaTypography from '../Typography/CdaTypography'
import CdaLinearProgress from '../Progresses/CdaLinearProgress'
import { useTranslation } from 'react-i18next'
import IconConnector from '../../components/IconConnector'
import { thousandSeparator } from '../../lib/helpers'

const styledBy = (property, mapping) => props => mapping[props[property]]

const styles = {
	root: {
		background: styledBy('color', {
			classic: greyB,
			primary: secondaryB,
			secondary: secondaryB,
			negative: negativeC,
			positive: positiveC,
			warning: warningC,
      purple: purpleC
		}) || secondaryB,
    padding: '0px',
		borderRadius: '16px',
    border: '1px solid ' + greyC,
    width: '100%'
	},
  content: {
    padding: '24px',
    paddingBottom: '16px !important'
  },
  icon: {
    height: '64px',
    width: '64px',
		borderRadius: '16px'
  }
}

const CdaProfesiesCard = withStyles(styles)(({ classes, color, icon, label, info, clicable, price, duration, ...other }) => {
  const { t, i18n } = useTranslation()

  const Content = () => (
    <CardContent className={classes.content}>
      <Grid container direction={'row'} justify={'flex-start'} alignItems={'center'}>
        <Grid item container justify={'space-between'} alignItems={'center'} style={{marginBottom: 18}}>
          <Grid item>
            <CdaTypography type={'l'} label={label} />
          </Grid>
          <Grid item>
            {!!icon &&
              <IconConnector className={classes.icon} icon={icon} />
            }
          </Grid>
        </Grid>
        {!!info &&
          <>
            <Grid item xs={12} md={12}>
              <CdaLinearProgress 
                num={parseInt(((info?.v?.count + info?.t?.count) / (info?.v?.total + info?.t?.total)) * 100)}
                showProc
              />
            </Grid>
            <Grid item xs={12} md={12} container direction={'row'} spacing={1} style={{marginTop: 12}}>
              <Grid item>
                <CdaTypography type={'c'} 
                  label={`${info?.v?.count}/${info?.v?.total} ${t('PersonalSpace.video')}`} 
                />
              </Grid>
              <Grid item>
                <CdaTypography type={'c'} 
                  label={`${info?.t?.count}/${info?.t?.total} ${t('PersonalSpace.test')} `} 
                />
              </Grid>
            </Grid>
          </>
        }
        {!!price &&
          <Grid item xs={12} md={12}>
            <CdaTypography type={'bm'} label={thousandSeparator(price.toString()) + ' ₸'}/>
          </Grid>
        }
        {!!duration &&
          <Grid item xs={12} md={12}>
            <CdaTypography type={'label'} color={'grey'} label={duration} />
          </Grid>
        }
      </Grid>
    </CardContent>
  )

  return(
		<Card
      clicable
			className={classes.root} 
			{...other} 
    >
      {!!clicable ?
        <CardActionArea>{Content()}</CardActionArea> :
        Content()
      } 
    </Card>
  );
})

export default CdaProfesiesCard;

CdaProfesiesCard.propTypes = {
	color: PropTypes.oneOf([
		'classic',
		'primary',
		'secondary',
		'negative',
		'positive',
		'warning',
    'purple'
	]),
  icon: PropTypes.node,
  clicable: PropTypes.bool,
	label: PropTypes.string,
  info: PropTypes.object,
  duration: PropTypes.string,
  price: PropTypes.string
}
