import moment from "moment";

export const checkLang = () => {
  const l = navigator.language
  if (!!l) {
    if (l.includes('en')) {
      localStorage.setItem('lang', 'en')
      return 'en';
    }
    if (l.includes('kz')) {
      localStorage.setItem('lang', 'kz')
      return 'kz';
    }
    localStorage.setItem('lang', 'ru')
    return 'ru';
  } else {
    localStorage.setItem('lang', 'ru')
    return 'ru';
  }
}

export const thousandSeparator = (str) => {
	return `${str}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

export const cardLine = (CW) => {
  if (CW >= 1490) {
    return 4;
  }
  if (CW < 1490 && CW >= 1100) {
    return 3;
  }
  if (CW < 1150 && CW >= 760) {
    return 2;
  }
  if (CW < 760) {
    return 1;
  }
}

export const toElem = (id) => {
  window.scrollTo(
    0, 
    document.getElementById(id).getBoundingClientRect().top + window.pageYOffset - 85
  )
}

export const toStart = (id) => {
  document.getElementById(id).scrollTo(
    0, 
    0
  )
}

export const jsonToObject = (json) => {
  try {
    return JSON.parse(json)
  } catch (e) {
    console.log(e, json)
    return json;
  }
}

/**
 * @function smartEnds
 * @param {number} n 24 - value count,
 * @param {string} w " слов" - корень исходного слова
 * @param {string} o "о" - окончание единственного числа
 * @param {string} s "а" - окончание при n от 2 до 4
 * @param {string} m "" - окончание при n от 5 до 19 и 0
 * @param {boolean} full false - когда корень меняется при разном кол-ве, то вместо окончаний целые слова
 * @return {string} "24 слова"
 * @example smartEnds(24, " слов", "о", "а", "", false)
 **/
export const smartEnds = (n, w, o, s, m, full) => {
  if ((!!w || w === '') && (!!o || o === '') && (!!s || s === '') && (!!m || m === '') && (!!n || n === 0)) {
    n = n.toString()
    if ((n.length === 1 || n > 20) && n[n.length - 1] === '1') return n + (full ? '' : w) + o
    if ((n.length === 1 || n > 20) && n[n.length - 1] < 5 && n[n.length - 1] > 1) return n + (full ? '' : w) + s
    if (n[n.length - 1] > 4 || n[n.length - 1] === '0' || (n > 4 && n < 21)) return n + (full ? '' : w) + m
  } else {
    return n + w
  }
}

export const dateDiff = (diff, noMonth) => {
  if (!diff) {
    return diff
  }
  const times = {
    ss: !!parseInt(moment(diff).format('ss'))
      ? smartEnds(parseInt(moment(diff).format('ss')), ' секунд', 'у', 'ы', '')
      : '',
    mm: !!parseInt(moment(diff).format('mm'))
      ? smartEnds(parseInt(moment(diff).format('mm')), ' минут', 'у', 'ы', '') + ' '
      : '',
    HH: !!(parseInt(moment(diff).format('HH')) - 6)
      ? smartEnds((parseInt(moment(diff).format('HH')) - 6 < 0 ? parseInt(moment(diff).format('HH')) - 6 + 24 : parseInt(moment(diff).format('HH')) - 6), ' час', '', 'а', 'ов') + ' '
      : '',
    DD: !!(parseInt(moment(diff).format('DD')) - 1)
      ? (!noMonth ? 
        smartEnds(parseInt(moment(diff).format('DD')) - 1, ' день', ' день', ' дня', 'дней', true) + ' ' :
        smartEnds(parseInt(moment(diff).format('DDD')) - 1, ' день', ' день', ' дня', 'дней', true) + ' ' )
      : '',
    MM: !!(parseInt(moment(diff).format('MM')) - 1)
      ? smartEnds(parseInt(moment(diff).format('MM')) - 1, ' месяц', '', 'а', 'ев') + ' '
      : ''
  }
  if (diff < 60000) {
    return times.ss
  }
  if (diff < 3600000) {
    return times.mm + times.ss
  }
  if (diff < 86400000) {
    return times.HH + times.mm + times.ss
  }
  if (!(parseInt(moment(diff).format('MM')) - 1) || !!noMonth) {
    return times.DD + times.HH + times.mm + times.ss
  }
  return times.MM + times.DD + times.HH + times.mm + times.ss
}