import React from 'react'
import { darkB } from "../../cda-UIkit/constants/colors"

const DatabasesIcon = ({size = '24', color = darkB, style}) => (
  <svg width={24} height={24} viewBox={`0 0 ${24} ${24}`} fill="none" xmlns="http://www.w3.org/2000/svg"
    style={{
      ...style,
      transform: `scale(${size / 24})`,
      transformOrigin: 'center'
    }}
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M19 21V21C17.895 21 17 20.105 17 19V19C17 17.895 17.895 17 19 17V17C20.105 17 21 17.895 21 19V19C21 20.105 20.105 21 19 21Z" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12 11V17" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 21V21C10.895 21 10 20.105 10 19V19C10 17.895 10.895 17 12 17V17C13.105 17 14 17.895 14 19V19C14 20.105 13.105 21 12 21Z" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5 21V21C3.895 21 3 20.105 3 19V19C3 17.895 3.895 17 5 17V17C6.105 17 7 17.895 7 19V19C7 20.105 6.105 21 5 21Z" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5 17V15C5 14.448 5.448 14 6 14H18C18.552 14 19 14.448 19 15V17" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17 3H7C4.791 3 3 4.791 3 7V7C3 9.209 4.791 11 7 11H17C19.209 11 21 9.209 21 7V7C21 4.791 19.209 3 17 3Z" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11 7H17" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M7.0355 6.96548C7.0555 6.98548 7.0555 7.01648 7.0355 7.03648C7.0155 7.05648 6.9845 7.05648 6.9645 7.03648C6.9445 7.01648 6.9445 6.98548 6.9645 6.96548C6.9845 6.94548 7.0165 6.94548 7.0355 6.96548" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
);

export default DatabasesIcon;