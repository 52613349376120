import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en/translation.json';
import translationRU from './locales/ru/translation.json';
import translationKZ from './locales/kz/translation.json';
import { checkLang } from './lib/helpers';

const resources = {
	ru: {
		translation: translationRU,
	},
  en: {
    translation: translationEN,
  },
	kz: {
    translation: translationKZ,
  }
};

i18n.use(initReactI18next).init({
	resources,
	fallbackLng: 'ru',
  language: localStorage.getItem('lang') || checkLang(),
	interpolation: {
		escapeValue: false,
	},
});

export default i18n;
