import React from 'react'
import { darkB } from "../../cda-UIkit/constants/colors"

const MachineLearningIcon = ({size = '24', color = darkB, style}) => (
  <svg width={24} height={24} viewBox={`0 0 ${24} ${24}`} fill="none" xmlns="http://www.w3.org/2000/svg"
    style={{
      ...style,
      transform: `scale(${size / 24})`,
      transformOrigin: 'center'
    }}
  >
    <path d="M14.5306 17.54H9.89062" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.0003 21C19.9184 21 21.4733 19.4514 21.4733 17.541C21.4733 15.6307 19.9184 14.082 18.0003 14.082C16.0823 14.082 14.5273 15.6307 14.5273 17.541C14.5273 19.4514 16.0823 21 18.0003 21Z" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M18 10V14.08" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.00034 9.918C7.91843 9.918 9.47334 8.36935 9.47334 6.459C9.47334 4.54865 7.91843 3 6.00034 3C4.08226 3 2.52734 4.54865 2.52734 6.459C2.52734 8.36935 4.08226 9.918 6.00034 9.918Z" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6 14V10" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8 21H4C2.895 21 2 20.105 2 19V19C2 17.895 2.895 17 4 17H8C9.105 17 10 17.895 10 19V19C10 20.105 9.105 21 8 21Z" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M2.00781 18.834L2.24381 15.843C2.32481 14.802 3.19381 14 4.23681 14H7.76381C8.80781 14 9.67581 14.802 9.75781 15.843L9.99381 18.834" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M20 10H16C14.895 10 14 9.105 14 8V8C14 6.895 14.895 6 16 6H20C21.105 6 22 6.895 22 8V8C22 9.105 21.105 10 20 10Z" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M14.0078 7.834L14.2438 4.843C14.3248 3.802 15.1938 3 16.2368 3H19.7638C20.8078 3 21.6758 3.802 21.7578 4.843L21.9938 7.834" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
);

export default MachineLearningIcon;