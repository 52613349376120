import React from 'react'
import { darkB } from "../../../cda-UIkit/constants/colors"

const TikTok = ({size = '29', color = darkB, style}) => (
  <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="4" y="4" width="24" height="24" rx="5" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M14.0003 15.3333H13.6736C12.0155 15.3328 10.6701 16.6752 10.667 18.3333H10.667C10.667 20.3584 12.3086 22 14.3337 22V22C16.3587 22 18.0003 20.3584 18.0003 18.3333V10" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M18 10C18 11.8409 19.4924 13.3333 21.3333 13.3333" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  
);

export default TikTok;