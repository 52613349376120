import React, {useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next';
import { Grid, Link } from '@material-ui/core';
import { history } from '../lib/history';
import { defaultB, primaryC, secondaryB, darkB } from '../cda-UIkit/constants/colors';
import CdaTypography from '../cda-UIkit/Typography/CdaTypography';
import logo from '../img/partners/logo.png'
import Instagram from '../img/icons/social/Instagram';
import Telegram from '../img/icons/social/Telegram';
import TikTok from '../img/icons/social/TikTok';
import YouTube from '../img/icons/social/YouTube';
import { getProfsCoursesInfo } from '../requests/courses';
import { OfferPdf } from '../assets'

const Footer = ({CW}) => {
  const { t } = useTranslation()
  const [profs, setProfs] = useState([])

  useEffect(() => {
    getProfsCoursesInfo().then(r => {
      if(!!r?.length) {
        setProfs(r?.filter(f => !f?.is_course))
      }
    })
  }, [])

  return(
    <Grid container
      direction={'row'}
      alignItems={'center'} 
      justifyContent={'center'}
      style={{background: secondaryB, padding: CW > 1070 ? '20px 48px' : '20px 16px'}}
      spacing={4}
    >
      <Grid item xs={12} md={12}
        container
        direction={'row'}
        alignItems={'flex-start'} 
        justifyContent={'space-between'}
      >
        <Grid item xs={12} md={4}
          container
          direction={'column'}
          alignItems={'flex-start'} 
          justifyContent={'flex-start'}
          spacing={2}
        >
          <Grid item>
            <CdaTypography type={'bm'} label={t('footer.call')} style={{fontWeight: 600}} />
          </Grid>
          <Grid item>
            <CdaTypography type={'subTitle'} label={'+7 777 773 66 03'} style={{fontWeight: 700}} />
          </Grid>
          <Grid item container
            direction={'row'}
            alignItems={'center'} 
            justifyContent={'flex-start'}
            style={{marginBottom: 24}}
          >
            <Grid item 
              style={{
                padding: '12px 12px 7px 12px', 
                borderRadius: 8, 
                background: defaultB,
                marginRight: 12,
                cursor: 'pointer'
              }}
              onClick={() => window.open('https://www.instagram.com/academy.cda/')}
            >
              <Instagram color={primaryC} />
            </Grid>
            <Grid item 
              style={{
                padding: '10px 11.5px 6px 9.5px', 
                borderRadius: 8, 
                background: defaultB,
                marginRight: 12,
                cursor: 'pointer'
              }}
              onClick={() => window.open('https://t.me/academycda')}
            >
              <Telegram color={primaryC} />
            </Grid>
            <Grid item 
              style={{
                padding: '10px 11.5px 6px 9.5px', 
                borderRadius: 8, 
                background: defaultB,
                marginRight: 12,
                cursor: 'pointer'
              }}
              onClick={() => window.open('https://www.tiktok.com/@academy.cda')}
            >
              <TikTok color={primaryC} />
            </Grid>
            <Grid item 
              style={{
                padding: '12px 12px 7px 12px', 
                borderRadius: 8, 
                background: defaultB,
                marginRight: 12,
                cursor: 'pointer'
              }}
              onClick={() => window.open('https://www.youtube.com/@academycda')}
            >
              <YouTube color={primaryC} />
            </Grid>
          </Grid>
        </Grid>        
        <Grid item
          container
          direction={'row'}
          alignItems={'flex-start'} 
          justifyContent={'flex-start'}
          spacing={2}
          style={{maxWidth: 720}}
        >
          <Grid item container
            direction={'column'}
            alignItems={'flex-start'} 
            justifyContent={'flex-start'}
            spacing={1}
            style={{maxWidth: 240}}
          >
            <Grid item>
              <CdaTypography type={'bm'} label={t('header.jobs')} style={{fontWeight: 600}} />
            </Grid>
            {profs?.map(e => 
              <Grid item>
                <CdaTypography type={'s'} label={e.name} link onClick={() => history.push('/profession/' + e?.id)} />
              </Grid>
            )}
          </Grid>
          <Grid item container
            direction={'column'}
            alignItems={'flex-start'} 
            justifyContent={'flex-start'}
            spacing={1}
            style={{maxWidth: 240}}
          >
            <Grid item>
              <CdaTypography type={'bm'} label={t('footer.aboutAcademy')} style={{fontWeight: 600}} />
            </Grid>
            <Grid item>
              <CdaTypography type={'s'} label={t('footer.contact')} link onClick={() => history.push('/contact_page')}/>
            </Grid>
            <Grid item>
              <CdaTypography type={'s'} label={t('footer.about')} link onClick={() => history.push('/about_academy')} />
            </Grid>
            <Grid item>
              <CdaTypography type={'s'} label={t('footer.carear')} link onClick={() => history.push('/carear_centre')} />
            </Grid>
          </Grid>
          <Grid item container
            direction={'column'}
            alignItems={'flex-start'} 
            justifyContent={'flex-start'}
            spacing={1}
            style={{maxWidth: 240}}
          >
            <Grid item>
              <CdaTypography type={'bm'} label={t('footer.coop')} style={{fontWeight: 600}} />
            </Grid>
            {/*<Grid item>
              <CdaTypography type={'s'} label={t('footer.partners')} link onClick={() => history.push('/info_page')} />
            </Grid>*/}
            <Grid item>
              <CdaTypography type={'s'} label={t('footer.corpClients')} link onClick={() => history.push('/b2b')} />
            </Grid>
            <Grid item>
              <CdaTypography type={'s'} label={t('footer.forBosses')} link onClick={() => history.push('/for_employer')} />
            </Grid>
            {/*<Grid item>
              <CdaTypography type={'s'} label={t('footer.presse')} link onClick={() => history.push('/info_page')} />
            </Grid>*/}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12}
        container
        direction={'row'}
        alignItems={'flex-end'} 
        justifyContent={'space-between'}
      >
        <Grid item container
          direction={'row'}
          alignItems={'flex-end'} 
          justifyContent={'flex-start'}
          spacing={2}
          style={{maxWidth: 480}}
        >
          <Grid item>
          <CdaTypography component={Link} style={{color: darkB}} href={OfferPdf} target='_blank' type={'s'} label={t('footer.oferta')} link />
          </Grid>
          <Grid item>
            <CdaTypography type={'s'} label={t('footer.payment')} link />
          </Grid>
          <Grid item>
            <CdaTypography type={'s'} label={t('footer.confident')} link />
          </Grid>
          <Grid item>
            <CdaTypography type={'s'} label={t('footer.faq')} link onClick={() => {/*history.push('/faq')*/}}/>
          </Grid>
        </Grid>
        <Grid item>
          <a target="_blank" href={"http://cda.kz/"}><img src={logo} style={{height: 85}}/></a>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Footer;