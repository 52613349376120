import React from 'react'
import { darkB } from "../../cda-UIkit/constants/colors"

const ProductIcon = ({size = '24', color = darkB, style}) => (
  <svg width={24} height={24} viewBox={`0 0 ${24} ${24}`} fill="none" xmlns="http://www.w3.org/2000/svg"
    style={{
      ...style,
      transform: `scale(${size / 24})`,
      transformOrigin: 'center'
    }}
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.125 14.812C13.125 13.881 13.88 13.125 14.812 13.125C15.744 13.125 16.499 13.88 16.499 14.812C16.499 15.744 15.744 16.499 14.812 16.499C13.883 16.503 13.128 15.754 13.125 14.825C13.125 14.821 13.125 14.817 13.125 14.812Z" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M13.6209 13.6199L10.3809 10.3799" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16.0098 13.6199L19.2398 10.3799" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.875 9.18802C10.875 10.119 10.12 10.875 9.188 10.875C8.256 10.875 7.5 10.12 7.5 9.18802C7.5 8.25502 8.255 7.50002 9.188 7.50002C10.117 7.49602 10.872 8.24502 10.875 9.17402C10.875 9.17902 10.875 9.18302 10.875 9.18802Z" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4.75977 13.6199L7.98977 10.3799" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M22.125 9.18799C22.125 10.119 21.37 10.875 20.438 10.875C19.506 10.875 18.75 10.12 18.75 9.18799C18.75 8.25599 19.505 7.50099 20.437 7.50099C21.366 7.49699 22.121 8.24599 22.124 9.17499C22.125 9.17899 22.125 9.18299 22.125 9.18799V9.18799Z" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.875 14.812C1.875 13.881 2.63 13.125 3.562 13.125C4.494 13.125 5.25 13.88 5.25 14.812C5.25 15.744 4.495 16.499 3.563 16.499C2.634 16.503 1.879 15.754 1.876 14.825C1.875 14.821 1.875 14.817 1.875 14.812Z" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M3.089 13.201C3.037 12.807 3 12.408 3 12C3 7.029 7.029 3 12 3C15.375 3 18.313 4.861 19.853 7.61" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4.14648 16.3898C5.68648 19.1388 8.62448 20.9998 11.9995 20.9998C16.9705 20.9998 20.9995 16.9708 20.9995 11.9998C20.9995 11.5918 20.9625 11.1928 20.9105 10.7988" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
);

export default ProductIcon;