import React from 'react'
import { darkB } from "../../cda-UIkit/constants/colors"

const AnalitycsIcon = ({size = '24', color = darkB, style}) => (
  <svg 
    width={24} height={24} 
    viewBox={`0 0 ${24} ${24}`} 
    fill="none" xmlns="http://www.w3.org/2000/svg"
    style={{
      ...style,
      transform: `scale(${size / 24})`,
      transformOrigin: 'center'
    }}
  >
    <path d="M3 8H21" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M7.5 16.9995V15.5195" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.5 17V12.25" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M13.5 16.9997V14.9297" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16.5 16.9998V12.7998" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.8652 5.495C10.8622 5.495 10.8602 5.497 10.8602 5.5C10.8602 5.503 10.8622 5.505 10.8652 5.505C10.8682 5.505 10.8702 5.503 10.8702 5.5C10.8702 5.497 10.8682 5.495 10.8652 5.495" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8.32617 5.495C8.32317 5.495 8.32117 5.497 8.32117 5.5C8.32117 5.503 8.32317 5.505 8.32617 5.505C8.32917 5.505 8.33117 5.503 8.33117 5.5C8.33117 5.497 8.32917 5.495 8.32617 5.495" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.7793 5.49012C5.7763 5.49012 5.7743 5.49212 5.7743 5.49512C5.7743 5.49812 5.7763 5.50012 5.7793 5.50012C5.7823 5.50012 5.7843 5.49812 5.7843 5.49512C5.7843 5.49212 5.7823 5.49012 5.7793 5.49012" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M19 21H5C3.895 21 3 20.105 3 19V5C3 3.895 3.895 3 5 3H19C20.105 3 21 3.895 21 5V19C21 20.105 20.105 21 19 21Z" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
);

export default AnalitycsIcon;