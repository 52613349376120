import React, {useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next';
import { Avatar, Grid } from '@material-ui/core';
import { cardLine } from '../lib/helpers'
import CdaTypography from '../cda-UIkit/Typography/CdaTypography';
import CdaButton from '../cda-UIkit/Buttons/CdaButton';
import CdaInstructorCard from '../cda-UIkit/Cards/CdaInstructorCard'
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./rcs.css";

const ReviewCarousel = ({ CW, reviewes, title}) => {
  const { t } = useTranslation()
  const [carousel, setCarousel] = useState(0)

  const switchCarousel = (orient) => !!reviewes && setCarousel(carousel + (!!orient ? 1 : -1))

  return (
    <>
      <Grid item container 
        direction={'row'} 
        justify={"space-between"} 
        alignItems={'center'} 
        xs={12} md={12} 
        style={{marginBottom:  CW > 600 ? 36 : 12}}
      >
        {!!title &&
          <Grid item>
            <CdaTypography type={'h3'} label={title} style={{fontWeight: 600}}/>
          </Grid>
        }
        <Grid item style={{marginTop: CW < 600 && 8}}>
          <CdaButton 
            size={'s'} 
            color={'secondary'}
            onClick={() => switchCarousel(0)}
            disabled={!!(carousel <= 0)}
            style={{width: 32, marginRight: 8}}
          >
            <ChevronRightIcon 
              style={{
                transform: 'rotate(180deg)'
              }}
            />
          </CdaButton>
          <CdaButton 
            size={'s'} 
            color={'secondary'}
            onClick={() => switchCarousel(1)}
            disabled={!!(carousel >= Math.ceil(reviewes?.length / cardLine(CW)) - 1)}
            style={{width: 32}}
          >
            <ChevronRightIcon />
          </CdaButton>
        </Grid>
      </Grid>
        <Carousel
          showStatus={false}
          showThumbs={false}
          showArrows={false}
          statusFormatter={false}
          showIndicators={false}
          selectedItem={carousel}
        >
          {reviewes?.filter((r, i, a) => !(i % cardLine(CW))).map((r, i) => (
            <Grid item xs={12} md={12}
              container direction={'row'}
              alignItems={'flex-start'}
              justify={'center'}
            >
              {reviewes?.map((e, p) => (p >= (i * cardLine(CW)) && p < ((i * cardLine(CW)) + cardLine(CW))) && (
                <Grid item container
                  direction={'column'}
                  alignItems={'flex-start'}
                  justify={'flex-start'}
                  spacing={2}
                  style={{maxWidth: 320, marginRight: 16, marginBottom: 16, marginLeft: (CW > 330 && cardLine(CW) === 1) && ((CW - 336) / 2)}}
                >
                  <Grid item>
                    <CdaTypography type={'bm'} label={e.title} />
                  </Grid>
                  {!e?.content?.includes('https://www.youtube.com/') ?
                    <Grid item>
                        <CdaTypography type={'label'} align={'left'} label={e.content} />
                    </Grid>
                    :
                    <Grid item dangerouslySetInnerHTML={{ __html: e?.content}}></Grid>
                  }
                  <Grid item container
                    direction={'row'} 
                    alignItems={'flex-start'} 
                    justify={'flex-start'}
                    spacing={1}
                  >
                    <Grid item xs={12} md={12} 
                      container
                      direction={'column'}
                      alignItems={'flex-start'}
                      justify={'space-between'}
                      spacing={1}
                      style={{height: '100%'}}
                    >
                      <Grid item>
                        <CdaTypography type={'label'} label={e.authorName} style={{fontWeight: 600}}/>
                      </Grid>
                      <Grid item>
                        <CdaTypography type={'label'} label={t('profession') + e.authorCourse} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          ))}
        </Carousel>
    </>
  );
}

export default ReviewCarousel;