import React from 'react';
import PropTypes from 'prop-types'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { defaultB, greyC, primaryB } from '../constants/colors';
import { Grid } from '@material-ui/core';
import CdaTypography from '../Typography/CdaTypography';


const GreyLinearProgress = withStyles((theme) => ({
  root: {
    height: 12,
    borderRadius: 6,
  },
  colorPrimary: {
    backgroundColor: greyC,
  },
  bar: {
    borderRadius: 5,
    backgroundColor: primaryB,
  },
}))(LinearProgress);

const WhiteLinearProgress = withStyles((theme) => ({
  root: {
    height: 12,
    borderRadius: 6,
  },
  colorPrimary: {
    backgroundColor: defaultB,
  },
  bar: {
    borderRadius: 5,
    backgroundColor: primaryB,
  },
}))(LinearProgress);

const useStyles = makeStyles({
  root: {
    flexGrow: 1
  },
});

const CdaLinearProgress = ({num = 0, showProc}) => {
  const classes = useStyles();

  return(
    <Grid container direction={'row'} alignItems={'center'} justify={'center'} spacing={!!showProc ? 1 : 0}>
      <Grid item className={classes.root}>
        {!!showProc ?
          <WhiteLinearProgress variant="determinate" value={num} style={{width: !!showProc && '100%'}}/> :
          <GreyLinearProgress variant="determinate" value={num} style={{width: !!showProc && '100%'}}/>
        }
      </Grid>
      {!!showProc && 
        <Grid item>
          <CdaTypography type={'bm'} 
            label={num + '%'}
          />
        </Grid>
      }
    </Grid>
  );
}

export default CdaLinearProgress;
CdaTypography.propTypes = {
	num: PropTypes.number,
  showProc:  PropTypes.oneOf([
		true,
		false
	]),
}