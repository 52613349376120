import React, {useEffect, useState} from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import {useSnackbar, withSnackbar} from 'notistack'
import CdaTextField from '../cda-UIkit/Inputs/CdaTextField';
import '../App.css';
import '../cda-UIkit/fonts/style.css';
import CdaButton from '../cda-UIkit/Buttons/CdaButton';
import DialogCda from '../cda-UIkit/Dialog/DialogCda';
import CdaTypography from '../cda-UIkit/Typography/CdaTypography';
import VisableIcon from '../img/icons/VisableIcon'
import InvisableIcon from '../img/icons/InvisableIcon';
import Slide from '@material-ui/core/Slide';
import { signIn } from '../requests/auth';
import { history } from '../lib/history';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const init = {
  email: null,
  password: null
}

const SignInForm = ({ open, handleClose, handleFogot }) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [visable, setViasble] = useState(false)
  const [error, setError] = useState(false)
  const [noEmail, setNoEmail] = useState(false)
  const [noPassword, setNoPassword] = useState(false)
  const [form, setForm] = useState(init)

  const clearErrors = () => {
    setError(false)
    setNoEmail(false)
    setNoPassword(false)
  }

  const sendAuth = () => {
    let errors = false
    if (!form?.email || !form?.password) {
      setError(true)
      errors = true
    }
    if ((!form?.email?.includes('@') || !form?.email?.includes('.')) && form?.email?.length > 5) {
      setNoEmail(true)
      errors = true
    }    
    if (form?.password?.length < 8) {
      setNoPassword(true)
      errors = true
    }
    if (!!errors) {
      return null;
    }
    signIn(form).then(r => {
      if (r?.status === 200) {
        enqueueSnackbar(t('signModal.success'), {variant: 'success'})
        localStorage.setItem('token', r.data.token)
        history.push('/personal_area')
        handleClose()
      } else if (r?.response?.status === 401) {
        setError(true)
      } else {
        enqueueSnackbar(t('error'), {variant: 'success'})
        console.log(r)
      }
    })
  }

  return (
    <DialogCda
      maxWidth={'xs'}
      open={open}
      onClose={handleClose}
      close={handleClose}
      TransitionComponent={Transition}
      title={t('signModal.title')}
      content={
      <Grid container direction={'row'} alignItems={'center'} justify={'center'} spacing={2}>
        <Grid item xs={12} md={12}>
          <CdaTextField 
            fullWidth
            error={!!error || !!noEmail}
            onChange={(e) => {setForm(prevState => ({...prevState, email: e.target.value})); clearErrors()}}
            value={form.email || ''}
            label={t('signModal.enterEmail')} 
            labelTop={'Email'}
            labelBottom={
              !!noEmail ? 
                t('signModal.emailValid') : 
                (!!error || !!noEmail? t('signModal.errorEmail') : null)
            }
            bottomColor={'negative'}
          />
        </Grid>
        <Grid item xs={12} md={12} style={{position: 'relative'}}>
          <CdaTextField 
            fullWidth
            error={!!error || !!noPassword}
            type={!visable && "password"}
            onChange={(e) => {setForm(prevState => ({...prevState, password: e.target.value})); clearErrors()}}
            value={form.password || ''}
            label={t('signModal.enterPassword')} 
            labelTop={t('signModal.password')}
            labelBottom={
              !!noPassword ? 
                t('signModal.passwordLength') + '. ' + t('signModal.fogot') : 
                (!!error ? t('signModal.errorPassword') + '. ' + t('signModal.fogot') : t('signModal.fogot'))
            }
            bottomColor={!!error || !!noPassword ? 'negative' : 'primary'}
            bottomAction={handleFogot}
            icon={!!visable ?
              <VisableIcon /> :
              <InvisableIcon />
            }
            iconAction={() => setViasble(!visable)}
          />
        </Grid>
      </Grid>
      }
      actions={
      <>
        <CdaButton 
          fullWidth 
          color={'primary'} 
          size={'l'}
          onClick={sendAuth}
        >
          {t('signModal.signin')}
        </CdaButton>
      </>
      }
    />
  );
}

export default withSnackbar(SignInForm);