import React from 'react'
import { Grid, IconButton, makeStyles, TextField } from '@material-ui/core'
import { darkB, defaultB, greyB } from '../../cda-UIkit/constants/colors'
import PropTypes from 'prop-types'
import '../../cda-UIkit/fonts/style.css'
import CdaTypography from '../../cda-UIkit/Typography/CdaTypography'

const useStyles = makeStyles(theme => ({
  root: {
    color: darkB,
    fontFamily: 'GT Eesti Pro Display',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 16,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: '10px 24px 8px 0px',
    paddingLeft: '40px',
    gap: '10px',
    height: 42,
    background: defaultB,
    border: '1px solid #EAEDF2',
    borderRadius: '8px',
    '&$disabled': {
      background: '#E6E8EA',
      color: greyB,
      border: '1px solid #E6E8EA',
    },
    '&:hover': {
      background: defaultB,
      border: '1px solid #2B6BB2',
      boxShadow: '0px 0px 0px 4px rgba(29, 133, 255, 0.2)'
    },
    '&$focused': {
      background: defaultB,
      border: '1px solid #2B6BB2',
      boxShadow: '0px 0px 0px 4px rgba(29, 133, 255, 0.2)'
    },
    '&$error': {
      background: defaultB,
      border: '1px solid #FF2C2C',
      boxShadow: '0px 0px 0px 4px rgba(237, 75, 75, 0.2)'
    },
    '& .MuiFilledInput-input': {
      paddingTop: 12,
    }
  },
  focused: {},
  error: {},
  disabled: {    
    fontFamily: 'GT Eesti Pro Display',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 16,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: '9px 24px 8px 6px',
    gap: '10px',
    height: 42,
    borderRadius: '8px',    
    background: '#E6E8EA',
    color: greyB,
    border: '1px solid #E6E8EA',
    '&:hover': {
      background: '#E6E8EA',
      border: '1px solid #E6E8EA',
    },
    '&$focused': {
      background: '#E6E8EA',
      border: '1px solid #E6E8EA',
    },
    '&$error': {
      background: '#E6E8EA',
      border: '1px solid #E6E8EA',
    },
    '& .MuiFilledInput-input': {
      paddingTop: 12,
    }
  },
  input: {
    WebkitBoxShadow: "0 0 0 1000px white inset",
    height: 0
  },
  inputLabel: {
    color: greyB,
    marginTop: -6,
    gap: '10px',
    fontFamily: 'GT Eesti Pro Display',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 16,
    paddingLeft: '40px',
    '&.MuiInputLabel-shrink': {
      height: 0,
      background: 'transparent',
      color: 'transparent',
      fontWeight: 400,
      fontSize: 0
    },
    '&.focused': {
      height: 0,
      background: 'transparent',
      color: 'transparent',
      fontWeight: 400,
      fontSize: 0
    }
  }
}))

const Field = ({ InputProps, labelTop, labelBottom, topColor, bottomColor, bottomAction, icon, iconAction, id, ...props }) => {
  const classes = useStyles()
  return (
    <Grid style={{position: !!icon && 'relative'}}>
      {!!labelTop && <CdaTypography label={labelTop} color={!!topColor ? topColor : 'classic'} type={'s'} style={{marginLeft: '1px', marginBottom: '1px'}} />}
      <TextField
        InputLabelProps={{
          classes: {
            root: classes.inputLabel,
            focused: 'focused'
          },
          shrink: !!props.value
        }}
        InputProps={{
          classes: {
            root: props.disabled ? classes.disabled : classes.root,
            error: classes.error,
            focused: classes.focused,
            disabled: classes.disabled,
            input: classes.input
          },
          disableUnderline: true,
          ...InputProps
        }}
        {...props}
        variant="filled"
      />
      <IconButton
        id={id}
        onClick={iconAction}
        style={{
          position: 'absolute',
          left: 8,
          padding: 2,
          height: 30,
          width: 40,
          top: !!labelTop ? 27 : 6,
          borderRadius: 4
        }}
        disabled={!!props?.disabled}
      >
        {icon}
      </IconButton>
      {!!labelBottom && 
        <CdaTypography 
          label={labelBottom} 
          color={!!bottomColor ? bottomColor : 'classic'} 
          type={'c'}
          onClick={bottomAction}
          style={{marginLeft: '1px', marginTop: '4px'}}
          link={!!bottomAction}
        />
      }
    </Grid>
  )
}
export default Field
Field.propTypes = {
  label: PropTypes.string,
  labelTop: PropTypes.string,
  labelBottom: PropTypes.string,
  bottomColor: PropTypes.string,
  topColor: PropTypes.string,
  bottomAction: PropTypes.func,
  icon: PropTypes.node,
  iconAction: PropTypes.func,
  onChange: PropTypes.func,
  InputProps: PropTypes.object,
  InputLabelProps: PropTypes.object,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  style: PropTypes.object,
  name: PropTypes.string,
  id: PropTypes.string,
  fullWidth: PropTypes.bool
}