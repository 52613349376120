import React, {useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next';
import { Grid, Tooltip } from '@material-ui/core';
import CdaTypography from '../cda-UIkit/Typography/CdaTypography';
import CdaCardTitle from '../cda-UIkit/Cards/CdaCardTitle';
import VisableIcon from '../img/icons/VisableIcon';
import IconConnector from './IconConnector';
import CdaCardDiscount from '../cda-UIkit/Cards/CdaCardDiscount';
import { getCoursesInfo } from '../requests/courses';
import moment from 'moment';
import 'moment/locale/ru';
import { discounts } from '../lib/constants';
import { history } from '../lib/history';

const CoursesAccordion = ({ mobile, close }) => {
  const { t } = useTranslation()
  const [courses, setCourses] = useState([])

  useEffect(() => {
    getCoursesInfo().then(r => {
      if (!!r) {
        setCourses(r?.sort((a,b) => a.id > b.id ? 1 : -1))
      }
    })
    moment.locale(localStorage.lang)
  }, [localStorage.lang])

  return(
    <Grid container 
      direction={'row'} 
      justify={'space-between'} 
      alignItems={'flex-start'} 
      spacing={!mobile ? 4 : 0}
      style={
        !mobile ? 
          { width: '100%', maxHeight: '600px', overflowY: 'auto', overflowX: 'hidden', paddingRight: '32px'} :
          { width: '100%'}
      }
    >
      <Grid item 
        xs={12} md={!mobile && !!discounts && !!discounts?.length ? 6 : 12} 
        container 
        direction={'row'} 
        justify={'flex-start'} 
        alignItems={'flex-start'} 
        spacing={2}
      >
        {!mobile &&
          <Grid item xs={12} md={12}>
            <CdaTypography type={'bm'} label={t('header.courses')} />
          </Grid>
        }
        {courses?.map(c => 
          <Grid item>
            <CdaCardTitle
              elevation={0}
              onClick={() => {close();history.push('/course/' + c?.id)}}
              icon={!!c?.logo && <IconConnector icon={JSON.parse(c?.logo)?.icon} color={JSON.parse(c?.logo)?.color} />} 
              color={!!c?.logo && JSON.parse(c?.logo)?.color} 
              label={c?.name} 
              info={!!c?.duration && (
                (moment(c?.duration).from('1970-01-01').replace(/[^\d]/g, '') === '' ? '1 ' : '') +
                (localStorage.lang !== 'kz' ?
                  moment(c?.duration).from('1970-01-01')
                    .replace('через ', '')
                    .replace('in ', '')
                    .replace('a ', '')
                  :
                  moment(c?.duration).from('1970-01-01')
                    .replace('через ', '')
                    .replace('in ', '')
                    .replace('года', 'жыл')
                    .replace('год', 'жыл')
                    .replace('лет', 'жыл')
                    .replace('месяцев', 'ай')
                    .replace('месяца', 'ай')
                    .replace('месяц', 'ай')
                    .replace('a year', 'жыл')
                    .replace('years', 'жыл')
                    .replace('a month', 'ай')
                    .replace('months', 'ай')
                )
              )}
            />
          </Grid>
        )}
      </Grid>
      {!mobile && !!discounts && !!discounts?.length && 
        <Grid item 
          xs={12} md={6} 
          container 
          direction={'row'} 
          justify={'flex-start'} 
          alignItems={'flex-start'} 
          spacing={2}
        >
          <Grid item xs={12} md={12}>
            <CdaTypography type={'bm'} label={t('header.discounts')} />
          </Grid>
          {discounts?.map(d => 
            <Grid item>
              <CdaCardDiscount
                elevation={0}
                onClick={() => {}} 
                color={d?.color} 
                label={d?.title} 
                info={d?.description}
                background={d?.background}
              />
            </Grid>
          )}
        </Grid>
      }
    </Grid>
  );
}

export default CoursesAccordion;