import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from '@material-ui/core'
import { defaultB, darkB } from '../constants/colors'
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types'
import CdaTypography from '../Typography/CdaTypography';

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    borderRadius: '16px',
    padding: '24px',
    background: defaultB,
    zIndex: '999 !important'
  },
  root:{
    zIndex: '999 !important',
    height: '100vh',
    '& .MuiDialog-container': {
      height: '100vh'
    },
    '& .MuiBackdrop-root': {
      backdropFilter: 'blur(16px)',
      transition: 'none !important',
      backgroundColor: 'rgba(0, 0, 0, 0.6) !important'
    }
  },
}))
const DialogCda = ({ open, onClose, title, actions, content, close, ...props }) => {
  const classes = useStyles()
  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{ paper: classes.dialogPaper, root: classes.root }}
      {...props}
    >
      <Grid container direction={'row'} alignItems={'center'} justify={'center'} spacing={4}>
        {!!title &&
          <Grid item xs={12} md={12} style={{position: 'relative'}}>
            <Grid style={{marginRight: 42}}>
              <CdaTypography type={'subTitle'} color={'classic'} label={title} style={{fontWeight: 600}} />
            </Grid>
            <IconButton style={{position: 'absolute', top: 6, right: 6}} onClick={close}>
              <CloseIcon style={{ color: darkB, height: 30, width: 30}} />
            </IconButton>
          </Grid>
        }
        {!!content && <Grid item xs={12} md={12}>{content}</Grid>}
        {!!actions && <Grid item xs={12} md={12}>{actions}</Grid>}
      </Grid>
    </Dialog>
  )
}
export default DialogCda
DialogCda.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  onClose: PropTypes.func,
  close: PropTypes.func,
  content: PropTypes.node,
  actions: PropTypes.node
}
