import React, {useEffect, useState} from 'react'
import ReactDOM from 'react-dom'
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Grid, IconButton, Menu, MenuItem, Slide, SwipeableDrawer, Tooltip, withStyles } from '@material-ui/core';
import { cloudB, darkB, defaultB, greyB, greyC, negativeB, primaryC } from '../cda-UIkit/constants/colors';
import logo from '../img/logo.svg'
import CrossIcon from '../img/icons/CrossIcon'
import MenuIcon from '../img/icons/MenuIcon'
import CdaButton from '../cda-UIkit/Buttons/CdaButton';
import ShevronIcon from '../img/icons/ShevronIcon';
import LanguageIcon from '../img/icons/LanguageIcon';
import DialogCda from '../cda-UIkit/Dialog/DialogCda';
import SignInForm from './SignInForm';
import FogotModal from './FogotModal';
import CoursesAccordion from './CoursesAccordeon';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { history } from '../lib/history';
import twemoji from 'twemoji';
import CdaTypography from '../cda-UIkit/Typography/CdaTypography';
import CdaCardDiscount from '../cda-UIkit/Cards/CdaCardDiscount';
import { discounts } from '../lib/constants';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import SchoolIcon from '@material-ui/icons/School';
import jwtDecode from 'jwt-decode';
import LogOut from '../img/icons/LogOut';
import { Button } from 'react-bootstrap';
import Sertify from '../img/icons/Sertify';
import { toElem } from '../lib/helpers';
import FeedBackForm from './FeedBackForm';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = {
	root: {
		position: 'fixed',
    zIndex: 5,
    top: 0,
    width: '100%',
    minHeight: '69px',
    backgroundColor: defaultB,
    padding: '14px 0px 14px 64px',
    boxShadow: '0px 4px 25px rgba(0, 0, 0, 0.1)'
	},
  rootMobile: {
    position: 'fixed',
    zIndex: 5,
    top: 0,
    width: '100%',
    minHeight: '0px',
    backgroundColor: defaultB,
    padding: '14px 24px 14px 24px',
    boxShadow: '0px 4px 25px rgba(0, 0, 0, 0.1)'
  },
  coursesPaper: {
    transition: 'all .3s',
    height: '0px',
    overflow: 'hidden'
  },
  menuPaper: {
    borderRadius: '8px',
    padding: '0px 8px',
    marginTop: 40
  },
  daver: {
    '& .MuiBackdrop-root': {
      backdropFilter: 'blur(16px)',
      transition: 'none !important',
      backgroundColor: 'rgba(0, 0, 0, 0.6) !important'
    }
  }
}

const HeaderBar = withStyles(styles)(({classes, changeLang, CW}) => {
  const { t } = useTranslation()
  const [courses, setCourses] = useState(false)
  const [langMenu, setLangMenu] = useState(false)
  const [profileMenu, setProfileMenu] = useState(false)
  const [signIn, setSignIn] = useState(false)
  const [signUp, setSignUp] = useState(false)
  const [fogot, setFogot] = useState(false)
  const [drawer, setDrawer] = useState(false)

  const handleLang = (l) => {
    changeLang(l)
    setLangMenu(false)
  }

  const handleFogot = () => {
    setSignIn(false)
    setFogot(true)
  }

  window.onclick = e => {
    const cacc = document.querySelector('.cacc_root')
    if (!cacc.contains(e.target) && !!courses) {
      return setCourses(false)
    } else {
      return null
    }
  }

  return(
  <div class='cacc_root'>
    <Grid container
      direction={'row'}
      alignItems={'center'} 
      justifyContent={'space-between'}
      className={CW > 1300 ? classes.root : classes.rootMobile}
    >
      {CW > 1300 ?
        <>
          <Grid item>
            <Grid container
              direction={'row'}
              alignItems={'center'} 
              justifyContent={'flex-start'}
              spacing={4}
            >
              <Grid item 
                onClick={() => {history.push('/');setDrawer(false);setCourses(false)}}
                style={{
                  cursor: 'pointer',
                  paddingBottom: 12
                }}
              >
                <img alt="" src={logo} />
              </Grid>
              <Grid item>
                <CdaButton 
                  color={'secondary'} 
                  size={'l'} 
                  active={!!courses}
                  onClick={() => setCourses(!courses)}
                >
                  <Grid container 
                    direction={'row'} 
                    alignItems={'center'} 
                    justifyContent={'flex-start'} 
                    spacing={2}
                  >
                    <Grid item>
                      {!!courses ?
                        <CrossIcon color={primaryC}/>
                        :
                        <MenuIcon color={primaryC}/>
                      }
                    </Grid>
                    <Grid item>{t('header.catalog')}</Grid>
                  </Grid>
                </CdaButton>
              </Grid>
              <Grid item>
                <CdaButton 
                  color={'tertiary'}
                  name={'programms'}
                  size={'l'} 
                  onClick={() => {history.push('/b2b');setDrawer(false);setCourses(false)}}
                >
                  <Grid style={{color: darkB}}>{t('header.forCompanies')}</Grid>
                </CdaButton>
              </Grid> 
              <Grid item >
                <CdaButton color={'tertiary'} size={'l'} onClick={() => {history.push('/grants');setDrawer(false);setCourses(false)}}>
                  <Grid container direction={'row'} style={{color: darkB}}>
                    {t('header.grants')}
                  </Grid>
                </CdaButton>
              </Grid>
              <Grid item >
                <CdaButton color={'tertiary'} size={'l'} onClick={() => {history.push('/rating');setDrawer(false);setCourses(false)}}>
                  <Grid container direction={'row'} style={{color: darkB}}>
                    {t('header.rating')}
                  </Grid>
                </CdaButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container
              direction={'row'}
              alignItems={'center'} 
              justifyContent={'flex-start'}
              spacing={2}
              style={{paddingRight: '64px'}}
            >
              <Grid item>
                <Tooltip title={t('header.langChoose')}>
                  <IconButton style={{width: 40, height: 40, padding: 10}} 
                    onClick={(e) => setLangMenu(e.currentTarget)}
                  >
                    <LanguageIcon color={primaryC} />
                  </IconButton>
                </Tooltip>
                <Menu
                  elevation={2}
                  variant={'selectedMenu'}
                  anchorEl={langMenu}
                  keepMounted
                  open={Boolean(langMenu)}
                  onClose={() => setLangMenu(false)}
                  classes={{paper: classes.menuPaper}}
                >
                  <Grid container 
                    direction={'column'} 
                    alignItems={'flex-start'} 
                    justifyContent={'center'}
                  >
                    <Grid item style={{width: '100%'}}>
                      <CdaButton color={'tertiary'} size={'l'} onClick={() => handleLang('ru')} fullWidth>
                        <Grid container direction={'row'} style={{color: localStorage.getItem('lang') === 'ru' ? null : darkB}}>
                          <img alt="" 
                            src={twemoji.parse('🇷🇺')?.split('src="')[1]?.split('"')[0]} 
                            height={'18px'} style={{marginRight: 8}}
                          />
                          {' Русский'}
                        </Grid>
                      </CdaButton>
                    </Grid>
                    <Grid item style={{width: '100%'}}>
                      <CdaButton color={'tertiary'} size={'l'} onClick={() => handleLang('kz')} fullWidth disabled>
                        <Grid container direction={'row'} style={{color: greyB/*localStorage.getItem('lang') === 'kz' ? null : darkB*/}}>
                          <img alt="" 
                            src={twemoji.parse('🇰🇿')?.split('src="')[1]?.split('"')[0]} 
                            height={'18px'} style={{marginRight: 8}}
                          />
                          {' Қазақ'}
                        </Grid>
                      </CdaButton>
                    </Grid>
                    <Grid item style={{width: '100%'}}>
                      <CdaButton color={'tertiary'} size={'l'} onClick={() => handleLang('en')} fullWidth disabled>
                        <Grid container direction={'row'} style={{color: greyB/*localStorage.getItem('lang') === 'en' ? null : darkB*/}}>
                          <img alt="" 
                            src={twemoji.parse('🇺🇸')?.split('src="')[1]?.split('"')[0]} 
                            height={'18px'} style={{marginRight: 8}}
                          />
                          {' English'}
                        </Grid>
                      </CdaButton>
                    </Grid>
                  </Grid>
                </Menu>
              </Grid>
              {!localStorage.token ?
                <>
                  <Grid item>
                    <CdaButton 
                      color={'secondary'} 
                      size={'l'}
                      onClick={() => setSignIn(true)}
                    >
                      {t('header.signin')}
                    </CdaButton>
                  </Grid>
                  <Grid item>
                    <CdaButton 
                      color={'primary'} 
                      size={'l'} 
                      onClick={() => {
                        setSignUp(true)
                        setDrawer(false)
                      }}
                    >
                      {t('header.signup')}
                    </CdaButton>
                  </Grid>
                </>
                :
                <Grid item>
                  <Tooltip title={t('header.personTooltip')}>
                    <IconButton
                      onClick={(e) => setProfileMenu(e.currentTarget)}
                      style={{padding: 4}}
                    >
                      <PersonOutlineIcon style={{height: 32, width: 32, color: primaryC}} />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    elevation={2}
                    variant={'selectedMenu'}
                    anchorEl={profileMenu}
                    keepMounted
                    open={Boolean(profileMenu)}
                    onClose={() => setProfileMenu(false)}
                    classes={{paper: classes.menuPaper}}
                  >
                    <Grid container 
                      direction={'column'} 
                      alignItems={'flex-start'} 
                      justifyContent={'center'}
                    >
                      <Grid item style={{width: '100%', marginBottom: 4, paddingBottom: 4, borderBottom: '1px solid ' + greyC}}>
                        <CdaButton color={'tertiary'} size={'l'} fullWidth>
                          <Grid item container
                            direction={'column'}
                            alignItems={'flex-start'}
                            justify={'center'}
                          >
                            <Grid item style={{color: darkB}}>
                              {jwtDecode(localStorage.token)?.firstName}
                            </Grid>
                            <Grid item style={{color: greyB}}>
                              {jwtDecode(localStorage.token)?.email}
                            </Grid>
                          </Grid>
                        </CdaButton>
                      </Grid>
                      <Grid item style={{width: '100%'}}>
                        <CdaButton color={'tertiary'} size={'l'} onClick={() => {history.push('/personal_area'); setProfileMenu(false); setDrawer(false)}} fullWidth>
                          <Grid container direction={'row'} style={{color: darkB}}>
                            <SchoolIcon style={{height: 24, width: 24, marginTop: -6, marginRight: 8}} /> {' ' + t('header.personalArea')}
                          </Grid>
                        </CdaButton>
                      </Grid>
                      <Grid item style={{width: '100%'}}>
                        <CdaButton color={'tertiary'} size={'l'} onClick={() => {history.push('/personal_area'); setProfileMenu(false); setDrawer(false)}} fullWidth>
                          <Grid container direction={'row'} style={{color: darkB}}>
                            <Sertify style={{height: 24, width: 24, marginTop: -6, marginRight: 8}} /> {' ' + t('header.sertify')}
                          </Grid>
                        </CdaButton>
                      </Grid>
                      <Grid item style={{width: '100%', marginTop: 4, paddingTop: 4, borderTop: '1px solid ' + greyC}}>
                        <CdaButton color={'tertiary'} size={'l'} onClick={() => {localStorage.removeItem('token'); setProfileMenu(false); setDrawer(false)}} fullWidth>
                          <Grid container direction={'row'} style={{color: negativeB}}>
                            <LogOut color={negativeB} style={{marginTop: -6, height: 24, marginRight: 8}} /> {' ' + t('header.logOut')}
                          </Grid>
                        </CdaButton>
                      </Grid>
                    </Grid>
                  </Menu>
                </Grid>
              }
            </Grid>
          </Grid>
            <Grid item xs={12} md={12} 
              className={classes.coursesPaper} 
              style={{height: !!courses && document.querySelector('#coursesContent')?.offsetHeight}}
            >
              <Grid id={'coursesContent'} style={{paddingTop: 36}}>
                <CoursesAccordion close={() => {setDrawer(false);setCourses(false)}} active={courses} />
              </Grid>
            </Grid>
        </>
        :
        <>
          <Grid item 
            onClick={() => {history.push('/');setDrawer(false);setCourses(false)}}
            style={{
              cursor: 'pointer',
              paddingTop: 8
            }}
          >
            <img alt="" src={logo} width={'130px'} />
          </Grid>
          <Grid item>
          <Tooltip title={t('header.langChoose')}>
            <IconButton style={{width: 40, height: 40, padding: 10, marginRight: 4}} 
              onClick={(e) => setLangMenu(e.currentTarget)}
            >
              <LanguageIcon color={primaryC} style={{marginTop: 4}} />
            </IconButton>
          </Tooltip>
          <Menu
            elevation={2}
            variant={'selectedMenu'}
            anchorEl={langMenu}
            keepMounted
            open={Boolean(langMenu)}
            onClose={() => setLangMenu(false)}
            classes={{paper: classes.menuPaper}}
          >
            <Grid container 
              direction={'column'} 
              alignItems={'flex-start'} 
              justifyContent={'center'}
            >
              <Grid item style={{width: '100%'}}>
                <CdaButton color={'tertiary'} size={'l'} onClick={() => handleLang('ru')} fullWidth>
                  <Grid container direction={'row'} style={{color: localStorage.getItem('lang') === 'ru' ? null : darkB}}>
                    <img alt="" 
                      src={twemoji.parse('🇷🇺')?.split('src="')[1]?.split('"')[0]} 
                      height={'18px'} style={{marginRight: 8}}
                    />
                    {' Русский'}
                  </Grid>
                </CdaButton>
              </Grid>
              <Grid item style={{width: '100%'}}>
                <CdaButton color={'tertiary'} size={'l'} onClick={() => handleLang('kz')} fullWidth disabled>
                  <Grid container direction={'row'} style={{color: greyB/*localStorage.getItem('lang') === 'kz' ? null : darkB*/}}>
                    <img alt="" 
                      src={twemoji.parse('🇰🇿')?.split('src="')[1]?.split('"')[0]} 
                      height={'18px'} style={{marginRight: 8}}
                    />
                    {' Қазақ'}
                  </Grid>
                </CdaButton>
              </Grid>
              <Grid item style={{width: '100%'}}>
                <CdaButton color={'tertiary'} size={'l'} onClick={() => handleLang('en')} fullWidth disabled>
                  <Grid container direction={'row'} style={{color: greyB/*localStorage.getItem('lang') === 'en' ? null : darkB*/}}>
                    <img alt="" 
                      src={twemoji.parse('🇺🇸')?.split('src="')[1]?.split('"')[0]} 
                      height={'18px'} style={{marginRight: 8}}
                    />
                    {' English'}
                  </Grid>
                </CdaButton>
              </Grid>
            </Grid>
          </Menu>
            <IconButton color={'primary'} style={{marginRight: -12}} onClick={() => setDrawer(true)}>
              <MenuIcon color={primaryC}/>
            </IconButton>
          </Grid>
          <SwipeableDrawer 
            anchor={'right'} 
            open={drawer} 
            onClose={() => setDrawer(false)}
            onOpen={() => setDrawer(true)}
            classes={{root: classes.daver}}
            style={{zIndex: 999}}
          >
            <Grid container
              direction={'row'}
              justify={'flex-end'}
              alignItems={'center'}
              style={{minWidth: '90vw', overflowX: 'hidden'}}
            >
              <Grid item xs={12} md={12} container
                direction={'row'}
                justify={'space-between'}
                alignItems={'center'}
                style={{padding: '0px 8px', minHeight: 69, borderBottom: '2px solid ' + greyC, marginBottom: 8}}
              >
                <Grid item>
                  <IconButton onClick={() => setDrawer(false)}>
                    <ChevronRightIcon />
                  </IconButton>
                </Grid>
                <Grid item>
                  {!localStorage.token ?
                    <CdaButton 
                      color={'secondary'} 
                      size={'l'}
                      onClick={() => setSignIn(true)}
                    >
                      {t('header.signin')}
                    </CdaButton>
                    :
                    <CdaButton color={'tertiary'} size={'l'} fullWidth>
                      <Grid item container
                        direction={'column'}
                        alignItems={'flex-end'}
                        justify={'center'}
                      >
                        <Grid item style={{color: darkB}}>
                          {jwtDecode(localStorage.token)?.firstName}
                        </Grid>
                        <Grid item style={{color: greyB}}>
                          {jwtDecode(localStorage.token)?.email}
                        </Grid>
                      </Grid>
                    </CdaButton>
                  }
                </Grid>
              </Grid>
              {!localStorage.token ?
                <Grid item xs={12} md={12} style={{padding: 8}}>
                  <CdaButton 
                    fullWidth
                    color={'primary'} 
                    size={'l'} 
                    onClick={() => {
                      setSignUp(true)
                      setDrawer(false)
                    }}
                  >
                    {t('header.signup')}
                  </CdaButton>
                </Grid>
                :
                <>
                  <Grid item xs={12} md={12} style={{padding: 8}}>
                    <CdaButton color={'tertiary'} size={'l'} onClick={() => {history.push('/personal_area'); setProfileMenu(false); setDrawer(false)}} fullWidth>
                      <Grid container direction={'row'} style={{color: darkB}}>
                        <SchoolIcon style={{height: 24, width: 24, marginTop: -6, marginRight: 8}} /> {' ' + t('header.personalArea')}
                      </Grid>
                    </CdaButton>
                  </Grid>
                  <Grid item xs={12} md={12} style={{padding: 8}}>
                    <CdaButton color={'tertiary'} size={'l'} onClick={() => {history.push('/personal_area'); setProfileMenu(false); setDrawer(false)}} fullWidth>
                      <Grid container direction={'row'} style={{color: darkB}}>
                        <Sertify style={{height: 24, width: 24, marginTop: -6, marginRight: 8}} /> {' ' + t('header.sertify')}
                      </Grid>
                    </CdaButton>
                  </Grid>
                  <Grid item xs={12} md={12} style={{padding: 8, borderTop: '1px solid ' + greyC, borderBottom: '1px solid ' + greyC, marginBottom: 8}}>
                    <CdaButton color={'tertiary'} size={'l'} onClick={() => {localStorage.removeItem('token'); setProfileMenu(false); setDrawer(false)}} fullWidth>
                      <Grid container direction={'row'} style={{color: negativeB}}>
                        <LogOut color={negativeB} style={{marginTop: -6, height: 24, marginRight: 8}} /> {' ' + t('header.logOut')}
                      </Grid>
                    </CdaButton>
                  </Grid>
                </>
              }
              {discounts?.map(d => 
                <Grid item xs={12} md={12} style={{padding: 8}}>
                  <CdaCardDiscount
                    mobile
                    elevation={2}
                    onClick={() => {}} 
                    color={d?.color} 
                    label={d?.title} 
                    info={d?.description}
                    background={d?.background}
                  />
                </Grid>
              )}
              <Grid item xs={12} md={12} 
                style={{
                  padding: 8, 
                  borderBottom: '1px solid' + greyC, 
                  borderTop:'1px solid' + greyC,
                  marginTop: 8
                }}
              >
                <Accordion elevation={0}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <CdaTypography type={'bm'} label={t('header.catalog')} />
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container 
                      alignItems={'flex-start'} 
                      justify={'flex-start'}
                      spacing={2}
                    >
                      <CoursesAccordion mobile close={() => {setDrawer(false);setCourses(false)}} />
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={12} md={12} 
                style={{
                  padding: 8,
                  paddingTop: 10,
                  paddingBottom: 10,
                  borderBottom: '1px solid' + greyC
                }}
              >
                <CdaButton color={'tertiary'} size={'l'} fullWidth onClick={() => {history.push('/b2b');setDrawer(false);setCourses(false)}}>
                  <Grid container direction={'row'}>
                    <CdaTypography type={'bm'} color={'classic'} label={t('header.forCompanies')} />
                  </Grid>
                </CdaButton>
              </Grid>
              <Grid item xs={12} md={12} 
                style={{
                  padding: 8,
                  paddingTop: 10,
                  paddingBottom: 10,
                  borderBottom: '1px solid' + greyC
                }}
              >
                <CdaButton color={'tertiary'} size={'l'} fullWidth onClick={() => {history.push('/grants');setDrawer(false);setCourses(false)}}>
                  <Grid container direction={'row'}>
                    <CdaTypography type={'bm'} color={'classic'} label={t('header.grants')} />
                  </Grid>
                </CdaButton>
              </Grid>
              <Grid item xs={12} md={12} 
                style={{
                  padding: 8,
                  paddingTop: 10,
                  paddingBottom: 10,
                  borderBottom: '1px solid' + greyC
                }}
              >
                <CdaButton color={'tertiary'} size={'l'} fullWidth onClick={() => {history.push('/rating');setDrawer(false);setCourses(false)}}>
                  <Grid container direction={'row'}>
                    <CdaTypography type={'bm'} color={'classic'} label={t('header.rating')} />
                  </Grid>
                </CdaButton>
              </Grid>
            </Grid>
          </SwipeableDrawer>
        </>
      }
      {!!signIn && <SignInForm open={signIn} handleClose={() => setSignIn(false)} handleFogot={handleFogot} />}
      {!!fogot && <FogotModal open={fogot} handleClose={() => setFogot(false)} />}
      {!!signUp && 
        <DialogCda
          maxWidth={'xs'}
          TransitionComponent={Transition}
          open={signUp}
          onClose={() => setSignUp(false)}
          close={() => setSignUp(false)}
          title={t('header.signup')}
          content={
            <FeedBackForm modal handleClose={() => setSignUp(false)} page={'sign_up'}/>
          }
        />
      }
    </Grid>
    </div>
  );
})

export default HeaderBar;