import React, {useState} from 'react'
import { Grid, MenuItem } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import CdaTypography from '../cda-UIkit/Typography/CdaTypography';
import {useSnackbar, withSnackbar} from 'notistack'
import CdaButton from '../cda-UIkit/Buttons/CdaButton';
import { secondaryB } from '../cda-UIkit/constants/colors';
import CdaTextField from '../cda-UIkit/Inputs/CdaTextField';
import PhoneInput from '../lib/universalPhones/PhoneInput';
import { feedBack } from '../requests/auth';
import moment from 'moment';
import MaskedTextInput from '../cda-UIkit/Inputs/MaskedTextInput';
import CdaSelect from '../cda-UIkit/Inputs/CdaSelect';
import { useEffect } from 'react';
import { getProfsInfo } from '../requests/courses';

const init = {
  firstName: null,
  lastName: null,
  email: null,
  phone: null,
  birthDate: null,
  itemId: null
}

const FeedBackForm = ({ CW, title, subTitle, modal, handleClose, color, page, itemId, grant }) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [form, setForm] = useState(init)
  const [error, setError] = useState(init)
  const [ready, setReady] = useState(false)
  const [profs, setProfs] = useState([])

  const handleChange = (e) => {
    setError(init)
    setForm(prevState => ({...prevState, [e.target.name]: e.target.value}))
  }

  const handleChangePhone = (val) => {
    setError(init)
    setForm(prev => ({...prev, phone: val.replace(/[^+\d]/g, '')}))
  }

  const handleChangeDate = (e) => {
    const name = e.target.name
    const value = e.target.value
    let vArr = value.split('.')
    if (!!vArr[1]) {
      if (vArr[0] < 1) {
        vArr[0] = '01'
      }
      if (vArr[1] > 12) {
        vArr[1] = 12
      }
      if (
        vArr[0] >
        moment()
          .month(vArr[1] - 1)
          .date(1)
          .daysInMonth()
      ) {
        vArr[0] = moment()
          .month(vArr[1] - 1)
          .date(1)
          .daysInMonth()
      }
    }
    if (!!vArr[2]) {
      if (vArr[1] < 1) {
        vArr[1] = '01'
      }
      if (vArr[2] > moment(+new Date()).year()) {
        vArr[2] = name === 'dateTo' ? moment(+new Date()).year() + 1 : moment(+new Date()).year()
        if (moment(vArr.join('.'), 'DD.MM.YYYY') < moment().subtract(7, 'days')) {
          vArr = moment().subtract(7, 'days').format('DD.MM.YYYY').split('.')
        }
      }
      if (vArr[2].length === 4 && vArr[2] <= moment(+new Date()).year() - 100) {
        vArr[2] = moment(+new Date()).year() - 100
      }
    }
    setError(init)
    setForm(prevState => ({...prevState, [name]: vArr?.join()}))
  }

  const handleSend = () => {
    let errors = {}
    let tic = false
    if (!form?.email || ((!form?.email?.includes('@') || !form?.email?.includes('.')) || form?.email?.length < 5)) {
      errors.email = true
      tic = true
    }
    if (!form?.firstName || form?.firstName?.length < 1) {
      errors.firstName = true
      tic = true
    }
    if (!form?.lastName || form?.lastName?.length < 1) {
      errors.lastName = true
      tic = true
    }
    if (!form?.phone || form?.phone?.length < 9) {
      errors.phone = true
      tic = true
    }
    if (!!grant) {
      if (!form?.birthDate || form?.birthDate?.length < 10) {
        errors.birthDate = true
        tic = true
      }
      if (!form?.itemId) {
        errors.itemId = true
        tic = true
      }
    }
    if (!!tic) {
      setError(errors)
      return null;
    }
    feedBack({
      ...form, page, 
      itemId: !!grant ? form?.itemId : itemId, 
      birthDate: !!grant ? moment(form?.birthDate, 'DD.MM.YYYY')?.format() : undefined
    }).then(r => {
      if (r?.status === 200) {
        enqueueSnackbar(t('signModal.feedBack'), {variant: 'success'})
        setReady(true)
      } else {
        enqueueSnackbar(t('error'), {variant: 'error'})
        console.log(r)
      }
    })
  }

  useEffect(() => {
    if (!!grant) {
      getProfsInfo().then(r => {
        if(!!r?.length) {
          setProfs(r/*?.sort((a,b) => a.id > b.id ? 1 : -1)*/)
        }
      })
    }
  }, [grant])

  return(
    <Grid item xs={12} md={12}
      container 
      direction={'row'} 
      justify={'center'} 
      alignItems={'center'}
      spacing={!modal && (CW < 369 ? 1 : 3)}
      style={!!modal ? {marginRight: -16} : {
        borderRadius: 24, 
        background: !!color ? color : secondaryB, 
        margin: CW < 1070 ? 24 : 0,
        marginBottom: 24,
        marginTop: CW < 1070 && 0
      }}
      id={'feedBack'}
    >
      {(!!title || !!subTitle) && !modal &&
        <Grid item xs={12} md={6}
          container
          direction={'column'}
          alignItems={'flex-start'}
          justify={'center'}
          spacing={3}
        >
          {!!title &&
            <Grid item>
              <CdaTypography type={'h3'} label={title} style={{fontWeight: 600}}/>
            </Grid>
          }
          {!!title && 
            <Grid item>
              <CdaTypography type={'bm'} label={subTitle} style={{whiteSpace: !!grant && 'pre'}} />
            </Grid>
          }
        </Grid>
      }
      <Grid item xs={12} md={(!!title || !!subTitle) && !modal ? 6 : 12}
        container
        direction={'row'}
        alignItems={'flex-start'}
        justify={'center'}
        spacing={2}
      >
        {!ready ?
          <>
            <Grid item xs={12} md={!modal ? 6 : 12}>
              <CdaTextField 
                fullWidth
                name={'firstName'}
                value={form?.firstName || null}
                onChange={handleChange}
                label={t('chooseHelpForm.firstNamePattern')} 
                labelTop={t('chooseHelpForm.firstName')}
                error={!!error.firstName}
                labelBottom={!!error.firstName && t('signModal.firstNameError')}
                bottomColor={'negative'}
              />
            </Grid>
            <Grid item xs={12} md={!modal ? 6 : 12}>
              <CdaTextField 
                fullWidth 
                name={'lastName'}
                value={form?.lastName || null}
                onChange={handleChange}
                label={t('chooseHelpForm.lastNamePattern')} 
                labelTop={t('chooseHelpForm.lastName')} 
                error={!!error.lastName}
                labelBottom={!!error.lastName && t('signModal.lastNameError')}
                bottomColor={'negative'}
              />
            </Grid>
            {!!grant && 
              <>
                <Grid item xs={12} md={!modal ? 6 : 12}>
                  <MaskedTextInput 
                    fullWidth 
                    name={'birthDate'}
                    value={form?.birthDate || null}
                    onChange={handleChangeDate}
                    label={'14.05.1999'} 
                    labelTop={t('chooseHelpForm.birthDate')}
                    error={!!error.birthDate}
                    labelBottom={!!error.birthDate && t('signModal.birthDateError')}
                    bottomColor={'negative'}
                    mask={'99.99.9999'}
                  />
                </Grid>
                <Grid item xs={12} md={!modal ? 6 : 12}>
                  <CdaSelect
                    fullWidth 
                    name={'itemId'}
                    value={form?.itemId || null}
                    onChange={handleChange}
                    label={profs?.[0]?.name || t('chooseHelpForm.itemId')} 
                    labelTop={t('chooseHelpForm.itemId')}
                    error={!!error.itemId}
                    labelBottom={!!error.birthDate && t('signModal.itemIdError')}
                    bottomColor={'negative'}
                  >
                    {profs?.map(e => 
                      <MenuItem key={e.id} value={e.id}>{e.name}</MenuItem>
                    )}
                  </CdaSelect>
                </Grid>
              </>
            }
            <Grid item xs={12} md={!modal ? 6 : 12}>
              <CdaTextField 
                fullWidth 
                name={'email'}
                value={form?.email || null}
                onChange={handleChange}
                label={'email@email.com'} 
                labelTop={'Email'}
                error={!!error.email}
                labelBottom={!!error.email && t('signModal.emailError')}
                bottomColor={'negative'}
              />
            </Grid>
            <Grid item xs={12} md={!modal ? 6 : 12}>
              <PhoneInput
                fullWidth
                value={form?.phone || null}
                onChange={e => handleChangePhone(e.target.value)}
                labelTop={t('chooseHelpForm.phone')}
                error={!!error.phone}
                labelBottom={!!error.phone && t('signModal.phoneError')}
                bottomColor={'negative'}
              />
            </Grid>
            <Grid item xs={12} md={!modal ? 6 : 12}>
              <CdaTypography type={'label'} label={t('chooseHelpForm.attention')} />
            </Grid>
            <Grid item xs={12} md={!modal ? 6 : 12}>
              <CdaButton 
                fullWidth
                size={'l'} 
                color={'primary'} 
                onClick={handleSend}
              >
                {t('chooseHelpForm.send')}
              </CdaButton>
            </Grid>
          </>
          :
          <>
            <Grid item xs={12} md={12}>
              <CdaTypography type={'bm'} label={t('signModal.feedBack')} />
            </Grid>
            {!!modal &&
              <Grid item xs={12} md={12}>
                <CdaButton 
                  fullWidth
                  size={'l'} 
                  color={'primary'} 
                  onClick={handleClose}
                >
                  {t('close')}
                </CdaButton>
              </Grid>
            }
          </>
        }
      </Grid>
    </Grid>
  );
}
export default withSnackbar(FeedBackForm);