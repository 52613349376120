import React from 'react'
import { FormControlLabel, makeStyles, Radio, withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import CdaTypography from '../Typography/CdaTypography'
import { darkB, greyB } from '../constants/colors'

const styles = {
  root: {
    padding: 8,
    '&:hover': {
      backgroundColor: 'transparent !important',
    }
  },
  icon: {
    width: 16,
    height: 16,
    border: '1px solid' + darkB,
    borderRadius: 16,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    'input:disabled  ~ &': {
      border: '1px solid' + greyB,
    }
  },
  checkedIcon: {
    width: 16,
    height: 16,
    border: '1px solid #2B6BB2',
    borderRadius: 16,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    'input:disabled  ~ &': {
      border: '1px solid' + greyB,
    }
  }
}
const StyledRadio = withStyles(styles)(({ classes, color, ...other }) => (
	<Radio
	  style={{marginLeft: 2}}
		{...other}
		className={classes.root}
    disableRipple
		checkedIcon={
			<span className={classes.checkedIcon}>
				<svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
				<rect x="0.5" y="0.5" width="7" height="7" rx="3.5" fill={!!other.disabled ? greyB : "#2B6BB2"} stroke={!!other.disabled ? greyB : "#2B6BB2"}/>
				</svg>
			</span>
		}
		icon={<span className={classes.icon} />}
	/>
))

const useStyle=makeStyles(theme=>({
  root:{
    marginTop: '2px',
    cursor: 'pointer'
  }
}))
const CdaRadio = ({ label, ...props }) => {
	const classes=useStyle()
	return (
		<FormControlLabel
      classes={{label:classes.root}}
      control={<StyledRadio {...props} />} 
      label={
        <CdaTypography label={label} color={!!props.disabled ? 'grey' : 'classic'} type={'s'} style={{cursor: !props.disabled && 'pointer'}} />
      }
    />
	)
}

export default CdaRadio

CdaRadio.propTypes = {
	color: PropTypes.oneOf(['primary', 'secondary']),
	label: PropTypes.string,
	name: PropTypes.string,
	disabled: PropTypes.bool
}
