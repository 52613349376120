import React from 'react'
import { Card, CardActionArea, CardContent, Grid, withStyles } from '@material-ui/core'
import {
	secondaryB,
	negativeC,
	positiveC,
	warningC,
	defaultB,
	cloudB,
  greyC,
} from '../constants/colors'
import PropTypes from 'prop-types'
import '../fonts/style.css'
import CdaTypography from '../Typography/CdaTypography'
import CdaLinearProgress from '../Progresses/CdaLinearProgress'
import { useTranslation } from 'react-i18next'

const styledBy = (property, mapping) => props => mapping[props[property]]

const styles = {
	root: {
		background: defaultB,
    padding: '0px',
		borderRadius: '16px',
    border: '1px solid ' + greyC,
    maxWidth: '310px'
	},
  content: {
    padding: '24px',
    paddingBottom: '16px !important'
  },
  icon: {
    padding: '8px',
    height: '48px',
    width: '48px',
		borderRadius: '16px',
    background: styledBy('color', {
			classic: cloudB,
			primary: secondaryB,
			secondary: secondaryB,
			negative: negativeC,
			positive: positiveC,
			warning: warningC
		})
  }
}

const CdaCourseCard = withStyles(styles)(({ classes, color, icon, label, info, clicable, ...other }) => {
  const { t, i18n } = useTranslation()

  const Content = () => (
    <CardContent className={classes.content}>
      <Grid container direction={'row'} justify={'flex-start'} alignItems={'center'}>
        <Grid item container justify={'space-between'} alignItems={'center'} style={{marginBottom: 18}}>
          <Grid item>
            <CdaTypography type={'l'} label={label} />
          </Grid>
          <Grid item container justify={'center'} alignItems={'center'} className={classes.icon}>
            {!!icon &&
              icon
            }
          </Grid>
        </Grid>
        <Grid item container xs={12} md={12} justify={'flex-end'} alignItems={'center'}>
          <Grid item>
            <CdaTypography type={'bm'} 
              label={parseInt(((info?.v?.count + info?.t?.count) / (info?.v?.total + info?.t?.total)) * 100) + '%'}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={12}>
          <CdaLinearProgress 
            num={parseInt(((info?.v?.count + info?.t?.count) / (info?.v?.total + info?.t?.total)) * 100)}
          />
        </Grid>
        <Grid item xs={12} md={12} container direction={'row'} spacing={1} style={{marginTop: 12}}>
          <Grid item>
            <CdaTypography type={'c'} 
              label={`${info?.v?.count}/${info?.v?.total} ${t('PersonalSpace.video')}`} 
            />
          </Grid>
          <Grid item>
            <CdaTypography type={'c'} 
              label={`${info?.t?.count}/${info?.t?.total} ${t('PersonalSpace.test')} `} 
            />
          </Grid>
        </Grid>
      </Grid>
    </CardContent>
  )

  return(
		<Card 
      clicable
			className={classes.root} 
			{...other} 
    >
      {!!clicable ?
        <CardActionArea>{Content()}</CardActionArea> :
        Content()
      } 
    </Card>
  );
})

export default CdaCourseCard;

CdaCourseCard.propTypes = {
	color: PropTypes.oneOf([
		'classic',
		'primary',
		'secondary',
		'negative',
		'positive',
		'warning'
	]),
  icon: PropTypes.node,
  clicable: PropTypes.bool,
	label: PropTypes.string,
  info: PropTypes.object,
}
