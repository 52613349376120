import React, {useEffect, useState} from 'react'
import { Avatar, Grid, Step, Stepper, StepLabel, withStyles, StepConnector, MobileStepper } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import { history } from '../lib/history';
import { defaultB, greyB, primaryB, secondaryB, positiveB, secondaryC } from '../cda-UIkit/constants/colors';
import CdaTypography from '../cda-UIkit/Typography/CdaTypography';
import Footer from '../components/Footer';
import CdaTextField from '../cda-UIkit/Inputs/CdaTextField';
import CdaButton from '../cda-UIkit/Buttons/CdaButton';
import bg from '../img/profTestBg.jpg'
import { careerTestAnswer, careerTestFinish, getCareerTest } from '../requests/students';
import CdaRadio from '../cda-UIkit/Inputs/CdaRadio';
import MaskedTextInput from '../cda-UIkit/Inputs/MaskedTextInput';
import IconConnector from '../components/IconConnector';
import moment from 'moment';
import CdaCardHome from '../cda-UIkit/Cards/CdaCardHome';
import PhoneInput from '../lib/universalPhones/PhoneInput';
import CdaCheckbox from '../cda-UIkit/Inputs/CdaCheckbox';
import ShevronIcon from '../img/icons/ShevronIcon';
import CdaInstructorCard from '../cda-UIkit/Cards/CdaInstructorCard'
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const init = {
  phone: null,
  name: null,
  email: null
}

const ColorlibConnector = withStyles({
  active: {
    '& $line': {
      borderColor: primaryB,
    },
  },
  completed: {
    '& $line': {
      borderColor: positiveB,
    },
  },
  line: {
    borderColor: greyB
  }
})(StepConnector);

const TestPage = ({CW}) => {
  const { t, i18n } = useTranslation()
  const [questions, setQuestions] = useState([
    {
      "id": "1",
      "name": "Вопрос 1",
      "description": "Сколько будет \"2\" + \"2\"?",
      "type": "text",
      "variants": [
        "99"
      ]
    },
    {
      "id": "2",
      "name": "Вопрос 2",
      "description": "Что такое рекурсия?",
      "type": "check",
      "variants": [
        "Это обычный цикл",
        "Это изменение курса приложения",
        "Это изменение переменной",
        "Это функция, содержащая в теле вызов самой себя."
      ]
    },
    {
      "id": "3",
      "name": "Вопрос 3",
      "description": "Где есть цифра 3?",
      "type": "radio",
      "variants": [
        "1",
        "2",
        "3",
        "4"
      ]
    },
    {
      "id": "3",
      "name": "Вопрос 3",
      "description": "Где есть цифра 3?",
      "type": "radio",
      "variants": [
        "1",
        "2",
        "3",
        "4"
      ]
    },
    {
      "id": "3",
      "name": "Вопрос 3",
      "description": "Где есть цифра 3?",
      "type": "radio",
      "variants": [
        "1",
        "2",
        "3",
        "4"
      ]
    },
    {
      "id": "3",
      "name": "Вопрос 3",
      "description": "Где есть цифра 3?",
      "type": "radio",
      "variants": [
        "1",
        "2",
        "3",
        "4"
      ]
    },
    {
      "id": "3",
      "name": "Вопрос 3",
      "description": "Где есть цифра 3?",
      "type": "radio",
      "variants": [
        "1",
        "2",
        "3",
        "4"
      ]
    },
    {
      "id": "3",
      "name": "Вопрос 3",
      "description": "Где есть цифра 3?",
      "type": "radio",
      "variants": [
        "1",
        "2",
        "3",
        "4"
      ]
    },
    {
      "id": "3",
      "name": "Вопрос 3",
      "description": "Где есть цифра 3?",
      "type": "radio",
      "variants": [
        "1",
        "2",
        "3",
        "4"
      ]
    },
    {
      "id": "3",
      "name": "Вопрос 3",
      "description": "Где есть цифра 3?",
      "type": "radio",
      "variants": [
        "1",
        "2",
        "3",
        "4"
      ]
    },
    {
      "id": "3",
      "name": "Вопрос 3",
      "description": "Где есть цифра 3?",
      "type": "radio",
      "variants": [
        "1",
        "2",
        "3",
        "4"
      ]
    },
    {
      "id": "3",
      "name": "Вопрос 3",
      "description": "Где есть цифра 3?",
      "type": "radio",
      "variants": [
        "1",
        "2",
        "3",
        "4"
      ]
    },
    {
      "id": "3",
      "name": "Вопрос 3",
      "description": "Где есть цифра 3?",
      "type": "radio",
      "variants": [
        "1",
        "2",
        "3",
        "4"
      ]
    }
  ])
  const [question, setQuestion] = useState(0)
  const [answer, setAnswer] = useState()
  const [student, setStudent] = useState(init)

  const hanndleChange = (e) => {
    if (!!answer) {
      if (answer?.includes(e)) {
        setAnswer(answer?.filter(f => f !== e))
      } else {
        setAnswer(prevState => ([...prevState, e]?.sort((a,b) => a > b ? -1 : 1)))
      }
    } else {
      setAnswer([e])
    }
  }

  const handleBack = () => {
    /*careerTestAnswer(
      {
        cgtId: questions?.[question]?.id,
        answer: answer?.replace(/[^\d]/g, ''),
        studentId: student?.id,
      }
    ).then(r => {
      if (!!r) {*/
        setQuestion(question - 1)
        setAnswer()
      /*}
    })*/
  }

  const handleNext = () => {
    /*careerTestAnswer(
      {
        cgtId: questions?.[question]?.id,
        answer: answer?.replace(/[^\d]/g, ''),
        studentId: student?.id,
      }
    ).then(r => {
      if (!!r) {*/
        setQuestion(question + 1)
        setAnswer()
      /*}
    })*/
  }

  const ColorlibStepIcon = (i, q, props) => {
    const { active, completed } = props;
  
    return (
      <CdaButton 
        size={'s'} 
        color={!!active ? 'primary' : (!!completed ? (!q?.rating ? 'negative' : 'positive') : 'grey')}
        style={{borderRadius: '100%', padding: 0}}
      >
        <CdaTypography type={'label'} label={i + 1} style={{marginTop: 2, cursor: 'pointer'}} />
      </CdaButton>
    );
  }

  const QuestionType = (q) => {
    if (!q) {
      return '';
    }
    if (q === 'text') {
      return (
        <Grid item>
          <MaskedTextInput
            topColor={'classic'}
            name={'answer'}
            value={answer || null}
            onChange={(e) => setAnswer(e.target.value)}
            fullWidth
            label={t('testPage.pasteAnswer')}
            mask={questions?.[question]?.variants?.[0]}
            labelTop={t('testPage.answer')} 
          />
        </Grid>
      )
    }
    if (q === 'check') {
      return (
        !!questions[question]?.variants && 
        Object.keys(questions[question]?.variants)?.map((q,i) => 
          <Grid item>
            <CdaCheckbox
              label={questions[question]?.variants?.[i]}
              checked={answer?.includes(questions[question]?.variants?.[i])}
              onClick={() => hanndleChange(questions[question]?.variants?.[i])}
            />
          </Grid>
        )
      )
    }
    if (q === 'radio') {
      return (
        !!questions[question]?.variants && 
        Object.keys(questions[question]?.variants)?.map((q,i) => 
          <Grid item>
            <CdaRadio 
              label={questions[question]?.variants?.[i]}
              checked={answer === questions[question]?.variants?.[i]}
              onClick={() => setAnswer(questions[question]?.variants?.[i])}
            />
          </Grid>
        )
      )
    }
  }

  useEffect(() => {
    moment.locale(localStorage.lang)
  }, [localStorage.lang])

  useEffect(() => {
    if (!!questions?.length) {
      document.querySelector('#step_' + question).scrollIntoView({block: 'end', inline: "center", behavior: "smooth"})
    }
  }, [question])

  useEffect(() => {
    window.scroll(0,0)
  }, [])

  return (
    <Grid container
      direction={'column'}
      alignItems={'flex-start'} 
      justify={'flex-start'}
      style={{minHeight: '100vh'}}
    >
      <Grid item xs={12} md={12}
        container
        direction={'row'}
        alignItems={'center'} 
        justify={'space-between'}
        spacing={2}
        style={{padding: CW > 850 ? '24px 64px 0px 64px' : 8, marginTop: CW < 850 && 16}}
      >
        <Grid item>
          <CdaButton color={'tertiary'} size={'l'} onClick={() => history.goBack()}>
            <Grid container
              direction={'row'} 
              alignItems={'center'} 
              justifyContent={'flex-start'}
            >
              <Grid item>
                <ShevronIcon style={{transform: 'rotate(90deg)', marginRight: 4, marginTop: -6, marginLeft: -10}} color={primaryB} />
              </Grid>
              <Grid item>{t('back')}</Grid>
            </Grid>
          </CdaButton>
        </Grid>
        <Grid item>
        </Grid>
      </Grid>
      <Grid container 
        direction={'row'} 
        alignItems={'center'} 
        justify={'center'}
      >
        <Grid container 
          direction={'column'} 
          alignItems={'flex-start'} 
          justify={'space-between'}
          style={{
            margin: CW > 850 ? 64 : '48px 8px 64px 8px',
            marginTop: 12,
            overflowX: 'hidden',
            overflowY: 'hidden', 
            maxWidth: 1600,
            minHeight: 560,
            borderRadius: 32,
            background: secondaryC,
            padding: CW > 850 ? '32px 64px' : 24,
          }}
          spacing={4}
        >
          {CW > 1000 ? 
            <Grid xs={12} md={12} container direction={'row'} justify={'flex-end'} style={{overflow: 'hidden'}}>
              <Grid item>
                <Stepper nonLinear
                  activeStep={question}
                  connector={<ColorlibConnector />}
                  style={{
                    padding: 0,
                    width: '100%',
                    overflowX: 'auto',
                    background: 'transparent',
                    WebkitOverflowScrolling: 'touch'
                  }}
                >
                  {questions?.map((q, i) => (
                    <Step key={q?.id} id={'step_' + i}>
                      <StepLabel 
                        StepIconComponent={(props) => ColorlibStepIcon(i, q, props)}
                        onClick={() => setQuestion(i)} 
                        completed={!!q?.answer}
                      />
                    </Step>
                  ))}
                </Stepper>
              </Grid>
            </Grid>
            :
            <Grid item xs={12} md={12}>
              <Stepper nonLinear
                activeStep={question}
                connector={<ColorlibConnector />}
                style={{
                  padding: 0,
                  width: '100%',
                  overflowX: 'auto',
                  background: 'transparent',
                  WebkitOverflowScrolling: 'touch'
                }}
              >
                {questions?.map((q, i) => (
                  <Step key={q?.id} id={'step_' + i}>
                    <StepLabel 
                      StepIconComponent={(props) => ColorlibStepIcon(i, q, props)}
                      onClick={() => setQuestion(i)} 
                      completed={!!q?.answer}
                    />
                  </Step>
                ))}
              </Stepper>
            </Grid>
          }
          <Grid item xs={12} md={12} container direction={'row'} alignItems={'flex-start'} justify={'censter'} spacing={4}>
            <Grid item xs={12} md={12}>
              {/*<CdaTypography
                type={'label'}
                color={'grey'}
                label={(question + 1) + '/' + questions?.length}
                style={{fontWeight: 600, marginBottom: 24}}
              />*/}
              <CdaTypography
                type={'h3'}
                label={`${questions[question]?.name}:`}
                style={{fontWeight: 600}}
              />
              <CdaTypography
                type={'subtitle'}
                label={`${questions[question]?.description}`}
              />
            </Grid>
            <Grid item container 
              direction={'column'} 
              alignItems={'flex-start'} 
              justify={'flex-start'}
              spacing={1}
            >
              {QuestionType(questions?.[question]?.type)}
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}
            container
            direction={'row'}
            alignItems={'center'}
            justify={'space-between'}
            spacing={1}
          >
            <Grid item>
              <CdaButton
                size={'l'} 
                color={'primary'}
                onClick={question + 1 !== questions?.length ? handleNext : ''}
                disabled={!answer}
              >
                {question + 1 !== questions?.length ? t('testPage.setAnswer') : t('profTest.finish')}
              </CdaButton>
            </Grid>
            <Grid item>
              <CdaButton 
                size={'s'} 
                color={'primary'}
                onClick={handleBack}
                disabled={question < 1}
                style={{width: 32, marginRight: 8}}
              >
                <ChevronRightIcon 
                  style={{
                    transform: 'rotate(180deg)'
                  }}
                />
              </CdaButton>
              <CdaButton 
                size={'s'} 
                color={'primary'}
                onClick={handleNext}
                disabled={(!!questions?.length) && question >= questions?.length - 1}
                style={{width: 32}}
              >
                <ChevronRightIcon />
              </CdaButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default TestPage;