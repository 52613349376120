import React from 'react'
import { darkB } from "../../cda-UIkit/constants/colors"

const Sertify = ({size = '24', color = darkB, style}) => (
  <div style={style}>
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 6H15" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M18 9.5H6" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M6 15H9" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M17.1213 13.3787C18.2929 14.5502 18.2929 16.4497 17.1213 17.6213C15.9497 18.7929 14.0502 18.7929 12.8787 17.6213C11.7071 16.4497 11.7071 14.5502 12.8787 13.3787C14.0502 12.2071 15.9497 12.2071 17.1213 13.3787" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M17 17.7212V22.5002L15 21.5002L13 22.5002V17.7212" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M17 19.5H20C21.105 19.5 22 18.605 22 17.5V4.5C22 3.395 21.105 2.5 20 2.5H4C2.895 2.5 2 3.395 2 4.5V17.5C2 18.605 2.895 19.5 4 19.5H13" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </div>
);

export default Sertify;