import React from 'react'
import { darkB } from "../../cda-UIkit/constants/colors"

const ShevronIcon = ({size = '20', color = darkB, style}) => (
  <div style={style}>
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 10L12 14L16 10" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </div>
);

export default ShevronIcon;