import React, {useEffect, useState} from 'react'
import { Avatar, Grid } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import { history } from '../lib/history';
import { secondaryB } from '../cda-UIkit/constants/colors';
import CdaTypography from '../cda-UIkit/Typography/CdaTypography';
import Footer from '../components/Footer';
import CdaTextField from '../cda-UIkit/Inputs/CdaTextField';
import CdaButton from '../cda-UIkit/Buttons/CdaButton';
import bg from '../img/profTestBg.jpg'
import { careerTestAnswer, careerTestFinish, getCareerTest } from '../requests/students';
import CdaRadio from '../cda-UIkit/Inputs/CdaRadio';
import MaskedTextInput from '../cda-UIkit/Inputs/MaskedTextInput';
import IconConnector from '../components/IconConnector';
import moment from 'moment';
import CdaCardHome from '../cda-UIkit/Cards/CdaCardHome';
import PhoneInput from '../lib/universalPhones/PhoneInput';

const init = {
  phone: null,
  name: null,
  email: null
}

const ProfTestPage = ({CW}) => {
  const { t, i18n } = useTranslation()
  const [questions, setQuestions] = useState([])
  const [question, setQuestion] = useState()
  const [answer, setAnswer] = useState()
  const [student, setStudent] = useState(init)
  const [courses, setCourses] = useState([])

  const hanndleChangePhone = (val) => {
    setStudent(prev => ({...prev, phone: val.replace(/[^+\d]/g, '')}))
  }

  const hanndleChangeName = (val) => {
    setStudent(prev => ({...prev, name: val}))
  }
  
  const hanndleChangeEmail = (val) => {
    setStudent(prev => ({...prev, email: val}))
  }

  const handleStart = () => {
    getCareerTest(student).then(r => {
      if(!!r?.cgt?.length) {
        setQuestion(0)
        setQuestions(r?.cgt?.filter(f => !f?.is_course))
        setStudent(prev => ({...prev, id: r?.studentId}))
      }
    })
  }

  const handleNext = () => {
    careerTestAnswer(
      {
        cgtId: questions?.[question]?.id,
        answer: answer?.replace(/[^\d]/g, ''),
        studentId: student?.id,
      }
    ).then(r => {
      if (!!r) {
        setQuestion(question + 1)
        setAnswer()
      }
    })
  }

  const handleFinish =() => {
    careerTestFinish(
      {
        cgtId: questions?.[question]?.id,
        answer: answer?.replace(/[^\d]/g, ''),
        studentId: student?.id,
      }
    ).then(r => {
      if (!!r) {
        setQuestion()
        setAnswer()
        setCourses(r)
      }
    })
  }

  useEffect(() => {
    moment.locale(localStorage.lang)
  }, [localStorage.lang])

  useEffect(() => {
    window.scroll(0,0)
  }, [])

  return (
    <Grid container
      direction={'column'}
      alignItems={'center'}
      justify={'space-between'}
      style={{minHeight: '100vh'}}
    >
      <Grid container 
        direction={'row'} 
        alignItems={'center'} 
        justify={'center'}
      >
        {!questions?.length ? 
          <Grid container 
            direction={'column'} 
            alignItems={'center'} 
            justify={'center'}
            style={{
              margin: CW > 850 ? 64 : '48px 8px 64px 8px',
              overflowX: 'hidden',
              overflowY: 'hidden', 
              maxWidth: 1600,
              borderRadius: 32,
              background: 'transparent',
              padding: CW > 850 ? 64 : 24,
              position: 'relative'
            }}
            spacing={3}
          >
            <Avatar 
              alt={''} 
              src={bg} 
              style={{
                height: '100%', 
                width: '100%', 
                position: 'absolute',
                zIndex: -1,
                borderRadius: 32
              }}
            />
            <Grid item style={{maxWidth: 800}}>
              <CdaTypography
                type={CW > 850 ? 'h2' : 'subTitle'}
                color={'white'}
                label={t('profTest.title')} 
                style={{fontWeight: 600, textAlign: 'center'}} 
              />
            </Grid>
            <Grid item style={{maxWidth: 600}}>
              <CdaTypography 
                type={'bm'} 
                color={'white'}
                label={t('profTest.subTitle')} 
                style={{textAlign: 'center'}} 
              />
            </Grid>
            <Grid item container 
              direction={'row'}
              alignItems={'center'}
              justify={'center'}
              spacing={2}
              style={{maxWidth: 500}}
            >
              <Grid item xs={12} md={12}>
                <CdaTextField
                  topColor={'white'}
                  name={'name'}
                  value={student?.name || null}
                  onChange={(e) => hanndleChangeName(e.target.value)}
                  fullWidth
                  label={t('chooseHelpForm.firstNamePattern')} 
                  labelTop={t('chooseHelpForm.firstName')} 
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <CdaTextField
                  topColor={'white'}
                  name={'email'}
                  value={student?.email || null}
                  onChange={(e) => hanndleChangeEmail(e.target.value)}
                  fullWidth
                  label={'example@email.com'} 
                  labelTop={'Email'} 
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <PhoneInput
                  topColor={'white'}
                  name={'phone'}
                  value={student?.phone || null}
                  onChange={(e) => hanndleChangePhone(e.target.value)}
                  fullWidth 
                  labelTop={t('chooseHelpForm.phone')}
                />
              </Grid>
              <Grid item xs={12} md={12} style={{paddingTop: 24}}>
                <CdaButton
                  fullWidth
                  size={'l'} 
                  color={'primary'}
                  onClick={handleStart}
                  disabled={!(student.phone?.length > 11 && student.name?.length > 1 && student.email?.length > 4)}
                >
                  {t('profTest.startTest')}
                </CdaButton>
              </Grid>
              <Grid item xs={12} md={12}>
                <CdaTypography 
                  color={'white'}
                  type={'label'} 
                  label={t('profTest.footer')} 
                  style={{textAlign: 'center'}}
                  link
                />
              </Grid>
            </Grid>
          </Grid>
          :
          ((!!question || question === 0) ? 
            <Grid container 
              direction={'column'} 
              alignItems={'flex-start'} 
              justify={'flex-start'}
              style={{
                margin: CW > 850 ? 64 : '48px 8px 64px 8px', 
                overflowX: 'hidden',
                overflowY: 'hidden', 
                maxWidth: 1600,
                borderRadius: 32,
                background: secondaryB,
                padding: CW > 850 ? '32px 64px' : '16px 12px',
              }}
              spacing={4}
            >
              <Grid item>
                <CdaTypography
                  type={'label'}
                  color={'grey'}
                  label={(question + 1) + '/' + questions?.length}
                  style={{fontWeight: 600, marginBottom: 24}}
                />
                <CdaTypography
                  type={CW > 850 ? 'h3' : 'l'}
                  label={`${questions[question]?.name}`}
                  style={{fontWeight: 600}}
                />
              </Grid>
              <Grid item container 
                direction={'column'} 
                alignItems={'flex-start'} 
                justify={'flex-start'}
                spacing={1}
              >
                {!!questions[question]?.questions && 
                Object.keys(questions[question]?.questions)?.map(q => 
                  <Grid item>
                    <CdaRadio 
                      label={questions[question]?.questions?.[q]}
                      checked={answer === q}
                      onClick={() => setAnswer(q)}
                    />
                  </Grid>
                )}
              </Grid>
              <Grid item>
                <CdaButton
                  size={'l'} 
                  color={'primary'}
                  onClick={question + 1 !== questions?.length ? handleNext : handleFinish}
                  disabled={!answer}
                >
                  {question + 1 !== questions?.length ? t('profTest.next') : t('profTest.finish')}
                </CdaButton>
              </Grid>
            </Grid>
            :
            <Grid container 
              direction={'column'} 
              alignItems={'flex-start'} 
              justify={'flex-start'}
              style={{
                margin: CW > 850 ? 64 : '48px 8px 64px 8px', 
                overflowX: 'hidden',
                overflowY: 'hidden', 
                maxWidth: 1600,
                borderRadius: 32,
                background: secondaryB,
                padding: CW > 850 ? '32px 64px' : '16px 12px',
              }}
              spacing={4}
            >
              <Grid item>
                <CdaTypography
                  type={'h3'}
                  color={'classic'}
                  label={t('profTest.yourTurn')}
                  style={{fontWeight: 600}}
                />
              </Grid>
              <Grid item container 
                direction={'row'} 
                justify={'flex-start'}
                alignItems={'center'}
                spacing={2}
              >
                {!!courses?.length && courses?.map(c =>
                  <Grid item>
                    <CdaCardHome
                      elevation={0}
                      style={{boxShadow: '0px 0px 15px rgba(74, 85, 104, 0.1)'}}
                      onClick={() => history.push(`/profession/${c?.id}`)}
                      icon={!!c?.logo && 
                        <IconConnector 
                          icon={JSON.parse(c?.logo)?.icon} 
                          color={JSON.parse(c?.logo)?.color}
                        />
                      } 
                      color={!!c?.logo && JSON.parse(c?.logo)?.color} 
                      label={c?.name} 
                      info={!!c?.duration && (
                        (moment(c?.duration).from('1970-01-01').replace(/[^\d]/g, '') === '' ? '1 ' : '') +
                        (localStorage.lang !== 'kz' ?
                          moment(c?.duration).from('1970-01-01')
                            .replace('через ', '')
                            .replace('in ', '')
                            .replace('a ', '')
                          :
                          moment(c?.duration).from('1970-01-01')
                            .replace('через ', '')
                            .replace('in ', '')
                            .replace('года', 'жыл')
                            .replace('год', 'жыл')
                            .replace('лет', 'жыл')
                            .replace('месяцев', 'ай')
                            .replace('месяца', 'ай')
                            .replace('месяц', 'ай')
                            .replace('a year', 'жыл')
                            .replace('years', 'жыл')
                            .replace('a month', 'ай')
                            .replace('months', 'ай')
                        )
                      )}
                    />
                  </Grid>
                )}
              </Grid>
              <Grid item>
                <CdaTypography
                  color={'classic'}
                  type={'bm'}
                  label={t('profTest.thanks')}
                />
                <CdaTypography
                  color={'classic'}
                  type={'bm'}
                  label={t('profTest.contact')}
                />
              </Grid>
              <Grid item>
                <CdaButton
                  size={'l'} 
                  color={'primary'}
                  onClick={() => history.push('/')}
                >
                  {t('profTest.backHome')}
                </CdaButton>
              </Grid>
            </Grid>
          )
        }
      </Grid>
      <Footer CW={CW} />
    </Grid>
  )
}

export default ProfTestPage;
