import React from 'react'
import { Button, makeStyles, withStyles } from '@material-ui/core'
import {
  negativeB,
  positiveB,
  primaryC,
  secondaryB,
  greyB,
  greyC,
  cloudB,
  positiveC,
  negativeC,
  defaultB,
  primaryD,
  primaryB,
  darkB
} from '../constants/colors'
import PropTypes from 'prop-types'
import '../fonts/style.css'

const styledBy = (property, mapping) => props => mapping[props[property]]

const styles = {
  root: {
    fontFamily: 'GT Eesti Pro Display',
    transition: 'none',
    textTransform: 'none',
    minWidth: '32px',
    border: styledBy('active', {
			[false]: '1px solid transparent',
			[true]: '1px solid ' + primaryC,
			[undefined]: '1px solid transparent'
		}),
    padding: styledBy('size', {
			s: '8px 16px',
			l: '8px 16px',
			[undefined]: '8px 16px'
		}),
    height: styledBy('size', {
			s: '32px',
			l: '42px',
			[undefined]: '32px'
		}),
    borderRadius: styledBy('color', {
			primary: '8px',
			secondary: '8px',
			tertiary: '8px',
			quaternary: '8px',
			positive: '8px',
			negative: '8px',
      negativeLight: '8px',
      grey: '8px',
			[undefined]: '8px'
		}),
    fontWeight: styledBy('size', {
			s: 500,
			l: 400,
			[undefined]: 500
		}),
    fontSize: styledBy('size', {
			s: '12px',
			l: '16px',
			[undefined]: '12px'
		}),
    lineHeight: styledBy('size', {
			s: '14px',
			l: '18px',
			[undefined]: '14px'
		}),
    backgroundColor: styledBy('color', {
			primary: primaryC,
			secondary: secondaryB,
			tertiary: 'transparent',
			quaternary: secondaryB,
			positive: positiveB,
			negative: negativeB,
      negativeLight: negativeC,
      grey: greyB,
			[undefined]: primaryC
		}),
    color: styledBy('color', {
			primary: defaultB,
			secondary: primaryC,
			tertiary: primaryC,
			quaternary: primaryC,
			positive: positiveC,
			negative: negativeC,
      negativeLight: negativeB,
      grey: darkB,
			[undefined]: defaultB
		}),
    '&:hover': {
      backgroundColor: styledBy('color', {
        primary: primaryD,
        secondary: secondaryB,
        tertiary: secondaryB,
        quaternary: primaryC,
        positive: positiveC,
        negative: negativeC,
        negativeLight: negativeB,
        grey: greyC,
        [undefined]: primaryD
      }),
      color: styledBy('color', {
        primary: defaultB,
        secondary: primaryB,
        tertiary: primaryC,
        quaternary: defaultB,
        positive: positiveB,
        negative: negativeB,
        negativeLight: negativeC,
        grey: darkB,
        [undefined]: defaultB
      }),
      border: styledBy('active', {
        [false]: '1px solid transparent',
        [true]: '1px solid ' + primaryC,
        [undefined]: '1px solid transparent'
      }),
    },
  },
  disabled: {
    fontFamily: 'GT Eesti Pro Display',
    transition: 'none',
    textTransform: 'none',
    border: '1px solid transparent',
    minWidth: '32px',
    padding: styledBy('size', {
			s: '8px 16px',
			l: '8px 16px',
			[undefined]: '8px 16px'
		}),
    height: styledBy('size', {
			s: '32px',
			l: '42px',
			[undefined]: '32px'
		}),
    borderRadius: styledBy('color', {
			primary: '8px',
			secondary: '8px',
			tertiary: '8px',
			quaternary: '8px',
			positive: '8px',
			negative: '8px',
      negativeLight: '8px',
      grey: '8px',
			[undefined]: '8px'
		}),
    fontWeight: styledBy('size', {
			s: 500,
			l: 400,
			[undefined]: 500
		}),
    fontSize: styledBy('size', {
			s: '12px',
			l: '16px',
			[undefined]: '12px'
		}),
    lineHeight: styledBy('size', {
			s: '14px',
			l: '19px',
			[undefined]: '14px'
		}),backgroundColor: styledBy('color', {
      primary: greyC,
      secondary: greyC,
      tertiary: 'transparent',
      quaternary: 'transparent',
      positive: greyC,
      negative: greyC,
      negativeLight: greyC,
      grey: greyC,
      [undefined]: greyC
    }),
    color: greyB,
  }
}

const CdaButton = withStyles(styles)(({classes, disabled, active, ...other }) => (
    <Button
      className={!!disabled ? classes.disabled : classes.root}
      disabled={!!disabled}
      {...other}
    />
  ))

export default CdaButton

CdaButton.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'tertiary',
    'quaternary',
    'positive',
    'negative',
    'negativeLight',
    'grey'
  ]),
  size: PropTypes.oneOf([
    'l',
    's'
  ]),
  active: PropTypes.oneOf([
    true,
    false
  ]),
  disabled: PropTypes.bool,
  onClick: PropTypes.func
}
