import React from 'react';
import ReactDOM from 'react-dom';
import { history } from './lib/history'
import './index.css';
import { SnackbarProvider } from 'notistack'
import reportWebVitals from './reportWebVitals';
import App from './App.js'
import './i18n.js';
import { Route, Router, Switch } from 'react-router-dom';

const rootElement = document.getElementById('root')
ReactDOM.render(
  <SnackbarProvider
    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    autoHideDuration={4000}
  >
    <Router history={history}>
      <Switch>
        <Route path='/' component={App} />
      </Switch>
    </Router>
  </SnackbarProvider>,
  rootElement
)

reportWebVitals();