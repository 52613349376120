import React from 'react'

const IconCv = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.182 16.318C21.9393 18.0754 21.9393 20.9246 20.182 22.682C18.4246 24.4393 15.5754 24.4393 13.818 22.682C12.0607 20.9246 12.0607 18.0754 13.818 16.318C15.5754 14.5607 18.4246 14.5607 20.182 16.318" stroke="#ddb6e3" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4 37V11.082C4 8.828 5.828 7 8.082 7H40C42.21 7 44 8.79 44 11V37C44 39.21 42.21 41 40 41H8C5.79 41 4 39.21 4 37Z" stroke="#ddb6e3" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M30 19H38" stroke="#ddb6e3" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M34.8 27H30" stroke="#ddb6e3" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M24.1905 33C23.8405 32.122 23.3005 31.334 22.6065 30.692V30.692C21.4325 29.604 19.8925 29 18.2925 29H15.7085C14.1085 29 12.5685 29.604 11.3945 30.692V30.692C10.7005 31.334 10.1605 32.122 9.81055 33" stroke="#ddb6e3" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
);

export default IconCv;
