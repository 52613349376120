import React from 'react'
import { 
	darkB,
	negativeB,
	positiveB,
	warningB,
	defaultB,
	primaryB,
	cloudB,
  greyB,
  purpleB
} from "../cda-UIkit/constants/colors"
import InvisableIcon from '../img/icons/InvisableIcon';
import VisableIcon from '../img/icons/VisableIcon';
import AnalitycsIcon from '../img/icons/AnalitycsIcon';
import SearchIcon from '../img/icons/SearchIcon';
import DatabasesIcon from '../img/icons/DatabasesIcon';
import ProductIcon from '../img/icons/ProductIcon';
import MachineLearningIcon from '../img/icons/MachineLearningIcon';
import DataScienceIcon from '../img/icons/DataScienceIcon';
import EtlDeveloperIcon from '../img/icons/EtlDeveloperIcon';
import ComputerVisionIcon from '../img/icons/ComputerVisionIcon';

const colorList = {
  classic: darkB,
  primary: primaryB,
  positive: positiveB,
  negative: negativeB,
  warning: warningB,
  grey: greyB,
  cloud: cloudB,
  white: defaultB,
  purple: purpleB
}

const IconConnector = ({size = '24', color = 'classic', icon, style}) => {

  const TakeIcon = () => ({
    VisableIcon: <VisableIcon color={colorList[color]} size={size} style={style} />,
    InvisableIcon: <InvisableIcon  color={colorList[color]} size={size} style={style} />,
    AnalitycsIcon: <AnalitycsIcon color={colorList[color]} size={size} style={style} />,
    SearchIcon: <SearchIcon color={colorList[color]} size={size} style={style} />,
    DatabasesIcon: <DatabasesIcon color={colorList[color]} size={size} style={style} />,
    ProductIcon: <ProductIcon color={colorList[color]} size={size} style={style} />,
    MachineLearningIcon: <MachineLearningIcon color={colorList[color]} size={size} style={style} />,
    DataScienceIcon: <DataScienceIcon color={colorList[color]} size={size} style={style} />,
    EtlDeveloperIcon: <EtlDeveloperIcon color={colorList[color]} size={size} style={style} />,
    ComputerVisionIcon: <ComputerVisionIcon color={colorList[color]} size={size} style={style} />
  }[icon])

  return TakeIcon() || <></>;
}

export default IconConnector;
