import React from 'react'

const IconQuality = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.4484 30.9917H15.6844C15.0664 30.9917 14.5664 30.4917 14.5664 29.8737V21.1097C14.5664 20.4917 15.0664 19.9917 15.6844 19.9917H18.4484C19.0664 19.9917 19.5664 20.4917 19.5664 21.1937V29.9577C19.5664 30.4897 19.0664 30.9917 18.4484 30.9917Z" stroke="#F1C40E" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M19.5665 28.6219L21.7305 30.4159C22.1785 30.7879 22.7445 30.9919 23.3265 30.9919H30.4925C31.3905 30.9919 32.1805 30.3959 32.4285 29.5319L33.8925 24.4079C34.1145 23.6339 33.9845 22.7999 33.5385 22.1299C33.0345 21.3719 32.1885 20.9179 31.2825 20.9179H30.0425H27.9525L27.9625 17.5139C27.9705 14.9839 24.6485 14.0439 23.3285 16.2019L19.5645 22.3639" stroke="#F1C40E" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M25.213 6.54192L28.113 5.12392C29.451 4.46992 31.069 4.99392 31.767 6.31192L33.281 9.16392C33.681 9.91592 34.405 10.4419 35.245 10.5899L38.425 11.1479C39.893 11.4059 40.891 12.7799 40.683 14.2559L40.231 17.4519C40.111 18.2959 40.389 19.1479 40.981 19.7599L43.225 22.0799C44.261 23.1499 44.261 24.8499 43.225 25.9219L40.981 28.2419C40.389 28.8539 40.111 29.7059 40.231 30.5499L40.683 33.7459C40.891 35.2219 39.893 36.5959 38.425 36.8539L35.245 37.4119C34.405 37.5599 33.681 38.0859 33.281 38.8379L31.767 41.6899C31.069 43.0059 29.453 43.5319 28.113 42.8779L25.213 41.4599C24.447 41.0859 23.553 41.0859 22.787 41.4599L19.887 42.8779C18.549 43.5319 16.931 43.0079 16.233 41.6899L14.719 38.8359C14.319 38.0839 13.595 37.5579 12.755 37.4099L9.57505 36.8519C8.10705 36.5939 7.10905 35.2199 7.31705 33.7439L7.76905 30.5479C7.88905 29.7039 7.61105 28.8519 7.01905 28.2399L4.77505 25.9199C3.73905 24.8499 3.73905 23.1499 4.77505 22.0779L7.01905 19.7579C7.61105 19.1459 7.88905 18.2939 7.76905 17.4499L7.31905 14.2539C7.10905 12.7799 8.10905 11.4039 9.57705 11.1479L12.757 10.5899C13.595 10.4419 14.319 9.91592 14.719 9.16392L16.233 6.31192C16.931 4.99592 18.547 4.46992 19.887 5.12392L22.787 6.54192C23.551 6.91592 24.447 6.91592 25.213 6.54192Z" stroke="#F1C40E" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
);

export default IconQuality;
