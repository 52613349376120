import React, {useEffect, useState} from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import CdaTextField from '../cda-UIkit/Inputs/CdaTextField';
import '../App.css';
import '../cda-UIkit/fonts/style.css';
import CdaButton from '../cda-UIkit/Buttons/CdaButton';
import DialogCda from '../cda-UIkit/Dialog/DialogCda';
import CdaTypography from '../cda-UIkit/Typography/CdaTypography';
import { repareCode, repareEmail, reparePassword } from '../requests/auth';
import VisableIcon from '../img/icons/VisableIcon';
import InvisableIcon from '../img/icons/InvisableIcon';

const FogotModal = ({ open, handleClose }) => {
  const { t } = useTranslation()
  const [visable, setViasble] = useState(false)
  const [visableRepeat, setViasbleRepeat] = useState(false)
  const [form, setForm] = useState({})
  const [noEmail, setNoEmail] = useState(false)
  const [noPassword, setNoPassword] = useState(false)
  const [noRepeat, setNoRepeat] = useState(false)
  const [error, setError] = useState(false)
  const [status, setStatus] = useState(0)
  const [loading, setLoading] = useState(false)
  const [count, setCount] = useState(0)

  const clearErrors = () => {
    setError(false)
    setNoEmail(false)
    setNoRepeat(false)
    setNoPassword(false)
  }

  const sendEmail = () => {
    if (
      !form?.email || 
      ((!form?.email?.includes('@') || 
      !form?.email?.includes('.')) && 
      form?.email?.length > 5)
    ) {
      setNoEmail(true)
      setLoading(false)
      return null;
    }
    repareEmail({email: form?.email}).then((res) => {
      if (res?.status === 200) {
        clearErrors()
        setStatus(1)
        setLoading(false)
        return;
      }
      if (res?.status === 203) {
        setStatus(4)
        setLoading(false)
        return;
      }
      setError(true)
      setLoading(false)
    }).catch((e) => {
      console.log(e)
      setError(true)
      setLoading(false)
    });
  }

  const sendCode = () => {
    if (form?.code?.length !== 6) {
      setError(true)
      setCount(count + 1)
      if (count > 2) {
        setStatus(4)
      }
      setLoading(false)
      return null;
    }
    repareCode({email: form?.email, code: form?.code}).then((res) => {
      if (res?.status === 200) {
        clearErrors()
        setStatus(2)
        setLoading(false)
        setForm(prevState => ({...prevState, token: res.data}))
        return;
      }
      if (res?.status === 203) {
        setStatus(4)
        setLoading(false)
        return;
      }
      setError(true)
      setCount(count + 1)
      if (count > 2) {
        setStatus(4)
      }
      setLoading(false)
    }).catch((e) => {
      console.log(e)
      setError(true)
      setCount(count + 1)
      if (count > 2) {
        setStatus(4)
      }
      setLoading(false)
    });
  }

  const sendPassword = () => {
    if (form?.password?.length < 8 || 
      (form?.password === form?.password?.toLowerCase()) ||
      !/[0-9]/.test(form?.password?.length)
    ) {
      setNoPassword(true)
      setLoading(false)
      return null;
    }
    if (form?.password !== form?.repeat) {
      setNoRepeat(true)
      setLoading(false)
      return null;
    }
    reparePassword({token: form?.token, data: form?.password}).then((res) => {
      if (res?.status === 200) {
        clearErrors()
        setStatus(3)
        setLoading(false)
        return;
      }
      if (res?.status === 203) {
        setStatus(4)
        setLoading(false)
        return;
      }
      setError(true)
      setLoading(false)
    }).catch((e) => {
      console.log(e)
      setStatus(4)
      setLoading(false)
    });
  }

  const handleButton = () => {
    setLoading(true)
    if (status === 0) {
      return sendEmail()
    }
    if (status === 1) {
      return sendCode()
    }
    if (status === 2) {
      return sendPassword()
    }
    return handleClose()
  }

  return (
    <DialogCda
      maxWidth={'xs'}
      open={open}
      onClose={handleClose}
      close={handleClose}
      title={t('signModal.fogotTitle')}
      content={
      <Grid container direction={'row'} alignItems={'center'} justify={'center'} spacing={2}>
        {status === 0 &&
          <>
            <Grid item xs={12} md={12} style={{marginBottom: 12}}>
              <CdaTypography type={'bm'} color={'classic'} label={t('signModal.sendEmailText')} />
            </Grid>
            <Grid item xs={12} md={12}>
              <CdaTextField 
                fullWidth 
                error={!!error || !!noEmail}
                onChange={(e) => {setForm(prevState => ({...prevState, email: e.target.value})); clearErrors()}}
                value={form.email || ''}
                label={t('signModal.enterEmail')} 
                labelTop={'Email'} 
                labelBottom={
                  !!noEmail ? 
                    t('signModal.emailValid') : 
                    (!!error || !!noEmail? t('signModal.errorEmail') : null)
                }
                bottomColor={'negative'}
              />
            </Grid>
          </>
        }        
        {status === 1 &&
          <>
            <Grid item xs={12} md={12} style={{marginBottom: 12}}>
              <CdaTypography type={'bm'} color={'classic'} 
                label={t('signModal.sendedCode') + ' ' + form?.email?.[0] + '***@' + form?.email?.split('@')[1]} 
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <CdaTextField 
                fullWidth 
                error={!!error}
                onChange={(e) => {setForm(prevState => ({...prevState, code: e.target.value})); clearErrors()}}
                value={form.code || ''}
                label={t('signModal.writeCode')} 
                labelTop={t('signModal.code')} 
                labelBottom={
                  !!error ? t('signModal.codeError') : null
                }
                bottomColor={'negative'}
              />
            </Grid>
          </>
        }        
        {status === 2 &&
          <>
            <Grid item xs={12} md={12} style={{marginBottom: 12}}>
              <CdaTypography type={'bm'} color={'classic'} 
                label={t('signModal.newPassword')} 
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <CdaTextField 
                fullWidth 
                type={!visable && "password"}
                error={!!error || !!noPassword || !!noRepeat}
                onChange={(e) => {setForm(prevState => ({...prevState, password: e.target.value})); clearErrors()}}
                value={form.password || ''}
                label={t('signModal.enterPassword')} 
                labelTop={t('signModal.password')} 
                labelBottom={
                  !!error ? t('signModal.newError') : (!!noPassword ? t('signModal.passwordError') : (!!noRepeat ? t('signModal.repeatError') : null))
                }
                bottomColor={'negative'}
                icon={!!visable ?
                  <VisableIcon /> :
                  <InvisableIcon />
                }
                iconAction={() => setViasble(!visable)}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <CdaTextField 
                fullWidth 
                type={!visableRepeat && "password"}
                error={!!error || !!noPassword || !!noRepeat}
                onChange={(e) => {setForm(prevState => ({...prevState, repeat: e.target.value})); clearErrors()}}
                value={form.repeat || ''}
                label={t('signModal.repeatPassword')} 
                labelTop={t('signModal.repeatPassword')} 
                labelBottom={
                  !!error ? t('signModal.newError') : (!!noPassword ? t('signModal.passwordError') : (!!noRepeat ? t('signModal.repeatError') : null))
                }
                bottomColor={'negative'}
                icon={!!visableRepeat ?
                  <VisableIcon /> :
                  <InvisableIcon />
                }
                iconAction={() => setViasbleRepeat(!visableRepeat)}
              />
            </Grid>
          </>
        }
        {status === 3 &&
          <Grid item xs={12} md={12} style={{marginBottom: 12}}>
            <CdaTypography type={'bm'} color={'classic'} 
              label={t('signModal.passwordSuccess')} 
            />
          </Grid>
        }
        {status === 4 &&
          <Grid item xs={12} md={12} style={{marginBottom: 12}}>
            <CdaTypography type={'bm'} color={'classic'} 
              label={t('signModal.tooManyTry')} 
            />
          </Grid>
        }
      </Grid>
      }
      actions={
      <>
        <CdaButton fullWidth color={'primary'} size={'l'} onClick={handleButton} disabled={!!loading} >
          {[3,4].includes(status) ? t('close') : t('signModal.send')}
        </CdaButton>
      </>
      }
    />
  );
}

export default FogotModal;