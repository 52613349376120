import React, {useEffect, useState} from 'react'
import { Grid } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import CdaTypography from '../cda-UIkit/Typography/CdaTypography';
import moment from 'moment/moment';
import CdaCourseCard from '../cda-UIkit/Cards/CdaCourseCard';
import IconConnector from '../components/IconConnector';
import CdaProfesiesCard from '../cda-UIkit/Cards/CdaProfesiesCard';
import { history } from '../lib/history';
import { getPersonalSpace } from '../requests/students';
import { jsonToObject } from '../lib/helpers';

const PersonalSpace = ({ CW }) => {
  const { t, i18n } = useTranslation()
  const [profsFull, setProfsFull] = useState([])

  useEffect(() => {
    if (!!localStorage.getItem('token')) {
      getPersonalSpace().then(res => {
        setProfsFull(res)
      })
    } else {
      history.push('/')
    }
  }, [])

  return (
    <Grid container 
      direction={'row'} 
      alignItems={'flex-start'} 
      justify={'center'}
      style={{padding: CW > 1070 ? '64px' : '56px 8px', overflowX: 'hidden', overflowY: 'hidden'}}
      spacing={6}
    >
      <Grid item xs={12} md={6}
        container
        direction={'column'}
        alignItems={'flex-start'} 
        justify={'flex-start'}
        spacing={2}
      >
        <Grid item>
          <CdaTypography type={'h2'} 
            color={'classic'} 
            label={t('PersonalSpace.hello')} 
            style={{fontWeight: 600}} 
          />
        </Grid>
        <Grid item style={{marginBottom: 64}}>
          <CdaTypography type={'l'} 
            color={'classic'} 
            label={
              moment().hours() >= 8 && moment().hours() <= 20 ? 
                t('PersonalSpace.goodDay') : t('PersonalSpace.goodNight')
            }
          />
        </Grid>
        {profsFull?.map(c => 
          <Grid item style={{width: '100%'}}>
            <CdaProfesiesCard
              clicable
              elevation={0}
              onClick={() => history.push(`/profession/${c?.id}`)}
              icon={<IconConnector icon={!!c?.logo ? jsonToObject(c?.logo)?.icon : ''} color={!!c?.logo ? jsonToObject(c?.logo)?.color : ''} />}
              color={!!c?.logo ? jsonToObject(c?.logo)?.color : ''} 
              label={c?.name} 
              info={{v: {count: Number(c?.videos_count), total: Number(c?.videos_total)}, t: {count: Number(c?.tasks_count), total: Number(c?.tasks_total)}}}
            />
          </Grid>
        )}
      </Grid>
      <Grid item xs={12} md={6}
        container
        direction={'column'}
        alignItems={'flex-start'} 
        justify={'flex-start'}
        spacing={2}
      >
        <Grid item xs={12} md={12}>
          <CdaTypography type={'l'} 
            color={'classic'} 
            label={t('PersonalSpace.courses')} 
            style={{marginBottom: profsFull?.length <= 1 ? '24px' : '12px'}}
          />
        </Grid>
        {profsFull?.map(p => 
          <Grid item container 
            xs={12} md={12}
            direction={'row'}
            alignItems={'flex-start'}
            justify={ CW > 1070 ? 'flex-start' : 'center'}
            spacing={2}
            style={{marginBottom: '12px'}}
          >
            {profsFull?.length > 1 &&
              <Grid item xs={12} md={12}>
                <CdaTypography type={'labelUp'} 
                  color={'grey'} 
                  label={p.name} 
                  style={{marginBottom: '4px'}}
                />
              </Grid>
            }
            {p?.courses?.map(c => 
              <Grid item>
                <CdaCourseCard
                  clicable
                  elevation={2}
                  onClick={() => history.push(`/course/${c?.id}`)}
                  icon={<IconConnector icon={!!c?.logo ? jsonToObject(c?.logo)?.icon : ''} color={!!c?.logo ? jsonToObject(c?.logo)?.color : ''} />}
                  color={!!c?.logo ? jsonToObject(c?.logo)?.color : ''} 
                  label={c?.name} 
                  info={{v: {count: Number(c?.videos_count), total: Number(c?.videos_total)}, t: {count: Number(c?.tasks_count), total: Number(c?.tasks_total)}}}
                />
              </Grid>
            )}
          </Grid>
      )}
      </Grid>
    </Grid>
  );
}

export default PersonalSpace;
