import React from 'react'
import InputMask from 'react-input-mask'
import PropTypes from 'prop-types'
import CdaTextField from './CdaTextField'

const MaskedTextInput = ({
	className,
	mask,
	label,
	labelTop,
	labelBottom,
	bottomColor,
	topColor,
	error = false,
	value,
	onChange,
	name,
	type = 'text',
	styles,
	helperText,
	disabled = false,
	variant = 'filled',
	fullWidth
}) => {
	return (
		<InputMask
			mask={mask}
			name={name}
			maskChar={''}
			value={value}
			onChange={onChange}
			disabled={disabled}
		>
			{inputProps => (
				<CdaTextField
					{...inputProps}
					className={className}
					disabled={disabled}
					fullWidth={fullWidth}
					type={type}
					style={styles}
					error={Boolean(error)}
					helperText={helperText}
					label={label}
					labelTop={labelTop}
					labelBottom={labelBottom}
					bottomColor={bottomColor}
					topColor={topColor}
					variant={variant}
				/>
			)}
		</InputMask>
	)
}

export default MaskedTextInput

MaskedTextInput.propTypes = {
	mask: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	label: PropTypes.string,
	labelTop: PropTypes.string,
	topColor: PropTypes.string,
	helperText: PropTypes.string,
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
	type: PropTypes.oneOf(['text', 'tel']),
	variant: PropTypes.oneOf(['outlined', 'contained', 'default']),
	fullWidth: PropTypes.bool
}
