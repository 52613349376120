import React from 'react'
import { Card, CardContent, Grid, withStyles } from '@material-ui/core';
import CdaTypography from '../Typography/CdaTypography';
import { defaultB } from '../constants/colors';

const styles = {
  root: {
    background: defaultB,
    padding: '0px',
    borderRadius: '12px',
    width: '100%',
  },
  content: {
    padding: '16px',
  }
}

const CdaBetterCard = withStyles(styles)(({ classes, icon, title, text, img, elevation = 0 }) =>
    <Card
      elevation={elevation}
      className={classes.root}
    >
      <CardContent className={classes.content}>
        <Grid container
          direction={'column'}
          alignItems={'flex-start'}
          justify={'center'}
          spacing={2}
          style={{minHeight: 160}}
        >
          <Grid item>
            {!!img ? <img src={icon} height={64}/> : icon}
          </Grid>
          <Grid item>
            <CdaTypography type={'l'} color={'classic'} 
              label={title}
              style={{fontWeight: 600}}
            />
          </Grid>
          <Grid item>
            <CdaTypography type={'bm'} color={'classic'} label={text} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
)

export default CdaBetterCard;