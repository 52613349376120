const isDevelop =
	window.location.hostname.includes('test') ||
	window.location.hostname.includes('192.168') ||
	window.location.hostname.includes('localhost')

export const AUTH_API_URL = isDevelop
	? 'http://localhost:5000/api-auth'
	: 'https://academy.cda.kz/api-auth';

export const COURSE_API_URL = isDevelop
  ? 'http://localhost:5000/api-course'
  : 'https://academy.cda.kz/api-course';

export const STUDENT_API_URL = isDevelop
? 'http://localhost:5000/api-student'
: 'https://academy.cda.kz/api-student';