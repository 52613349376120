import React from 'react'
import { Avatar, Card, CardActionArea, CardContent, Grid, withStyles } from '@material-ui/core'
import {
	defaultB
} from '../constants/colors'
import PropTypes from 'prop-types'
import '../fonts/style.css'
import CdaTypography from '../Typography/CdaTypography'
import { useTranslation } from 'react-i18next'
import RectangleLeft from '../../img/RectangleLeft'
import RectangleRight from '../../img/RectangleRight'

const styles = {
	root: {
		background: defaultB,
    padding: '0px',
		borderRadius: '16px',
    borderBottomLeftRadius: '0px',
    height: '480px',
    width: '320px',
    border: 'none',
    position: 'relative'
	},
  content: {
    padding: '0px',
  },
  middle: {
    height: '160px',
    width: '320px',
    borderRadius: '24px',
    marginBottom: '6px'
  }
}

const CdaVideoCard = withStyles(styles)(({ classes, icon, label, description, i, cardLine, l, ...other }) => {
  const { t, i18n } = useTranslation()

  const getColor = {
    0: '#FAE6E1',
    1: '#E1F8FA',
    3: '#F6E7FA',
    2: '#E2E8FE',
  }

  const getOrient = () => {
    if (cardLine === 4 && l >= 4) {
      return {
        0: <RectangleLeft color={getColor[i]} />,
        1: <p className={classes.middle} style={{backgroundColor: getColor[i]}} />,
        2: <p className={classes.middle} style={{backgroundColor: getColor[i]}} />,
        3: <RectangleRight color={getColor[i]} />
      }[i]
    }
    if (cardLine === 3 || (cardLine >= 3 && l >= 3)) {
      return {
        0: <RectangleLeft color={getColor[i]} />,
        1: <p className={classes.middle} style={{backgroundColor: getColor[i]}} />,
        2: <RectangleRight color={getColor[i]} />
      }[i]
    }
    if (cardLine === 2 || (cardLine >= 2 && l >= 2)) {
      return {
        0: <RectangleLeft color={getColor[i]} />,
        1: <RectangleRight color={getColor[i]} />
      }[i]
    }
    return <p className={classes.middle} style={{backgroundColor: getColor[i]}} />;
  }

  return(
		<Card
      elevation={0}
      clicable
			className={classes.root} 
			{...other} 
    >
      <CardContent className={classes.content}>
        <Avatar 
          style={{
            width: '320px', 
            height: '400px', 
            pointerEvents: 'none', 
            borderRadius: 16,
            position: 'absolute',
            zIndex: 1,
            top: 0,
            left: 0
          }}
          alt="" src={icon} 
          disabled
        />
        <Grid container 
          direction={'column'} 
          justify={'flex-end'} 
          alignItems={'flex-start'}
          style={{bottom: 0, position: 'absolute', zIndex: 0}}
        >
          <Grid item>
            {getOrient()}
          </Grid>
          <Grid item container 
            direction={'row'} 
            justify={'flex-start'} 
            alignItems={'flex-start'}
            style={{height: '80px'}}
          >
            <Grid item xs={12} md={12}>
              <CdaTypography type={'bm'} label={label} align={'left'} style={{marginLeft: '12px', marginTop: '8px'}} />
            </Grid>
            <Grid item xs={12} md={12}>
              <CdaTypography type={'label'} label={description} color={'grey'} align={'left'} style={{marginLeft: '12px', marginTop: -8}} />
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
})

export default CdaVideoCard;

CdaVideoCard.propTypes = {
  icon: PropTypes.node,
  label: PropTypes.string,
  description: PropTypes.string,
  i: PropTypes.number,
  l: PropTypes.number
}
