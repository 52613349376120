import React from 'react'
import { useTranslation } from "react-i18next";
import { Card, CardActionArea, CardContent, Grid, withStyles } from '@material-ui/core'
import {
	darkB,
	negativeB,
	positiveB,
	primaryC,
	warningB,
	secondaryB,
	negativeC,
	positiveC,
	warningC,
	defaultB,
	primaryB,
	cloudB,
  greyB,
  greyC,
  primaryD,
  purpleC,
} from '../constants/colors'
import PropTypes from 'prop-types'
import '../fonts/style.css'
import CdaTypography from '../Typography/CdaTypography'
import { thousandSeparator } from '../../lib/helpers'
import CdaButton from '../Buttons/CdaButton';

const styledBy = (property, mapping) => props => mapping[props[property]]

const styles = {
	root: {
		background: defaultB,
    padding: '0px',
		borderRadius: '8px',
    border: 'none',
    width: '310px'
	},
  content: {
    padding: '16px',
    paddingBottom: '16px !important'
  },
  icon: {
    padding: '8px',
    height: '64px',
    width: '64px',
		borderRadius: '8px',
    marginRight: '12px',
    background: styledBy('color', {
			classic: cloudB,
			primary: secondaryB,
			secondary: secondaryB,
			negative: negativeC,
			positive: positiveC,
			warning: warningC,
      purple: purpleC
		})
  }
}

const CdaCardHome = withStyles(styles)(({ classes, color, icon, label, info, price, profsName, CW, ...other }) => {
  const { t, i18n } = useTranslation()

  const longCard = () => 
    <Grid container direction={'row'} justify={'space-between'} alignItems={'flex-start'}>
      <Grid item xs={6} md={6} container direction={'row'} justify={'flex-start'} alignItems={'flex-start'} spacing={3}
        style={{height: 120, position: 'relative'}}
      >
        {(!!profsName) &&
          <Grid item container direction={'row'} alignItems={'flex-start'} justify={'space-between'} xs={12} md={12}>            
            {!!profsName?.length && profsName.map(e => 
              <Grid item>
                <CdaTypography type={'bm'} label={e} color={'grey'} />
              </Grid>
            )}
          </Grid>
        }
        {!!label &&
          <Grid item xs={12} md={12}>
            <CdaTypography type={'l'} label={label}/>
          </Grid>
        }
        {!!price &&
          <Grid item xs={12} md={12}>
            <CdaTypography type={'bm'} label={thousandSeparator(price.toString()) + ' ₸'}/>
          </Grid>
        }
        {!!info &&
          <Grid xs={12} md={12} style={{position: 'absolute', bottom: 0, left: 12}}>
            <CdaTypography type={'label'} color={'grey'} label={info} />
          </Grid>
        }
      </Grid>
      <Grid item>
        {!!icon &&
          <Grid item container justify={'center'} alignItems={'center'} className={classes.icon}
            style={{
              padding: '8px',
              height: '104px',
              width: '104px',
            }}
          >
            {icon}
          </Grid>
        }  
      </Grid>
    </Grid>

  const shortCard = () => 
    <Grid container direction={'row'} justify={'flex-start'} alignItems={'flex-start'} spacing={3}>
      {(!!icon || !!profsName) &&
        <Grid item container direction={'row'} alignItems={'flex-start'} justify={'space-between'} xs={12} md={12}>
          {!!icon &&
            <Grid item container justify={'center'} alignItems={'center'} className={classes.icon}>
              {icon}
            </Grid>
          }          
          {!!profsName?.length &&       
            <Grid item>
              {profsName?.map(e => 
                <CdaTypography type={'bm'} label={e} color={'grey'} />
              )}
            </Grid>
          }
        </Grid>
      }
      {!!label &&
        <Grid item xs={12} md={12}>
          <CdaTypography type={'l'} label={label}/>
        </Grid>
      }
      {!!price &&
        <Grid item xs={12} md={12}>
          <CdaTypography type={'bm'} label={thousandSeparator(price.toString()) + ' ₸'}/>
        </Grid>
      }
      {!!info &&
        <Grid item xs={12} md={12}>
          <CdaTypography type={'label'} color={'grey'} label={info} />
        </Grid>
      }
    </Grid>

  return (
		<Card 
      clicable
			className={classes.root} 
			{...other} 
    >
      <CardActionArea>
        <CardContent className={classes.content}>
          {!!CW ? 
            (CW > 1160 ? longCard() : shortCard()) :
            shortCard()
          }
        </CardContent>
      </CardActionArea>
    </Card>
)})

export default CdaCardHome

CdaCardHome.propTypes = {
	color: PropTypes.oneOf([
		'classic',
		'primary',
		'secondary',
		'negative',
		'positive',
		'warning',
    'purple'
	]),
  icon: PropTypes.node,
	label: PropTypes.string,
  info: PropTypes.string,
  price: PropTypes.number,
  profsName: PropTypes.string
}
