import axios from "axios";
import { STUDENT_API_URL } from "../lib/env";

/**
 * @async axios
 * @function getCareerTest(data)
 * @return  {Object} {[{...}, {...}, ...]}
 * @example getCareerTest(data)
 *
 */ 
 export const getCareerTest = async (data) => {
	try {
		const res = await axios.post(STUDENT_API_URL + '/getCareerTest', data,
		{
      headers: {
        lang: localStorage.getItem('lang')
      }
    })
		return res.data;
	} catch (e) {
		return e;
	}
}

/**
 * @async axios
 * @function careerTestAnswer(data)
 * @return  {Object} {[{...}, {...}, ...]}
 * @example careerTestAnswer(data)
 *
 */ 
 export const careerTestAnswer = async (data) => {
	try {
		const res = await axios.post(STUDENT_API_URL + '/careerTestAnswer', data,
		{
      headers: {
        lang: localStorage.getItem('lang')
      }
    })
		return res.data;
	} catch (e) {
		return e;
	}
}

/**
 * @async axios
 * @function careerTestFinish(data)
 * @return  {Object} {[{...}, {...}, ...]}
 * @example careerTestFinish(data)
 *
 */ 
 export const careerTestFinish = async (data) => {
	try {
		const res = await axios.post(STUDENT_API_URL + '/careerTestFinish', data,
		{
      headers: {
        lang: localStorage.getItem('lang')
      }
    })
		return res.data;
	} catch (e) {
		return e;
	}
}

/**
 * @async axios
 * @function getReviewes(data)
 * @return  {Object} {[{...}, {...}, ...]}
 * @example getReviewes(data)
 *
 */ 
export const getReviewes = async (data) => {
	try {
		const res = await axios.get(STUDENT_API_URL + `/getReviewes/${!data ? 0 : data}`,
		{
      headers: {
        lang: localStorage.getItem('lang')
      }
    })
		return res.data;
	} catch (e) {
		return e;
	}
}

/**
 * @async axios
 * @function getStudentsForCv()
 * @return  {Object} {[{...}, {...}, ...]}
 * @example getStudentsForCv()
 *
 */ 
export const getStudentsForCv = async () => {
	try {
		const res = await axios.get(STUDENT_API_URL + '/getStudentsForCv',
		{
      headers: {
        lang: localStorage.getItem('lang')
      }
    })
		return res.data;
	} catch (e) {
		return e;
	}
}

export const getPersonalSpace = async () => {
	try {
		const res = await axios.get(STUDENT_API_URL + '/getPersonalSpace',
		{
      headers: {
        lang: localStorage.getItem('lang'),
				'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
		return res.data;
	} catch (e) {
		return e;
	}
}

export const getStudentsRating = async (filter = {}, limit = 10, offset = 0) => {
	try {
		const res = await axios.post(STUDENT_API_URL + '/getStudentsRating',
		{ ...filter, limit, offset },
		{
      headers: {
        lang: localStorage.getItem('lang'),
				//'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
		return res.data;
	} catch (e) {
		return e;
	}
}