import React from 'react'
import { darkB } from "../../cda-UIkit/constants/colors"

const InvisableIcon = ({size = '24', color = darkB, style}) => (
  <svg width={24} height={24} viewBox={`0 0 ${24} ${24}`} fill="none" xmlns="http://www.w3.org/2000/svg"
    style={{
      ...style,
      transform: `scale(${size / 24})`,
      transformOrigin: 'center'
    }}
  >
    <path d="M12.0001 19.0004C11.1581 19.0004 10.3151 18.8224 9.49609 18.5054"  stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M20.882 12.4678C18.99 15.9668 15.495 18.9998 12 18.9998"  stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M19.0781 8.9209C19.7691 9.7299 20.3831 10.6119 20.8811 11.5329C21.0381 11.8239 21.0381 12.1769 20.8811 12.4679"  stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5 19L19 5"  stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9.77211 14.2271C8.54211 12.9971 8.54211 11.0021 9.77211 9.77211C11.0021 8.54211 12.9971 8.54211 14.2271 9.77211"  stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M17.0437 6.956C15.4967 5.759 13.7478 5 11.9998 5C8.50475 5 5.00975 8.033 3.11775 11.533C2.96075 11.824 2.96075 12.177 3.11775 12.468C4.06375 14.217 5.40975 15.849 6.95575 17.045"  stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
);

export default InvisableIcon;