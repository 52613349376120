import damu from '../img/partners/damu.svg';
import yandex from '../img/partners/yandex.svg';
import alfabank from '../img/partners/alfabank.svg';
import beeline from '../img/partners/beeline.svg';
import bloomberg from '../img/partners/bloomberg.svg';
import meta from '../img/partners/Meta.svg';
import kazatom from '../img/partners/kazatom.svg';
import kpmg from '../img/partners/kmpg.png';
import nitec from '../img/partners/nitec.png';
import airastana from '../img/partners/airastana.png';
import kaspi from '../img/partners/kaspi.png';
import bigroup from '../img/partners/bigroup.png';
import fortebank from '../img/partners/fortebank.png';
import tele2 from '../img/partners/tele2.png';
import centercredit from '../img/partners/centercredit.svg';
import freedomfinance from '../img/partners/freedomfinance.svg';


export const discounts = [
  {
    id: 1,
    title: 'Скидка до 50%', 
    description: 'Приобрети курс одним из первых и получи скидку!', 
    color: 'white',
    background: null,
    active: true
  }
]

export const partners = [
  damu,
  beeline,
  kazatom,
  kpmg,
  nitec,
  airastana,
  kaspi,
  bigroup,
  fortebank,
  tele2,
  centercredit,
  freedomfinance
]