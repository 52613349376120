import React, {useEffect, useState} from 'react'
import { CircularProgress, Grid } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import {checkLang} from './lib/helpers'
import HeaderBar from './components/HeaderBar';
import './App.css';
import { Route } from 'react-router-dom';
import PersonalSpace from './pages/PersonalSpace';
import ProfessionPage from './pages/ProfessionPage';
import CoursePage from './pages/CoursePage';
import VideoPage from './pages/VideoPage';
import Home from './pages/Home';
import ProfTestPage from './pages/ProfTestPage'
import { check } from './requests/auth';
import ContactPage from './pages/ContactPage';
import InfoPage from './pages/InfoPage';
import CarearCentre from './pages/CarearCentre';
import ForEmployer from './pages/ForEmployer';
import Faq from './pages/Faq';
import BToB from './pages/BToB';
import TestPage from './pages/TestPage';
import RatingPage from './pages/RatingPage';
import axios from 'axios';
import { history } from './lib/history';
import { useSnackbar, withSnackbar } from 'notistack';
import { defaultB } from './cda-UIkit/constants/colors';
import AboutAcademy from './pages/AboutAcademy';
import Grants from './pages/Grants';

const App = () => {
  const { t, i18n } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [lang, setLang] = useState(localStorage.getItem('lang') || checkLang())
  const [active, setActive] = useState(false)
  const [CW, setCW] = useState(document.documentElement.clientWidth)
  const [loading, setLoading] = useState(false)

  axios.interceptors.request.use(config => {
    setLoading(true)
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    const lang = localStorage.getItem('lang');
    if (lang) {
      config.headers.lang = lang;
    }
    return config;
  });
  
  axios.interceptors.response.use(
    response => {
      setLoading(false)
      return response
    },
    error => {
      if ([401,403].includes(error?.request?.status) && !error?.request?.responseURL?.includes('/api-auth/check')) {
          localStorage.removeItem('token')
          setLoading(false)
          history.push('/')
          return enqueueSnackbar(t('notAuth'),{variant: 'error'})
      } else {
        if (error?.request?.status === 404) {
          setLoading(false)
          history.push('/')
          return enqueueSnackbar(t('404'),{variant: 'error'})
        }
      }
      setLoading(false)
      return Promise.reject(error)
    }
  )

  const changeLang = (l) => {
    localStorage.setItem('lang', l)
    setLang(l)
  }

  window.onresize = () => {
    setCW(document.documentElement.clientWidth)
  }

  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [lang])

  useEffect(() => {
    setLang(localStorage.getItem('lang') || checkLang())
    check().then(r => {
      if (r?.valid !== true) {
        localStorage.removeItem('token')
      }
    })
  }, [])

  return (
    <>
      <HeaderBar changeLang={changeLang} CW={CW} />
      {!!loading &&
        <Grid container 
          alignItems={'center'} justify={'center'} 
          style={{width: '100%', height:'100%', position: 'fixed', zIndex: 500, background: defaultB}}
        >
          <Grid item style={{paddingBottom: '15vh'}}>
            <CircularProgress />
          </Grid>
        </Grid>
      }
      <Grid style={{marginTop: 70, overflowX: 'hidden', overflowY: 'hidden'}}>
        <Route
          exact
          path='/'
          render={() => <Home CW={CW} />}
        />
        <Route
          exact
          path='/carear_test'
          render={() => <ProfTestPage CW={CW} />}
        />
        <Route
          exact
          path='/personal_area'
          render={() => <PersonalSpace CW={CW} />}
        />
        <Route
          exact
          path='/profession/:id'
          render={() => <ProfessionPage CW={CW} />}
        />
        <Route
          exact
          path='/course/:id'
          render={() => <CoursePage CW={CW} />}
        />
        <Route
          exact
          path='/lesson/:id'
          render={() => <VideoPage CW={CW} />}
        />
        <Route
          exact
          path='/test/:id'
          render={() => <TestPage CW={CW} />}
        />
        <Route
          exact
          path='/contact_page'
          render={() => <ContactPage CW={CW} />}
        />
        <Route
          exact
          path='/about_academy'
          render={() => <AboutAcademy CW={CW} />}
        />
        <Route
          exact
          path='/grants'
          render={() => <Grants CW={CW} />}
        />
        <Route
          exact
          path='/info_page'
          render={() => <InfoPage CW={CW} />}
        />
        <Route
          exact
          path='/carear_centre'
          render={() => <CarearCentre CW={CW} />}
        />
        <Route
          exact
          path='/for_employer'
          render={() => <ForEmployer CW={CW} />}
        />
        <Route
          exact
          path='/faq'
          render={() => <Faq CW={CW} />}
        />
        <Route
          exact
          path='/b2b'
          render={() => <BToB CW={CW} />}
        />
        <Route
          exact
          path='/rating'
          render={() => <RatingPage CW={CW} />}
        />
      </Grid>
    </>
  );
}

export default withSnackbar(App);
