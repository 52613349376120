import React, {useEffect, useState} from 'react'
import { Grid } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import CdaTypography from '../cda-UIkit/Typography/CdaTypography';
import moment from 'moment/moment';
import CdaCourseCard from '../cda-UIkit/Cards/CdaCourseCard';
import IconConnector from '../components/IconConnector';
import CdaProfesiesCard from '../cda-UIkit/Cards/CdaProfesiesCard';
import CdaButton from '../cda-UIkit/Buttons/CdaButton';
import ShevronIcon from '../img/icons/ShevronIcon';
import { primaryB } from '../cda-UIkit/constants/colors';
import CdaSwitch from '../cda-UIkit/Inputs/CdaSwitch';
import { history } from '../lib/history';
import { getProfInfoById, getTeachers } from '../requests/courses';
import { getReviewes } from '../requests/students';
import CdaCardHome from '../cda-UIkit/Cards/CdaCardHome';
import { toElem } from '../lib/helpers';
import InstructorsCarousel from '../components/InstructorsCarousel';
import PaymentCards from '../components/PaymentCards';
import ReviewCarousel from '../components/ReviewCarousel';
import FeedBackForm from '../components/FeedBackForm';
import Footer from '../components/Footer';
import CVPattern from '../components/CVPattern';

const ProfessionPage = ({CW}) => {
  const { t, i18n } = useTranslation()
  const [isAuth, setIsAuth] = useState(!!localStorage.getItem('token'))
  const [profFull, setProfFull] = useState()
  const [reviewes, setReviewes] = useState([])
  const [instructors, setInstructors] = useState([])  

  useEffect(() => {
    setIsAuth(!!localStorage.getItem('token'))
  }, [localStorage.token])

  useEffect(() => {
    getProfInfoById(document.location.pathname.split('/')[2]).then(r => {
      if(!!r?.data) {
        if (r?.status === 206 || !!r?.data?.basic) {
          setIsAuth(false)
        }
        setProfFull(r?.data/*?.sort((a,b) => a.id > b.id ? 1 : -1)*/)
      }
    })
    getReviewes(document.location.pathname.split('/')[2]).then(r => {
      if(!!r?.length) {
        setReviewes(r)
      }
    })
    moment.locale(localStorage.lang)
  }, [localStorage.lang, window.location.pathname])

  useEffect(() => {
    getTeachers(document.location.pathname.split('/')[2]).then(r => setInstructors(r))
    window.scroll(0,0)
  }, [])

  return (
    <Grid container 
      direction={'row'} 
      alignItems={'center'} 
      justify={'center'}
    >
      <Grid container 
        direction={'row'} 
        alignItems={'flex-start'} 
        justify={'center'}
        style={{
          padding: CW > 950 ? '64px' : '64px 8px 50px 8px', 
          overflowX: 'hidden',
          overflowY: 'hidden',
          maxWidth: 1600,
          marginBottom: '-12px'
        }}
        spacing={8}
      >
        <Grid item xs={12} md={12}
          container
          direction={'row'}
          alignItems={'center'} 
          justify={'space-between'}
          spacing={2}
          style={{paddingBottom: 0, paddingTop: 0}}
        >
          <Grid item>
            <CdaButton color={'tertiary'} size={'l'} onClick={() => history.goBack()}>
              <Grid container
                direction={'row'} 
                alignItems={'center'} 
                justifyContent={'flex-start'}
              >
                <Grid item>
                  <ShevronIcon style={{transform: 'rotate(90deg)', marginRight: 4, marginTop: -6, marginLeft: -10}} color={primaryB} />
                </Grid>
                <Grid item>{t('back')}</Grid>
              </Grid>
            </CdaButton>
          </Grid>
            {!!isAuth ?
              <Grid item>
                <Grid item>
                  <CdaSwitch 
                    label={t('PersonalSpace.hideFinished')} 
                    labelPlacement={'start'}
                  />
                </Grid>
              </Grid>
              :
              <Grid item>
              </Grid>
            }
        </Grid>
        <Grid item xs={12} md={6}
          container
          direction={'column'}
          alignItems={'flex-start'} 
          justify={'flex-start'}
          spacing={2}
          style={{paddingTop: 20, marginBottom: CW > 950 && 24}}
        >
          <Grid item style={{width: '100%', marginBottom: 24}}>
            {!!isAuth ? (!!profFull?.id &&
              <CdaProfesiesCard
                elevation={0}
                clicable={!isAuth}
                onClick={() => !isAuth && toElem('onbuy')}
                icon={!!profFull?.logo && JSON.parse(profFull?.logo)?.icon}
                color={!!profFull?.logo && JSON.parse(profFull?.logo)?.color} 
                label={profFull?.name}
                info={!!isAuth ? {v: {count: Number(profFull?.videos_count), total: Number(profFull?.videos_total)}, t: {count: Number(profFull?.tasks_count), total: Number(profFull?.tasks_total)}} : profFull?.info}
                duration={!!profFull?.duration && !isAuth && (
                  (moment(profFull?.duration).from('1970-01-01').replace(/[^\d]/g, '') === '' ? '1 ' : '') +
                  (localStorage.lang !== 'kz' ?
                    moment(profFull?.duration).from('1970-01-01')
                      .replace('через ', '')
                      .replace('in ', '')
                      .replace('a ', '')
                    :
                    moment(profFull?.duration).from('1970-01-01')
                      .replace('через ', '')
                      .replace('in ', '')
                      .replace('года', 'жыл')
                      .replace('год', 'жыл')
                      .replace('лет', 'жыл')
                      .replace('месяцев', 'ай')
                      .replace('месяца', 'ай')
                      .replace('месяц', 'ай')
                      .replace('a year', 'жыл')
                      .replace('years', 'жыл')
                      .replace('a month', 'ай')
                      .replace('months', 'ай')
                  )
                )}
                price={!isAuth && profFull?.price}
              />
            ) : (!!profFull?.id &&
              <CdaCardHome
                CW={CW}
                elevation={0}
                style={{
                  boxShadow: '0px 0px 15px rgba(74, 85, 104, 0.1)', 
                  width: '100%'
                }}
                onClick={() => toElem('onbuy')} 
                icon={!!profFull?.logo && 
                  <IconConnector 
                    icon={JSON.parse(profFull?.logo)?.icon} 
                    color={JSON.parse(profFull?.logo)?.color}
                    size={CW > 1160 ? 68 : 40}
                  />
                } 
                color={!!profFull?.logo && JSON.parse(profFull?.logo)?.color} 
                label={profFull?.name} 
                price={profFull?.price}
                info={!!profFull?.duration && (
                  (moment(profFull?.duration).from('1970-01-01').replace(/[^\d]/g, '') === '' ? '1 ' : '') +
                  (localStorage.lang !== 'kz' ?
                    moment(profFull?.duration).from('1970-01-01')
                      .replace('через ', '')
                      .replace('in ', '')
                      .replace('a ', '')
                    :
                    moment(profFull?.duration).from('1970-01-01')
                      .replace('через ', '')
                      .replace('in ', '')
                      .replace('года', 'жыл')
                      .replace('год', 'жыл')
                      .replace('лет', 'жыл')
                      .replace('месяцев', 'ай')
                      .replace('месяца', 'ай')
                      .replace('месяц', 'ай')
                      .replace('a year', 'жыл')
                      .replace('years', 'жыл')
                      .replace('a month', 'ай')
                      .replace('months', 'ай')
                  )
                )}
              />
            )}
          </Grid>
          {!isAuth &&
            <>
              <Grid item style={{width: '100%'}}>
                <CdaTypography type={'l'} 
                  color={'classic'} 
                  label={t('PersonalSpace.aboutProf')} 
                  style={{marginBottom: '12px', fontWeight: 600}}
                />
              </Grid>
              <Grid item style={{width: '100%'}}>
                <CdaTypography
                  ihtml
                  type={'bm'}
                  style={{
                    textIndent: '20px'
                  }}
                  label={profFull?.description?.replaceAll('\\n', '<br>')} 
                />
              </Grid>
            </>
          }
        </Grid>
        <Grid item xs={12} md={6}
          container
          direction={'column'}
          alignItems={'flex-start'} 
          justify={'flex-start'}
          spacing={2}
          style={{marginBottom: 24}}
        >
          <Grid item xs={12} md={12}>
            <CdaTypography type={'l'} 
              color={'classic'} 
              label={!!isAuth ? t('PersonalSpace.courses') : t('inside')} 
              style={{marginBottom: '24px', fontWeight: 600}}
            />
          </Grid>
          <Grid item container 
            xs={12} md={12}
            direction={'row'}
            alignItems={'flex-start'}
            justify={CW > 600 ? 'flex-start' : 'center'}
            spacing={2}
            style={{marginBottom: '12px'}}
          >
            {profFull?.courses?.map(c => 
              <Grid item>
                {!!isAuth ? 
                  <CdaCourseCard
                    clicable
                    elevation={2}
                    onClick={() => history.push(`/course/${c?.id}`)}
                    icon={<IconConnector icon={JSON.parse(c?.logo)?.icon} color={JSON.parse(c?.logo)?.color} />}
                    color={JSON.parse(c?.logo)?.color} 
                    label={c?.name} 
                    info={{v: {count: Number(c?.videos_count), total: Number(c?.videos_total)}, t: {count: Number(c?.tasks_count), total: Number(c?.tasks_total)}}}
                  />
                  :
                  <CdaCardHome
                    elevation={0}
                    onClick={() => history.push(`/course/${c?.id}`)}
                    style={{boxShadow: '0px 0px 15px rgba(74, 85, 104, 0.1)'}}
                    price={c?.price}
                    profName={c?.profName}
                    icon={!!c?.logo && 
                      <IconConnector 
                        icon={JSON.parse(c?.logo)?.icon} 
                        color={JSON.parse(c?.logo)?.color}
                      />
                    } 
                    color={!!c?.logo && JSON.parse(c?.logo)?.color} 
                    label={c?.name} 
                    info={!!c?.duration && (
                      (moment(c?.duration).from('1970-01-01').replace(/[^\d]/g, '') === '' ? '1 ' : '') +
                      (localStorage.lang !== 'kz' ?
                        moment(c?.duration).from('1970-01-01')
                          .replace('через ', '')
                          .replace('in ', '')
                          .replace('a ', '')
                        :
                        moment(c?.duration).from('1970-01-01')
                          .replace('через ', '')
                          .replace('in ', '')
                          .replace('года', 'жыл')
                          .replace('год', 'жыл')
                          .replace('лет', 'жыл')
                          .replace('месяцев', 'ай')
                          .replace('месяца', 'ай')
                          .replace('месяц', 'ай')
                          .replace('a year', 'жыл')
                          .replace('years', 'жыл')
                          .replace('a month', 'ай')
                          .replace('months', 'ай')
                      )
                    )}
                  />
                }
              </Grid>
            )}
          </Grid>
        </Grid>        
        {!isAuth &&
          <Grid item xs={12} md={12}
            container
            direction={'row'}
            alignItems={'center'} 
            justify={'center'}
            spacing={CW > 600 ? 2 : 0}
            style={{paddingBottom: 0, paddingTop: 0}}
          >
            {!!profFull?.cvItems && profFull?.cvPrice &&
              <CVPattern items={profFull?.cvItems} name={profFull?.name} price={profFull?.cvPrice} />
            }
            <Grid item xs={12} md={12}
              container 
              direction={'row'} 
              justify={'flex-start'} 
              alignItems={'flex-start'} 
              spacing={2}
              style={{marginBottom: 36}}
            >
              <InstructorsCarousel
                title={t('PersonalSpace.instructors')}
                instructors={instructors}
                CW={CW}
              />
            </Grid>
            <Grid item container xs={12} md={12} spacing={2}>
              <Grid item><CdaTypography type={'h3'} label={t('taxs')} style={{fontWeight: 600, marginBottom: 36}}/></Grid>
            </Grid>
            <PaymentCards
              CW={CW}
              costBasic={profFull?.basic}
              costPro={profFull?.price}
              style={{marginBottom: '24px'}}
              duration={Math.round(moment.duration(moment(profFull?.duration) - moment('1970-01-01')).asMonths())}
            />
            <Grid item xs={12} md={12}
              container 
              direction={'row'} 
              justify={'flex-start'} 
              alignItems={'flex-start'} 
              spacing={2}
              style={{marginBottom: 36}}
            >
              <ReviewCarousel
                title={t('PersonalSpace.reviewes')}
                reviewes={reviewes}
                CW={CW}
              />
            </Grid>
          </Grid>
        }
        {!isAuth && 
          <Grid item xs={12} md={12}
            container
            direction={'row'}
            alignItems={'center'} 
            justify={'center'}
            spacing={2}
            style={{padding: 8}}
          >
            <FeedBackForm
              title={t('chooseHelpForm.feedBack')}
              subTitle={t('home.chooseHelpInfo')}
              CW={CW}
              page={'profession'}
              itemId={profFull?.id || document.location.pathname.split('/')[2]}
            />
          </Grid>
        }
      </Grid>
      {!isAuth &&
        <Footer CW={CW} />
      }
    </Grid>
  );
}

export default ProfessionPage;
