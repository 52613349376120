import React, {useEffect, useState} from 'react'
import { Card, CardContent, Grid, withStyles } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import CdaTypography from '../cda-UIkit/Typography/CdaTypography';
import 'moment/locale/ru';
import { defaultB } from '../cda-UIkit/constants/colors';
import Footer from '../components/Footer';
import FeedBackForm from '../components/FeedBackForm';

const styles = {
	root: {
		background: defaultB,
    padding: '0px',
		borderRadius: '24px',
    border: 'none',
    width: '100%'
	},
  content: {
    padding: '16px',
    paddingBottom: '16px !important'
  }
}

const AboutAcademy = withStyles(styles)(({ classes, CW }) => {
  const { t } = useTranslation()

  useEffect(() => {
    window.scroll(0,0)
  }, [])

  return (
    
    <Grid container 
      direction={'column'} 
      alignItems={'center'} 
      justify={'space-between'}
      style={{minHeight: '100vh', width: '100vw'}}
    >
      <Grid item container 
        direction={'row'} 
        alignItems={'flex-start'} 
        justify={'center'}
        style={{padding: CW > 1070 ? '64px' : '56px 8px', overflowX: 'hidden', overflowY: 'hidden', maxWidth: 1600}}
        spacing={6}
      >
        <Grid item xs={12} md={12}>
          <CdaTypography type={'h2'} label={t('aboutAcademy.title')} style={{fontWeight: 600}} />
        </Grid>
        <Grid item container 
          direction={'row'}
          alignItems={'flex-start'} 
          justify={'flex-start'}
          spacing={2}
          style={{margin: 0}}
        >
          <Grid item xs={12} md={12}>
            <CdaTypography type={'bm'} label={t('aboutAcademy.main')} />
          </Grid>
          <Grid item xs={12} md={12}>
            <CdaTypography type={'bm'} label={t('aboutAcademy.main2')} />
          </Grid>
          <Grid item xs={12} md={12}>
            <CdaTypography type={'bm'} label={t('aboutAcademy.main3')} />
          </Grid>
        </Grid>
        <Grid item xs={12} md={3}
          container 
          direction={'row'} 
          alignItems={'flex-start'} 
          justify={'flex-start'}
          spacing={2}
        >
          <Grid item xs={12} md={12}>
            <CdaTypography type={'subTitle'} label={t('aboutAcademy.subtitle')} style={{fontWeight: 600}} />
          </Grid>
        </Grid>
        <Grid item xs={12} md={9}
          container 
          direction={'row'} 
          alignItems={'flex-start'} 
          justify={'flex-start'}
          spacing={2}
          style={{marginBottom: 24}}
        >
          <Grid item xs={12} md={12}>
            <CdaTypography type={'bm'} label={t('aboutAcademy.submain')} />
          </Grid>
          <Grid item xs={12} md={12}>
            <CdaTypography type={'l'} label={t('aboutAcademy.topik1')} style={{fontWeight: 600}} />
          </Grid>
          <Grid item xs={12} md={12}>
            <CdaTypography type={'bm'} label={t('aboutAcademy.content1')} />
          </Grid>
          <Grid item xs={12} md={12}>
            <CdaTypography type={'l'} label={t('aboutAcademy.topik2')} style={{fontWeight: 600}} />
          </Grid>
          <Grid item xs={12} md={12}>
            <CdaTypography type={'bm'} label={t('aboutAcademy.content2')} />
          </Grid>
          <Grid item xs={12} md={12}>
            <CdaTypography type={'l'} label={t('aboutAcademy.topik3')} style={{fontWeight: 600}} />
          </Grid>
          <Grid item xs={12} md={12}>
            <CdaTypography type={'bm'} label={t('aboutAcademy.content3')} />
          </Grid>
          <Grid item xs={12} md={12}>
            <CdaTypography type={'l'} label={t('aboutAcademy.topik4')} style={{fontWeight: 600}} />
          </Grid>
          <Grid item xs={12} md={12}>
            <CdaTypography type={'bm'} label={t('aboutAcademy.content4')} />
          </Grid>
          <Grid item xs={12} md={12}>
            <CdaTypography type={'l'} label={t('aboutAcademy.topik5')} style={{fontWeight: 600}} />
          </Grid>
          <Grid item xs={12} md={12}>
            <CdaTypography type={'bm'} label={t('aboutAcademy.content5')} />
          </Grid>
          <Grid item xs={12} md={12}>
            <CdaTypography type={'l'} label={t('aboutAcademy.topik6')} style={{fontWeight: 600}} />
          </Grid>
          <Grid item xs={12} md={12}>
            <CdaTypography type={'bm'} label={t('aboutAcademy.content6')} />
          </Grid>
        </Grid>
        <FeedBackForm
          title={t('chooseHelpForm.feedBack')}
          subTitle={t('home.chooseHelpInfo')}
          CW={CW}
          page={'about_academy'}
        />
      </Grid>
      <Footer CW={CW} />
    </Grid>
  );
})

export default AboutAcademy;