import React, {useEffect, useState} from 'react'
import { Card, CardContent, Grid, withStyles } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import CdaTypography from '../cda-UIkit/Typography/CdaTypography';
import 'moment/locale/ru';
import { defaultB } from '../cda-UIkit/constants/colors';
import Footer from '../components/Footer';
import FeedBackForm from '../components/FeedBackForm';

const anyText = "Какой-то текст, и тут, и тут, и тут, и тут, и тут, и тут, и тут, и тут, и тут, и тут, и тут," +
  " и тут, и тут, и тут, и тут, и тут, и тут, и тут, и тут, и тут, и тут, и тут, и тут, и тут, и тут, и тут, и тут," +
  " и тут, и тут, и тут, и тут, и тут, и тут, и тут, и тут, и тут, и тут, и тут, и тут, и тут, и тут, и тут, и тут," +
  " и тут, и тут, и тут, и тут, и тут, и тут, и тут, и тут, и тут, и тут, и тут, и тут, и тут, и тут, и тут, и тут"

const styles = {
	root: {
		background: defaultB,
    padding: '0px',
		borderRadius: '24px',
    border: 'none',
    width: '100%'
	},
  content: {
    padding: '16px',
    paddingBottom: '16px !important'
  }
}

const InfoPage = withStyles(styles)(({ classes, CW }) => {
  const { t } = useTranslation()

  useEffect(() => {
    window.scroll(0,0)
  }, [])

  return (
    
    <Grid container 
      direction={'row'} 
      alignItems={'center'} 
      justify={'center'}
    >
      <Grid item container 
        direction={'row'} 
        alignItems={'flex-start'} 
        justify={'flex-start'}
        style={{padding: CW > 1070 ? '64px' : '56px 36px', overflowX: 'hidden', overflowY: 'hidden', maxWidth: 1600}}
        spacing={6}
      >
        <Grid item xs={12} md={12}>
          <CdaTypography type={'h2'} label={'Тайтл'} style={{fontWeight: 600}} />
        </Grid>
        <Grid item xs={12} md={3}
          container 
          direction={'row'} 
          alignItems={'flex-start'} 
          justify={'flex-start'}
          spacing={2}
        >
          <Grid item xs={12} md={12}>
            <CdaTypography type={'subTitle'} label={'Заголовок'} style={{fontWeight: 600}} />
          </Grid>
        </Grid>
        <Grid item xs={12} md={9}
          container 
          direction={'row'} 
          alignItems={'flex-start'} 
          justify={'flex-start'}
          spacing={2}
        >
          <Grid item xs={12} md={12}>
            <CdaTypography type={'l'} label={"Подзаголовок"} style={{fontWeight: 600}} />
          </Grid>
          <Grid item xs={12} md={12}>
            <CdaTypography type={'bm'} label={anyText} />
          </Grid>
          <Grid item xs={12} md={12}>
            <CdaTypography type={'bm'} label={anyText} />
          </Grid>          
          <Grid item xs={12} md={12}>
            <CdaTypography type={'l'} label={"Подзаголовок"} style={{fontWeight: 600}} />
          </Grid>
          <Grid item xs={12} md={12}>
            <CdaTypography type={'bm'} label={anyText} />
          </Grid>
          <Grid item xs={12} md={12}>
            <CdaTypography type={'bm'} label={anyText} />
          </Grid>
          <Grid item xs={12} md={12}>
            <CdaTypography type={'bm'} label={anyText} />
          </Grid>
        </Grid>        
        <Grid item xs={12} md={3}
          container 
          direction={'row'} 
          alignItems={'flex-start'} 
          justify={'flex-start'}
          spacing={2}
        >
          <Grid item xs={12} md={12}>
            <CdaTypography type={'subTitle'} label={'Заголовок'} style={{fontWeight: 600}} />
          </Grid>
        </Grid>
        <Grid item xs={12} md={9}
          container 
          direction={'row'} 
          alignItems={'flex-start'} 
          justify={'flex-start'}
          spacing={2}
        >
          <Grid item xs={12} md={12}>
            <CdaTypography type={'l'} label={"Подзаголовок"} style={{fontWeight: 600}} />
          </Grid>
          <Grid item xs={12} md={12}>
            <CdaTypography type={'bm'} label={anyText} />
          </Grid>
          <Grid item xs={12} md={12}>
            <CdaTypography type={'bm'} label={anyText} />
          </Grid>          
          <Grid item xs={12} md={12}>
            <CdaTypography type={'l'} label={"Подзаголовок"} style={{fontWeight: 600}} />
          </Grid>
          <Grid item xs={12} md={12}>
            <CdaTypography type={'bm'} label={anyText} />
          </Grid>
          <Grid item xs={12} md={12}>
            <CdaTypography type={'bm'} label={anyText} />
          </Grid>
          <Grid item xs={12} md={12}>
            <CdaTypography type={'bm'} label={anyText} />
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} style={{marginTop: 24}}>
          <FeedBackForm
            title={t('chooseHelpForm.feedBack')}
            subTitle={t('home.chooseHelpInfo')}
            CW={CW}
          />
        </Grid>
      </Grid>
      <Footer CW={CW} />
    </Grid>
  );
})

export default InfoPage;