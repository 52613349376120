import React from 'react'
import { darkB } from "../../cda-UIkit/constants/colors"

const SearchIcon = ({size = '24', color = darkB, style}) => (
  <svg width={24} height={24} viewBox={`0 0 ${24} ${24}`} fill="none" xmlns="http://www.w3.org/2000/svg"
    style={{
      ...style,
      transform: `scale(${size / 24})`,
      transformOrigin: 'center'
    }}
  >
    <path d="M10.8652 5.495C10.8622 5.495 10.8602 5.497 10.8602 5.5C10.8602 5.503 10.8622 5.505 10.8652 5.505C10.8682 5.505 10.8702 5.503 10.8702 5.5C10.8702 5.497 10.8682 5.495 10.8652 5.495" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8.32617 5.495C8.32317 5.495 8.32117 5.497 8.32117 5.5C8.32117 5.503 8.32317 5.505 8.32617 5.505C8.32917 5.505 8.33117 5.503 8.33117 5.5C8.33117 5.497 8.32917 5.495 8.32617 5.495" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.7793 5.49012C5.7763 5.49012 5.7743 5.49212 5.7743 5.49512C5.7743 5.49812 5.7763 5.50012 5.7793 5.50012C5.7823 5.50012 5.7843 5.49812 5.7843 5.49512C5.7843 5.49212 5.7823 5.49012 5.7793 5.49012" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M3 8H21" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M21 10V5C21 3.895 20.105 3 19 3H5C3.895 3 3 3.895 3 5V18C3 19.105 3.895 20 5 20H9" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M20.9995 20.9995L19.0195 19.0195" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15.834 20.334C13.349 20.334 11.334 18.319 11.334 15.834C11.334 13.349 13.349 11.334 15.834 11.334C18.32 11.334 20.334 13.349 20.334 15.834C20.334 18.319 18.32 20.334 15.834 20.334" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15.084 15.084L15.834 14.333V17.334" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15.0879 17.3369H16.5819" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
);

export default SearchIcon;