import React, { useState } from 'react'
import { Avatar, Card, CardContent, Grid, withStyles } from '@material-ui/core'
import {
	defaultB,
  secondaryC
} from '../cda-UIkit/constants/colors'
import '../cda-UIkit/fonts/style.css'
import CdaTypography from '../cda-UIkit/Typography/CdaTypography'
import { useTranslation } from 'react-i18next'
import { thousandSeparator } from '../lib/helpers'
import CdaButton from '../cda-UIkit/Buttons/CdaButton'
import bg from '../img/profTestBg.jpg'
import PaymentDialog from './PaymentDialog'

const styles = {
	root: {
		background: defaultB,
    padding: '0px',
		borderRadius: '16px',
    marginBottom: '24px'
	},
  basic: {
    backgroundColor: 'transparent',
    padding: '24px',
    paddingBottom: '16px !important',
    height: '90%'
  },
  pro: {
    background: 'transparent',
    padding: '24px',
    paddingBottom: '16px !important',
    height: '90%'
  }
}

const PaymentCards = withStyles(styles)(({ classes, CW, costBasic, costPro, duration, ...other }) => {
  const { t, i18n } = useTranslation()
  const [open, setOpen] = useState(false)

  const toLocalMonths = () => {
    return {
      ru: 'мес.',
      kz: 'айына',
      en: 'month'
    }[localStorage.lang]
  }

  const Basic = () => (
    <CardContent className={classes.basic}>
      <Grid container
        direction={'column'}
        justifyContent={'space-between'}
        style={{height: '100%'}}
      >
        <Grid item 
          container 
          spacing={1}
        >
          <Grid item xs={12} md={12} style={{marginBottom: 32}}>
            <CdaTypography color={'white'} type={'subTitle'} label={t('PersonalSpace.basic')} style={{fontWeight: 600}} />
          </Grid>
          <Grid item xs={12} md={12}>
            <CdaTypography color={'white'} type={'label'} label={' + ' + t('PersonalSpace.basic1')}/>
          </Grid>
          <Grid item xs={12} md={12}>
            <CdaTypography color={'white'} type={'label'} label={' + ' + t('PersonalSpace.basic2')}/>
          </Grid>
          <Grid item xs={12} md={12}>
            <CdaTypography color={'white'} type={'label'} label={' + ' + t('PersonalSpace.basic3')}/>
          </Grid>
          <Grid item xs={12} md={12}>
            <CdaTypography color={'white'} type={'label'} label={' + ' + t('PersonalSpace.basic4')}/>
          </Grid>
          <Grid item xs={12} md={12}>
            <CdaTypography color={'white'} type={'label'} label={' + ' + t('PersonalSpace.basic5')}/>
          </Grid>
          <Grid item xs={12} md={12}>
            <CdaTypography color={'white'} type={'label'} label={' + ' + t('PersonalSpace.basic6')}/>
          </Grid>
        </Grid>
        <Grid item 
          container 
          direction={'row'} 
          justify={'space-between'} 
          alignItems={'center'}
          spacing={2}
        >  
          <Grid item>
            <CdaTypography 
              type={'l'} 
              label={thousandSeparator(costBasic?.toString()) + ' ₸'}
              color={'grey'}
              style={{textDecoration: 'line-through'}}
            />
            <CdaTypography 
              color={'white'}
              type={'l'} 
              label={thousandSeparator(Math.round(costBasic / duration)?.toString()) + ' ₸/' + toLocalMonths()}
            />
          </Grid>
          <Grid item container={CW < 755 && localStorage.lang === 'kz' && 'row'} justify={'center'}>
            <CdaButton color={'primary'} size={'l'} onClick={() => setOpen('basic')}>
              {t('buy')}
            </CdaButton>
          </Grid>
        </Grid>
      </Grid>
    </CardContent>
  )

  const Pro = () => (
    <CardContent className={classes.pro} id={'onbuy'} /*style={{height: CW < 755 && 480}}*/ >
      <Grid container
        direction={'column'}
        justifyContent={'space-between'}
        style={{height: '100%'}}
      >
        <Grid item 
          container 
          spacing={1}
        >
          <Grid item xs={12} md={12} style={{marginBottom: 32}}>
            <CdaTypography 
              type={'subTitle'} 
              label={t('PersonalSpace.pro')} 
              style={{fontWeight: 600}} 
              color={'white'}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <CdaTypography color={'white'} type={'label'} label={' + ' + t('PersonalSpace.basic1')}/>
          </Grid>
          <Grid item xs={12} md={12}>
            <CdaTypography color={'white'} type={'label'} label={' + ' + t('PersonalSpace.pro1')}/>
          </Grid>
          <Grid item xs={12} md={12}>
            <CdaTypography color={'white'} type={'label'} label={' + ' + t('PersonalSpace.basic3')}/>
          </Grid>
          <Grid item xs={12} md={12}>
            <CdaTypography color={'white'} type={'label'} label={' + ' + t('PersonalSpace.basic4')}/>
          </Grid>
          <Grid item xs={12} md={12}>
            <CdaTypography color={'white'} type={'label'} label={' + ' + t('PersonalSpace.basic5')}/>
          </Grid>
          <Grid item xs={12} md={12}>
            <CdaTypography color={'white'} type={'label'} label={' + ' + t('PersonalSpace.basic6')}/>
          </Grid>
          <Grid item xs={12} md={12}>
            <CdaTypography color={'white'} type={'label'} label={' + ' + t('PersonalSpace.pro2')}/>
          </Grid>
          <Grid item xs={12} md={12}>
            <CdaTypography color={'white'} type={'label'} label={' + ' + t('PersonalSpace.pro3')}/>
          </Grid>
          <Grid item xs={12} md={12}>
            <CdaTypography color={'white'} type={'label'} label={' + ' + t('PersonalSpace.pro4')}/>
          </Grid>
        </Grid>
        <Grid item 
          container 
          direction={'row'} 
          justify={'space-between'} 
          alignItems={'center'}
          spacing={1}
        >
          <Grid item>
            <CdaTypography 
              color={'grey'}
              type={'l'} 
              label={thousandSeparator(costPro?.toString()) + ' ₸'}
              style={{textDecoration: 'line-through'}}
            />
            <CdaTypography 
              type={'l'} 
              color={'white'}
              label={thousandSeparator(Math.round(costPro / duration)?.toString()) + ' ₸/' + toLocalMonths()}
            />
          </Grid>
          <Grid item container={CW < 755 && localStorage.lang === 'kz' && 'row'} justify={'center'}>
            <CdaButton color={'primary'} size={'l'} onClick={() => setOpen('pro')}>
              {t('buy')}
            </CdaButton>
          </Grid>
        </Grid>
      </Grid>
    </CardContent>
  )

  return(
    <Grid item xs={12} md={12}
      container
      direction={'raw'}
      justifyContent={'center'}
      spacing={CW > 950 ? 8 : 2}
      {...other} 
    >
      <Grid item>
        <Card
          elevation={2}
          className={classes.root}
          style={{ /*maxWidth: CW < 1100 ? (CW < 390 ? '216px' : '310px') : '500px' */
          maxWidth: CW < 1100 ? '310px' : '500px', position: 'relative', background: 'transparent',
          height: '100%'
          }}
        >
          <Avatar
            alt={''} 
            src={bg} 
            style={{
              height: '100%', 
              width: '100%', 
              position: 'absolute',
              zIndex: -1,
              borderRadius: 16,
              transform: 'scaleX(-1)'
            }}
          />
          {Basic()}
        </Card>
      </Grid>	
      <Grid item>
        <Card
          elevation={12}
          className={classes.root}
          style={{ maxWidth: CW < 1100 ? '310px' : '500px', position: 'relative', background: 'transparent',
          height: '100%'
          }}
        >
          <Avatar
            alt={''} 
            src={bg} 
            style={{
              height: '100%', 
              width: '100%', 
              position: 'absolute',
              zIndex: -1,
              borderRadius: 16
            }}
          />
          {Pro()}
        </Card>
      </Grid>
      {!!open &&
        <PaymentDialog 
          open={open} 
          handleClose={() => setOpen(false)}
          cost={open === 'basic' ? costBasic : costPro}
          duration={duration}
          toLocalMonths={toLocalMonths}
          CW={CW} 
        />
      }
    </Grid>
  );
})

export default PaymentCards;
