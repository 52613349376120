import React, {useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next';
import { Avatar, Grid } from '@material-ui/core';
import { defaultB, secondaryB, secondaryC } from '../cda-UIkit/constants/colors';
import CdaTypography from '../cda-UIkit/Typography/CdaTypography';
import { thousandSeparator } from '../lib/helpers';

const CVPattern = ({CW, items, price, name}) => {
  const { t } = useTranslation()

  return(
    <Grid item xs={12} md={12}
      container 
      direction={'row'} 
      justify={'flex-start'} 
      alignItems={'flex-start'} 
      spacing={2}
      style={{marginBottom: 48}}
    >
      <Grid item xs={12} style={{marginBottom: 44}}>
        <CdaTypography type={'h3'} label={t('cv.prof')} style={{fontWeight: 600}}/>
      </Grid>
      <Grid item xs={12} md={12}
        container 
        direction={'row'} 
        justify={'flex-start'} 
        alignItems={'flex-start'} 
        spacing={4}
        style={{marginBottom: 36, background: secondaryB, borderRadius: 16, marginLeft: 0}}
      >
        <Grid item container xs={12} direction={'row'} alignItems={'flex-start'} justify={'space-between'}>
          <Grid item>
            <CdaTypography type={'l'} label={name} style={{fontWeight: 600}}/>
          </Grid>
          <Grid item>
            <CdaTypography type={'l'} label={t('cv.price', {price: thousandSeparator(price) + ' ₸'})} style={{fontWeight: 600}}/>
          </Grid>
        </Grid>
        <Grid item container xs={12} direction={'row'} alignItems={'flex-start'} justify={'flex-start'}>
          <Grid item xs={12} md={3}>
            <CdaTypography type={'bm'} label={t('cv.tools') + ':'} style={{fontWeight: 600, marginBottom: 8}}/>
          </Grid>
          <Grid item container direction={'row'} xs={12} md={9} spacing={1} justify={'flex-start'}>
            {items?.filter(f => Number(f.type) === 1)?.map(e =>
              <Grid item container 
                direction={'row'} 
                alignItems={'center'} 
                justifyContent={'space-between'} 
                spacing={1}
                style={{background: defaultB, width: 'fit-content', borderRadius: 8, margin: 8, padding: '2px 2px 0px 2px'}}
              >
                {!!e?.image &&
                  <Grid item>
                    <Avatar src={e?.image} style={{height: 24, width: 24, borderRadius: 0, marginTop: -1}} />
                  </Grid>
                }
                <Grid item>
                  <CdaTypography type={'bm'} label={e?.text} style={{fontWeight: 500}}/>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item container xs={12} direction={'row'} alignItems={'flex-start'} justify={'flex-start'}>
          <Grid item xs={12} md={3}>
            <CdaTypography type={'bm'} label={t('cv.skills') + ':'} style={{fontWeight: 600, marginBottom: 12}}/>
          </Grid>
          <Grid item container direction={'row'} xs={12} md={9} spacing={1} justify={'space-between'}>
            {items?.filter(f => Number(f.type) === 2)?.map(e =>
              <Grid item container 
                direction={'column'} 
                alignItems={'flex-start'} 
                justifyContent={'flex-start'} 
                spacing={1}
              >
                <Grid item>
                  <CdaTypography type={'bm'} label={' • ' + e?.text} style={{fontWeight: 500}}/>
                </Grid>
              </Grid>
            )}
        </Grid>
      </Grid>
        <Grid item container xs={12} direction={'row'} alignItems={'flex-start'} justify={'flex-start'}>
          <Grid item xs={12} md={3}>
            <CdaTypography type={'bm'} label={t('cv.projects') + ':'} style={{fontWeight: 600, marginBottom: 12}}/>
          </Grid>
          <Grid item container direction={'row'} xs={12} md={9} spacing={1} justify={'space-between'}>
            {items?.filter(f => Number(f.type) === 3)?.map(e =>
              <Grid item container 
                direction={'column'} 
                alignItems={'flex-start'} 
                justifyContent={'flex-start'} 
                spacing={1}
              >
                <Grid item>
                  <CdaTypography type={'bm'} label={' • ' + e?.text} style={{fontWeight: 500}}/>
                </Grid>
              </Grid>
            )}
        </Grid>
      </Grid>
      </Grid>
    </Grid>
  );
};

export default CVPattern;