export const collection = [
  {
    en: 'Afghanistan',
    ru: 'Афганистан',
    mask: '+\\93 (99) 999-99-99',
    code: '+93',
    flag: '🇦🇫',
    min: 'AF'
  },
  {
    en: 'Albania',
    ru: 'Албания',
    mask: '+355 (99) 999-99-99',
    code: '+355',
    flag: '🇦🇱',
    min: 'AL'
  },
  {
    en: 'Algeria',
    ru: 'Алжир',
    mask: '+213 (99) 999-99-99',
    code: '+213',
    flag: '🇩🇿',
    min: 'DZ'
  },
  {
    en: 'Andorra',
    ru: 'Андорра',
    mask: '+376 (99) 999-99-99',
    code: '+376',
    flag: '🇦🇩',
    min: 'AD'
  },
  {
    en: 'Angola',
    ru: 'Ангола',
    mask: '+244 (99) 999-99-99',
    code: '+244',
    flag: '🇦🇴',
    min: 'AO'
  },
  {
    en: 'Antigua and Barbuda',
    ru: 'Антигуа и Барбуда',
    mask: '+1268 (9) 999-99-99',
    code: '+1268',
    flag: '🇦🇬',
    min: 'AG'
  },
  {
    en: 'Armenia',
    ru: 'Армения',
    mask: '+374 (99) 999-99-99',
    code: '+374',
    flag: '🇦🇲',
    min: 'AM'
  },
  {
    en: 'Argentina',
    ru: 'Аргентина',
    mask: '+54 (99) 999-99-99',
    code: '+54',
    flag: '🇦🇷',
    min: 'AR'
  },
  {
    en: 'Australia',
    ru: 'Австралия',
    mask: '+61 (99) 999-99-99',
    code: '+61',
    flag: '🇦🇺',
    min: 'AU'
  },
  {
    en: 'Austria',
    ru: 'Австрия',
    mask: '+43 (99) 999-99-99',
    code: '+43',
    flag: '🇦🇹',
    min: 'AT'
  },
  {
    en: 'Azerbaijan',
    ru: 'Азербайджан',
    mask: '+\\9\\94 (999) 99-99-99',
    code: '+994',
    flag: '🇦🇿',
    min: 'AZ'
  },
  {
    en: 'Bahrain',
    ru: 'Бахрейн',
    mask: '+\\9734 (9) 999-99-99',
    code: '+9734',
    flag: '🇧🇭',
    min: 'BH'
  },
  {
    en: 'Bangladesh',
    ru: 'Бангладеш',
    mask: '+880 (99) 999-99-99',
    code: '+880',
    flag: '🇧🇩',
    min: 'BD'
  },
  {
    en: 'Barbados',
    ru: 'Барбадос',
    mask: '+1246 (9) 999-99-99',
    code: '+1246',
    flag: '🇧🇧',
    min: 'BB'
  },
  {
    en: 'Belarus',
    ru: 'Беларусь',
    mask: '+375 (99) 999-99-99',
    code: '+375',
    flag: '🇧🇾',
    min: 'BY'
  },
  {
    en: 'Belgium',
    ru: 'Бельгия',
    mask: '+32 (99) 999-99-99',
    code: '+32',
    flag: '🇧🇪',
    min: 'BE'
  },
  {
    en: 'Belize',
    ru: 'Белиз',
    mask: '+501 (99) 999-99-99',
    code: '+501',
    flag: '🇧🇿',
    min: 'BZ'
  },
  {
    en: 'Benin',
    ru: 'Бенин',
    mask: '+22\\91 (9) 999-99-99',
    code: '+2291',
    flag: '🇧🇯',
    min: 'BJ'
  },
  {
    en: 'Bermuda',
    ru: 'Бермудские острова',
    mask: '+1441 (9) 999-99-99',
    code: '+1441',
    flag: '🇧🇲',
    min: 'BM'
  },
  {
    en: "Bolivia",
    ru: "Боливия",
    mask: "+5\\91 (99) 999-99-99",
    code: "+591",
    flag: '🇧🇴',
    min: 'BO'
  },
  {
    en: "Bosnia and Herzegovina",
    ru: "Босния и Герцеговина",
    mask: "+387 (99) 999-99-99",
    code: "+387",
    flag: '🇧🇦',
    min: 'BA'
  },
  {
    en: "Botswana",
    ru: "Ботсвана",
    mask: "+267 (99) 999-99-99",
    code: "+267",
    flag: '🇧🇼',
    min: 'BW'
  },
  {
    en: "Brazil",
    ru: "Бразилия",
    mask: "+55 (99) 999-99-99",
    code: "+55",
    flag: '🇧🇷',
    min: 'BR'
  },
  {
    en: "Bulgaria",
    ru: "Болгария",
    mask: "+35\\9 (99) 999-99-99",
    code: "+359",
    flag: '🇧🇬',
    min: 'BG'
  },
  {
    en: "Burundi",
    ru: "Бурунди",
    mask: "+257 (99) 999-99-99",
    code: "+257",
    flag: '🇧🇮',
    min: 'BI'
  },
  {
    en: "Cambodia",
    ru: "Камбоджа",
    mask: "+855 (99) 999-99-99",
    code: "+855",
    flag: '🇰🇭',
    min: 'KH'
  },
  {
    en: "Cameroon",
    ru: "Камерун",
    mask: "+237 (99) 999-99-99",
    code: "+237",
    flag: '🇨🇲',
    min: 'CM'
  },
  {
    en: "Canada",
    ru: "Канада",
    mask: "+1 (999) 999-99-99",
    code: "+1",
    flag: '🇨🇦',
    min: 'CA'
  },
  {
    en: "Cayman Islands",
    ru: "Каймановы острова",
    mask: "+1345 (9) 999-99-99",
    code: "+1345",
    flag: '🇰🇾',
    min: 'KY'
  },
  {
    en: "Chad",
    ru: "Чад",
    mask: "+235 (99) 999-99-99",
    code: "+235",
    flag: '🇹🇩',
    min: 'TD'
  },
  {
    en: "Chile",
    ru: "Чили",
    mask: "+56 (99) 999-99-99",
    code: "+56",
    flag: '🇨🇱',
    min: 'CL'
  },
  {
    en: "China",
    ru: "Китай",
    mask: "+86 (99) 999-99-99",
    code: "+86",
    flag: '🇨🇳',
    min: 'CN'
  },
  {
    en: "Congo",
    ru: "Конго",
    mask: "+242 (99) 999-99-99",
    code: "+242",
    flag: '🇨🇬',
    min: 'CG'
  },
  {
    en: "Costa Rica",
    ru: "Коста-Рика",
    mask: "+506 (99) 999-99-99",
    code: "+506",
    flag: '🇨🇷',
    min: 'CR'
  },
  {
    en: "Croatia",
    ru: "Хорватия",
    mask: "+385 (99) 999-99-99",
    code: "+385",
    flag: '🇭🇷',
    min: 'HR'
  },
  {
    en: "Cuba",
    ru: "Куба",
    mask: "+53 (99) 999-99-99",
    code: "+53",
    flag: '🇨🇺',
    min: 'CU'
  },
  {
    en: "Cyprus",
    ru: "Кипр",
    mask: "+357 (99) 999-99-99",
    code: "+357",
    flag: '🇨🇾',
    min: 'CY'
  },
  {
    en: "Czech Republic",
    ru: "Чешская республика",
    mask: "+420 (99) 999-99-99",
    code: "+420",
    flag: '🇨🇿',
    min: 'CZ'
  },
  {
    en: "Denmark",
    ru: "Дания",
    mask: "+45 (99) 999-99-99",
    code: "+45",
    flag: '🇩🇰',
    min: 'DK'
  },
  {
    en: "Djibouti",
    ru: "Джибути",
    mask: "+253 (99) 999-99-99",
    code: "+253",
    flag: '🇩🇯',
    min: 'DJ'
  },
  {
    en: "Dominica",
    ru: "Доминика",
    mask: "+1767 (9) 999-99-99",
    code: "+1767",
    flag: '🇩🇲',
    min: 'DM'
  },
  {
    en: "Dominican Republic",
    ru: "Доминиканская республика",
    mask: "+180\\9 (9) 999-99-99",
    code: "+1809",
    flag: '🇩🇴',
    min: 'DO'
  },
  {
    en: "Ecuador",
    ru: "Эквадор",
    mask: "+5\\93 (99) 999-99-99",
    code: "+593",
    flag: '🇪🇨',
    min: 'EC'
  },
  {
    en: "Egypt",
    ru: "Египет",
    mask: "+20 (99) 999-99-99",
    code: "+20",
    flag: '🇪🇬',
    min: 'EG'
  },
  {
    en: "El Salvador",
    ru: "Сальвадор",
    mask: "+503 (99) 999-99-99",
    code: "+503",
    flag: '🇸🇻',
    min: 'SV'
  },
  {
    en: "Equatorial Guinea",
    ru: "Экваториальная Гвинея",
    mask: "+240 (99) 999-99-99",
    code: "+240",
    flag: '🇬🇶',
    min: 'GQ'
  },
  {
    en: "Estonia",
    ru: "Эстония",
    mask: "+372 (99) 999-99-99",
    code: "+372",
    flag: '🇪🇪',
    min: 'EE'
  },
  {
    en: "Ethiopia",
    ru: "Эфиопия",
    mask: "+251 (99) 999-99-99",
    code: "+251",
    flag: '🇪🇹',
    min: 'ET'
  },
  {
    en: "Falkland Islands",
    ru: "Фолклендские острова",
    mask: "+500 (99) 999-99-99",
    code: "+500",
    flag: '🇫🇰',
    min: 'FK'
  },
  {
    en: "Fiji",
    ru: "Фиджи",
    mask: "+67\\9 (99) 999-99-99",
    code: "+679",
    flag: '🇫🇯',
    min: 'FJ'
  },
  {
    en: "Finland",
    ru: "Финляндия",
    mask: "+358 (99) 999-99-99",
    code: "+358",
    flag: '🇫🇮',
    min: 'FI'
  },
  {
    en: "France",
    ru: "Франция",
    mask: "+33 (99) 999-99-99",
    code: "+33",
    flag: '🇫🇷',
    min: 'FR'
  },
  {
    en: "Gabon",
    ru: "Габон",
    mask: "+241 (99) 999-99-99",
    code: "+241",
    flag: '🇬🇦',
    min: 'GA'
  },
  {
    en: "Gambia",
    ru: "Гамбия",
    mask: "+220 (99) 999-99-99",
    code: "+220",
    flag: '🇬🇲',
    min: 'GM'
  },
  {
    en: "Georgia",
    ru: "Грузия",
    mask: "+\\9\\95 (99) 999-99-99",
    code: "+995",
    flag: '🇬🇪',
    min: 'GE'
  },
  {
    en: "Germany",
    ru: "Германия",
    mask: "+4\\9 (99) 999-99-99",
    code: "+49",
    flag: '🇩🇪',
    min: 'DE'
  },
  {
    en: "Ghana",
    ru: "Гана",
    mask: "+233 (99) 999-99-99",
    code: "+233",
    flag: '🇬🇭',
    min: 'GH'
  },
  {
    en: "Gibraltar",
    ru: "Гибралтар",
    mask: "+350 (99) 999-99-99",
    code: "+350",
    flag: '🇬🇮',
    min: 'GI'
  },
  {
    en: "Greece",
    ru: "Греция",
    mask: "+30 (99) 999-99-99",
    code: "+30",
    flag: '🇬🇷',
    min: 'GR'
  },
  {
    en: "Greenland",
    ru: "Гренландия",
    mask: "+2\\9\\9 (99) 999-99-99",
    code: "+299",
    flag: '🇬🇱',
    min: 'GL'
  },
  {
    en: "Grenada",
    ru: "Гренада",
    mask: "+1473 (9) 999-99-99",
    code: "+1473",
    flag: '🇬🇩',
    min: 'GD'
  },
  {
    en: "Guam",
    ru: "Гуам",
    mask: "+1671 (9) 999-99-99",
    code: "+1671",
    flag: '🇬🇺',
    min: 'GU'
  },
  {
    en: "Guatemala",
    ru: "Гватемала",
    mask: "+502 (99) 999-99-99",
    code: "+502",
    flag: '🇬🇹',
    min: 'GT'
  },
  {
    en: "Guinea",
    ru: "Гвинея",
    mask: "+224 (99) 999-99-99",
    code: "+224",
    flag: '🇬🇳',
    min: 'GN'
  },
  {
    en: "Guinea-Bissau",
    ru: "Гвинея Биссау",
    mask: "+245 (99) 999-99-99",
    code: "+245",
    flag: '🇬🇼',
    min: 'GW'
  },
  {
    en: "Guyana",
    ru: "Гайана",
    mask: "+5\\92 (99) 999-99-99",
    code: "+592",
    flag: '🇬🇾',
    min: 'GY'
  },
  {
    en: "Haiti",
    ru: "Гаити",
    mask: "+50\\9 (99) 999-99-99",
    code: "+509",
    flag: '🇭🇹',
    min: 'HT'
  },
  {
    en: "Honduras",
    ru: "Гондурас",
    mask: "+504 (99) 999-99-99",
    code: "+504",
    flag: '🇭🇳',
    min: 'HN'
  },
  {
    en: "Hong Kong",
    ru: "Гонконг",
    mask: "+852 (99) 999-99-99",
    code: "+852",
    flag: '🇭🇰',
    min: 'HK'
  },
  {
    en: "Hungary",
    ru: "Венгрия",
    mask: "+36 (99) 999-99-99",
    code: "+36",
    flag: '🇭🇺',
    min: 'HU'
  },
  {
    en: "Iceland",
    ru: "Исландия",
    mask: "+354 (99) 999-99-99",
    code: "+354",
    flag: '🇮🇸',
    min: 'IS'
  },
  {
    en: "India",
    ru: "Индия",
    mask: "+\\91 (99) 999-99-99",
    code: "+91",
    flag: '🇮🇳',
    min: 'IN'
  },
  {
    en: "Indonesia",
    ru: "Индонезия",
    mask: "+62 (99) 999-99-99",
    code: "+62",
    flag: '🇮🇩',
    min: 'ID'
  },
  {
    en: "Iran",
    ru: "Иран",
    mask: "+\\98 (99) 999-99-99",
    code: "+98",
    flag: '🇮🇷',
    min: 'IR'
  },
  {
    en: "Iraq",
    ru: "Ирак",
    mask: "+\\964 (99) 999-99-99",
    code: "+964",
    flag: '🇮🇶',
    min: 'IQ'
  },
  {
    en: "Irish Republic",
    ru: "Ирландская республика",
    mask: "+353 (99) 999-99-99",
    code: "+353",
    flag: '🇮🇪',
    min: 'IE'
  },
  {
    en: "Israel",
    ru: "Израиль",
    mask: "+\\972 (99) 999-99-99",
    code: "+972",
    flag: '🇮🇱',
    min: 'IL'
  },
  {
    en: "Italy",
    ru: "Италия",
    mask: "+3\\9 (99) 999-99-99",
    code: "+39",
    flag: '🇮🇹',
    min: 'IT'
  },
  {
    en: "Jamaica",
    ru: "Ямайка",
    mask: "+1876 (9) 999-99-99",
    code: "+1876",
    flag: '🇯🇲',
    min: 'JM'
  },
  {
    en: "Japan",
    ru: "Япония",
    mask: "+81 (99) 999-99-99",
    code: "+81",
    flag: '🇯🇵',
    min: 'JP'
  },
  {
    en: "Jordan",
    ru: "Иордания",
    mask: "+\\962 (99) 999-99-99",
    code: "+962",
    flag: '🇯🇴',
    min: 'JO'
  },
  {
    en: "Kazakhstan",
    ru: "Казахстан",
    mask: "+7 (999) 999-99-99",
    code: "+7",
    flag: '🇰🇿',
    min: 'KZ'
  },
  {
    en: "Kenya",
    ru: "Кения",
    mask: "+254 (99) 999-99-99",
    code: "+254",
    flag: '🇰🇪',
    min: 'KE'
  },
  {
    en: "Kiribati Republic",
    ru: "Кирибати",
    mask: "+686 (99) 999-99-99",
    code: "+686",
    flag: '🇰🇮',
    min: 'KI'
  },
  {
    en: "Kirghizia",
    ru: "Кыргызстан",
    mask: "+\\9\\96 (99) 999-99-99",
    code: "+996",
    flag: '🇰🇬',
    min: 'KG'
  },
  {
    en: "Kuwait",
    ru: "Кувейт",
    mask: "+\\965 (99) 999-99-99",
    code: "+965",
    flag: '🇰🇼',
    min: 'KW'
  },
  {
    en: "Laos",
    ru: "Лаос",
    mask: "+856 (99) 999-99-99",
    code: "+856",
    flag: '🇱🇦',
    min: 'LA'
  },
  {
    en: "Latvia",
    ru: "Латвия",
    mask: "+371 (99) 999-99-99",
    code: "+371",
    flag: '🇱🇻',
    min: 'LV'
  },
  {
    en: "Lebanon",
    ru: "Ливан",
    mask: "+\\961 (99) 999-99-99",
    code: "+961",
    flag: '🇱🇧',
    min: 'LB'
  },
  {
    en: "Liberia",
    ru: "Либерия",
    mask: "+231 (99) 999-99-99",
    code: "+231",
    flag: '🇱🇷',
    min: 'LR'
  },
  {
    en: "Liechtenstein",
    ru: "Лихтенштейн",
    mask: "+41 (99) 999-99-99",
    code: "+41",
    flag: '🇱🇮',
    min: 'LI'
  },
  {
    en: "Lithuania",
    ru: "Литва",
    mask: "+370 (99) 999-99-99",
    code: "+370",
    flag: '🇱🇹',
    min: 'LT'
  },
  {
    en: "Luxembourg",
    ru: "Люксембург",
    mask: "+352 (99) 999-99-99",
    code: "+352",
    flag: '🇱🇺',
    min: 'LU'
  },
  {
    en: "Macao",
    ru: "Макао",
    mask: "+853 (99) 999-99-99",
    code: "+853",
    flag: '🇲🇴',
    min: 'MO'
  },
  {
    en: "Macedonia",
    ru: "Македония",
    mask: "+38\\9 (99) 999-99-99",
    code: "+389",
    flag: '🇲🇰',
    min: 'MK'
  },
  {
    en: "Madagascar",
    ru: "Мадагаскар",
    mask: "+261 (99) 999-99-99",
    code: "+261",
    flag: '🇲🇬',
    min: 'MG'
  },
  {
    en: "Malawi",
    ru: "Малави",
    mask: "+265 (99) 999-99-99",
    code: "+265",
    flag: '🇲🇼',
    min: 'MW'
  },
  {
    en: "Malaysia",
    ru: "Малайзия",
    mask: "+60 (99) 999-99-99",
    code: "+60",
    flag: '🇲🇾',
    min: 'MY'
  },
  {
    en: "Maldives",
    ru: "Мальдивы",
    mask: "+\\960 (99) 999-99-99",
    code: "+960",
    flag: '🇲🇻',
    min: 'MV'
  },
  {
    en: "Mali",
    ru: "Мали",
    mask: "+223 (99) 999-99-99",
    code: "+223",
    flag: '🇲🇱',
    min: 'ML'
  },
  {
    en: "Malta",
    ru: "Мальта",
    mask: "+356 (99) 999-99-99",
    code: "+356",
    flag: '🇲🇹',
    min: 'MT'
  },
  {
    en: "Marshall Islands",
    ru: "Маршалловы острова",
    mask: "+6\\92 (99) 999-99-99",
    code: "+692",
    flag: '🇲🇭',
    min: 'MH'
  },
  {
    en: "Martinique",
    ru: "Мартиника",
    mask: "+5\\96 (99) 999-99-99",
    code: "+596",
    flag: '🇲🇶',
    min: 'MQ'
  },
  {
    en: "Mauritania",
    ru: "Мавритания",
    mask: "+222 (99) 999-99-99",
    code: "+222",
    flag: '🇲🇷',
    min: 'MR'
  },
  {
    en: "Mauritius",
    ru: "Маврикий",
    mask: "+230 (99) 999-99-99",
    code: "+230",
    flag: '🇲🇺',
    min: 'MU'
  },
  {
    en: "Mexico",
    ru: "Мексика",
    mask: "+52 (99) 999-99-99",
    code: "+52",
    flag: '🇲🇽',
    min: 'MX'
  },
  {
    en: "Micronesia",
    ru: "Микронезия",
    mask: "+6\\91 (99) 999-99-99",
    code: "+691",
    flag: '🇫🇲',
    min: 'FM'
  },
  {
    en: "Monaco",
    ru: "Монако",
    mask: "+377 (99) 999-99-99",
    code: "+377",
    flag: '🇲🇨',
    min: 'MC'
  },
  {
    en: "Mongolia",
    ru: "Монголия",
    mask: "+\\976 (99) 999-99-99",
    code: "+976",
    flag: '🇲🇳',
    min: 'MN'
  },
  {
    en: "Mozambique",
    ru: "Мозамбик",
    mask: "+258 (99) 999-99-99",
    code: "+258",
    flag: '🇲🇿',
    min: 'MZ'
  },
  {
    en: "Namibia",
    ru: "Намибия",
    mask: "+264 (99) 999-99-99",
    code: "+264",
    flag: '🇳🇦',
    min: 'NA'
  },
  {
    en: "Nauru",
    ru: "Науру",
    mask: "+674 (99) 999-99-99",
    code: "+674",
    flag: '🇳🇷',
    min: 'NR'
  },
  {
    en: "Nepal",
    ru: "Непал",
    mask: "+\\977 (99) 999-99-99",
    code: "+977",
    flag: '🇳🇵',
    min: 'NP'
  },
  {
    en: "Netherlands",
    ru: "Нидерланды",
    mask: "+31 (99) 999-99-99",
    code: "+31",
    flag: '🇳🇱',
    min: 'NL'
  },
  {
    en: "New Caledonia",
    ru: "Новая каледония",
    mask: "+687 (99) 999-99-99",
    code: "+687",
    flag: '🇳🇨',
    min: 'NC'
  },
  {
    en: "New Zealand",
    ru: "Новая зеландия",
    mask: "+64 (99) 999-99-99",
    code: "+64",
    flag: '🇳🇿',
    min: 'NZ'
  },
  {
    en: "Nicaragua",
    ru: "Никарагуа",
    mask: "+505 (99) 999-99-99",
    code: "+505",
    flag: '🇳🇮',
    min: 'NI'
  },
  {
    en: "Niger",
    ru: "Нигер",
    mask: "+227 (99) 999-99-99",
    code: "+227",
    flag: '🇳🇪',
    min: 'NE'
  },
  {
    en: "Nigeria",
    ru: "Нигерия",
    mask: "+234 (99) 999-99-99",
    code: "+234",
    flag: '🇳🇬',
    min: 'NG'
  },
  {
    en: "Yemen",
    ru: "Йемен",
    mask: "+\\967 (99) 999-99-99",
    code: "+967",
    flag: '🇾🇪',
    min: 'YE'
  },
  {
    en: "Northern Mariana Islands",
    ru: "Северно-Марианские острова",
    mask: "+670 (99) 999-99-99",
    code: "+670",
    flag: '🇲🇵',
    min: 'MP'
  },
  {
    en: "Norway",
    ru: "Норвегия",
    mask: "+47 (99) 999-99-99",
    code: "+47",
    flag: '🇳🇴',
    min: 'NO'
  },
  {
    en: "Oman",
    ru: "Оман",
    mask: "+\\968 (99) 999-99-99",
    code: "+968",
    flag: '🇴🇲',
    min: 'OM'
  },
  {
    en: "Pakistan",
    ru: "Пакистан",
    mask: "+\\92 (99) 999-99-99",
    code: "+92",
    flag: '🇵🇰',
    min: 'PK'
  },
  {
    en: "Panama",
    ru: "Панама",
    mask: "+507 (99) 999-99-99",
    code: "+507",
    flag: '🇵🇦',
    min: 'PA'
  },
  {
    en: "Papua New Guinea",
    ru: "Папуа-Новая Гвинея",
    mask: "+675 (99) 999-99-99",
    code: "+675",
    flag: '🇵🇬',
    min: 'PG'
  },
  {
    en: "Paraguay",
    ru: "Парагвай",
    mask: "+5\\95 (99) 999-99-99",
    code: "+595",
    flag: '🇵🇾',
    min: 'PY'
  },
  {
    en: "Peru",
    ru: "Перу",
    mask: "+51 (99) 999-99-99",
    code: "+51",
    flag: '🇵🇪',
    min: 'PE'
  },
  {
    en: "Philippines",
    ru: "Филиппины",
    mask: "+63 (99) 999-99-99",
    code: "+63",
    flag: '🇵🇭',
    min: 'PH'
  },
  {
    en: "Poland",
    ru: "Польша",
    mask: "+48 (99) 999-99-99",
    code: "+48",
    flag: '🇵🇱',
    min: 'PL'
  },
  {
    en: "Portugal",
    ru: "Португалия",
    mask: "+351 (99) 999-99-99",
    code: "+351",
    flag: '🇵🇹',
    min: 'PT'
  },
  {
    en: "Puerto Rico",
    ru: "Пуэрто-Рико",
    mask: "+1787 (9) 999-99-99",
    code: "+1787",
    flag: '🇵🇷',
    min: 'PR'
  },
  {
    en: "Qatar",
    ru: "Катар",
    mask: "+\\974 (99) 999-99-99",
    code: "+974",
    flag: '🇶🇦',
    min: 'QA'
  },
  {
    en: "San Marino",
    ru: "Сан-Марино",
    mask: "+378 (99) 999-99-99",
    code: "+378",
    flag: '🇸🇲',
    min: 'SM'
  },
  {
    en: "Romania",
    ru: "Румыния",
    mask: "+40 (99) 999-99-99",
    code: "+40",
    flag: '🇷🇴',
    min: 'RO'
  },
  {
    en: "Russia",
    ru: "Россия",
    mask: "+7 (999) 999-99-99",
    code: "+7",
    flag: '🇷🇺',
    min: 'RU'
  },
  {
    en: "Rwandese Republic",
    ru: "Республика Руанда",
    mask: "+250 (99) 999-99-99",
    code: "+250",
    flag: '🇷🇼',
    min: 'RW'
  },
  {
    en: "Saudi Arabia",
    ru: "Саудовская Аравия",
    mask: "+\\966 (99) 999-99-99",
    code: "+966",
    flag: '🇸🇦',
    min: 'SA'
  },
  {
    en: "Senegal",
    ru: "Сенегал",
    mask: "+221 (99) 999-99-99",
    code: "+221",
    flag: '🇸🇳',
    min: 'SN'
  },
  {
    en: "Sierra Leone",
    ru: "Сьерра-Леоне",
    mask: "+232 (99) 999-99-99",
    code: "+232",
    flag: '🇸🇱',
    min: 'SL'
  },
  {
    en: "Singapore",
    ru: "Сингапур",
    mask: "+65 (99) 999-99-99",
    code: "+65",
    flag: '🇸🇬',
    min: 'SG'
  },
  {
    en: "Slovakia",
    ru: "Словакия",
    mask: "+421 (99) 999-99-99",
    code: "+421",
    flag: '🇸🇰',
    min: 'SK'
  },
  {
    en: "Slovenia",
    ru: "Словения",
    mask: "+386 (99) 999-99-99",
    code: "+386",
    flag: '🇸🇮',
    min: 'SI'
  },
  {
    en: "Solomon Islands",
    ru: "Соломоновы острова",
    mask: "+677 (99) 999-99-99",
    code: "+677",
    flag: '🇸🇧',
    min: 'SB'
  },
  {
    en: "Somalia",
    ru: "Сомали",
    mask: "+252 (99) 999-99-99",
    code: "+252",
    flag: '🇸🇴',
    min: 'SO'
  },
  {
    en: "South Korea",
    ru: "Южная Корея",
    mask: "+82 (99) 999-99-99",
    code: "+82",
    flag: '🇰🇷',
    min: 'KR'
  },
  {
    en: "Spain",
    ru: "Испания",
    mask: "+34 (99) 999-99-99",
    code: "+34",
    flag: '🇪🇸',
    min: 'ES'
  },
  {
    en: "Sri Lanka",
    ru: "Шри-Ланка",
    mask: "+\\94 (99) 999-99-99",
    code: "+94",
    flag: '🇱🇰',
    min: 'LK'
  },
  {
    en: "Sudan",
    ru: "Судан",
    mask: "+24\\9 (99) 999-99-99",
    code: "+249",
    flag: '🇸🇩',
    min: 'SD'
  },
  {
    en: "Suriname",
    ru: "Суринам",
    mask: "+5\\97 (99) 999-99-99",
    code: "+597",
    flag: '🇸🇷',
    min: 'SR'
  },
  {
    en: "Sweden",
    ru: "Швеция",
    mask: "+46 (99) 999-99-99",
    code: "+46",
    flag: '🇸🇪',
    min: 'SE'
  },
  {
    en: "Switzerland",
    ru: "Швейцария",
    mask: "+41 (99) 999-99-99",
    code: "+41",
    flag: '🇨🇭',
    min: 'CH'
  },
  {
    en: "Tadjikistan",
    ru: "Таджикистан",
    mask: "+\\9\\92 (99) 999-99-99",
    code: "+992",
    flag: '🇹🇯',
    min: 'TJ'
  },
  {
    en: "Taiwan",
    ru: "Тайвань",
    mask: "+886 (99) 999-99-99",
    code: "+886",
    flag: '🇹🇼',
    min: 'TW'
  },
  {
    en: "Tanzania",
    ru: "Танзания",
    mask: "+255 (99) 999-99-99",
    code: "+255",
    flag: '🇹🇿',
    min: 'TZ'
  },
  {
    en: "Tonga",
    ru: "Тонга",
    mask: "+676 (99) 999-99-99",
    code: "+676",
    flag: '🇹🇴',
    min: 'TO'
  },
  {
    en: "Trinidad and Tobago",
    ru: "Тринидад и Тобаго",
    mask: "+1868 (9) 999-99-99",
    code: "+1868",
    flag: '🇹🇹',
    min: 'TT'
  },
  {
    en: "Tunisia",
    ru: "Тунис",
    mask: "+21 (99) 999-99-99",
    code: "+21",
    flag: '🇹🇳',
    min: 'TN'
  },
  {
    en: "Turkey",
    ru: "Турция",
    mask: "+\\90 (99) 999-99-99",
    code: "+90",
    flag: '🇹🇷',
    min: 'TR'
  },
  {
    en: "Turkmenistan",
    ru: "Туркменистан",
    mask: "+\\9\\93 (99) 999-99-99",
    code: "+993",
    flag: '🇹🇲',
    min: 'TM'
  },
  {
    en: "Uganda",
    ru: "Уганда",
    mask: "+256 (99) 999-99-99",
    code: "+256",
    flag: '🇺🇬',
    min: 'UG'
  },
  {
    en: "Ukraine",
    ru: "Украина",
    mask: "+380 (99) 999-99-99",
    code: "+380",
    flag: '🇺🇦',
    min: 'UA'
  },
  {
    en: "United Arab Emirates",
    ru: "О.А.Э.",
    mask: "+\\971 (99) 999-99-99",
    code: "+971",
    flag: '🇦🇪',
    min: 'AE'
  },
  {
    en: "United Kingdom",
    ru: "Великобритания",
    mask: "+44 (99) 999-99-99",
    code: "+44",
    flag: '🇬🇧',
    min: 'GB'
  },
  {
    en: "Uruguay",
    ru: "Уругвай",
    mask: "+5\\98 (99) 999-99-99",
    code: "+598",
    flag: '🇺🇾',
    min: 'UY'
  },
  {
    en: "USA",
    ru: "США",
    mask: "+1 (999) 999-99-99",
    code: "+1",
    flag: '🇺🇸',
    min: 'US'
  },
  {
    en: "Uzbekistan",
    ru: "Узбекистан",
    mask: "+\\9\\98 (99) 999-99-99",
    code: "+998",
    flag: '🇺🇿',
    min: 'UZ'
  },
  {
    en: "Vatican City",
    ru: "Ватикан",
    mask: "+3\\9 (99) 999-99-99",
    code: "+39",
    flag: '🇻🇦',
    min: 'VA'
  },
  {
    en: "Venezuela",
    ru: "Венесуэла",
    mask: "+58 (99) 999-99-99",
    code: "+58",
    flag: '🇻🇪',
    min: 'VE'
  },
  {
    en: "Vietnam",
    ru: "Вьетнам",
    mask: "+84 (99) 999-99-99",
    code: "+84",
    flag: '🇻🇳',
    min: 'VN'
  },
  {
    en: "Western Sahara",
    ru: "Западная Сахара",
    mask: "+21 (99) 999-99-99",
    code: "+21",
    flag: '🇪🇭',
    min: 'EH'
  },
  {
    en: "Zambia",
    ru: "Замбия",
    mask: "+260 (99) 999-99-99",
    code: "+260",
    flag: '🇿🇲',
    min: 'ZM'
  },
  {
    en: "Zimbabwe",
    ru: "Зимбабве",
    mask: "+263 (99) 999-99-99",
    code: "+263",
    flag: '🇿🇼',
    min: 'ZW'
  }
]