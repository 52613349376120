import React from 'react'
import { darkB } from "../../cda-UIkit/constants/colors"

const DataScienceIcon = ({size = '24', color = darkB, style}) => (
  <svg width={24} height={24} viewBox={`0 0 ${24} ${24}`} fill="none" xmlns="http://www.w3.org/2000/svg"
    style={{
      ...style,
      transform: `scale(${size / 24})`,
      transformOrigin: 'center'
    }}
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M16 3H6C3.791 3 2 4.791 2 7V7C2 9.209 3.791 11 6 11H16C18.209 11 20 9.209 20 7V7C20 4.791 18.209 3 16 3Z" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6 11C3.791 11 2 12.791 2 15V15C2 17.209 3.791 19 6 19H13" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10 7H16" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.0355 6.96548C6.0555 6.98548 6.0555 7.01648 6.0355 7.03648C6.0155 7.05648 5.9845 7.05648 5.9645 7.03648C5.9445 7.01648 5.9445 6.98548 5.9645 6.96548C5.9845 6.94548 6.0165 6.94548 6.0355 6.96548" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10 15H12.1" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.0355 14.9655C6.0555 14.9855 6.0555 15.0165 6.0355 15.0365C6.0155 15.0565 5.9845 15.0565 5.9645 15.0365C5.9445 15.0165 5.9445 14.9855 5.9645 14.9655C5.9845 14.9455 6.0165 14.9455 6.0355 14.9655" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M17 11C14.239 11 12 13.239 12 16C12 18.761 14.239 21 17 21C19.762 21 22 18.761 22 16C22 13.239 19.762 11 17 11" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M17 18.08V18.5" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M17 13.92V13.5" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M14.8395 17.2495L15.1995 17.0395" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M19.1605 14.7505L18.8005 14.9605" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15.1995 14.9605L14.8395 14.7505" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M19.1605 17.2495L18.8005 17.0395" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
);

export default DataScienceIcon;