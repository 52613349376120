import React from 'react'
import { darkB } from "../../cda-UIkit/constants/colors"

const ComputerVisionIcon = ({size = '24', color = darkB, style}) => (
  <svg width={24} height={24} viewBox={`0 0 ${24} ${24}`} fill="none" xmlns="http://www.w3.org/2000/svg"
    style={{
      ...style,
      transform: `scale(${size / 24})`,
      transformOrigin: 'center'
    }}
  >
  <path fill-rule="evenodd" clip-rule="evenodd" d="M16 10H19C20.105 10 21 9.105 21 8V5C21 3.895 20.105 3 19 3H16C14.895 3 14 3.895 14 5V8C14 9.105 14.895 10 16 10Z" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M4 18.5H9C9.552 18.5 10 18.052 10 17.5V15C10 14.448 9.552 14 9 14H4C3.448 14 3 14.448 3 15V17.5C3 18.052 3.448 18.5 4 18.5Z" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M8.5 21H4.5" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M20.5 15V17C20.5 18.105 19.605 19 18.5 19H15" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M17 17L15 19L17 21" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M7 7L9 5L7 3" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M3.5 9V7C3.5 5.895 4.395 5 5.5 5H9" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
);

export default ComputerVisionIcon;