import React from 'react'
import { darkB } from "../../cda-UIkit/constants/colors"

const ClosedIcon = ({size = '24', color = darkB, style}) => (
  <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17 21H7C5.895 21 5 20.105 5 19V11C5 9.895 5.895 9 7 9H17C18.105 9 19 9.895 19 11V19C19 20.105 18.105 21 17 21Z" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12 17.09V14.5" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.5303 13.2197C12.8232 13.5126 12.8232 13.9874 12.5303 14.2803C12.2374 14.5732 11.7626 14.5732 11.4697 14.2803C11.1768 13.9874 11.1768 13.5126 11.4697 13.2197C11.7626 12.9268 12.2374 12.9268 12.5303 13.2197" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8 9V7V7C8 4.791 9.791 3 12 3V3C14.209 3 16 4.791 16 7V7V9" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
);

export default ClosedIcon;