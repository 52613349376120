import React from 'react'
import { Checkbox, FormControlLabel, makeStyles, Switch, withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { darkB, defaultB, greyB, greyC, primaryC, secondaryB } from '../constants/colors'
import CdaTypography from '../Typography/CdaTypography'

const styles = {
  root: {
    height: '20px',
    width: '40px',
    padding: 0,
  },
  switchBase: {
    padding: 2,
    '&$checked + $track': {
      background: primaryC,
      opacity: 1
    }
  },
  checked: {
  },
  track: {
    borderRadius: '10px',
    background: greyB,
  },
  thumb: {
    width: '16px',
    height: '16px',
    background: defaultB,
    boxShadow: 'none',
  }
}
const StyledSwitch = withStyles(styles)(({ classes, ...other }) => (
  <Switch
    {...other}
    disableRipple
    classes={{
      root: classes.root,
      switchBase: classes.switchBase,
      thumb: classes.thumb,
      track: classes.track,
      checked: classes.checked,
    }}
    
  />
))

const CdaSwitch = ({ label, ...props }) => {
  const useStyle=makeStyles(theme=>({
    root:{
      marginTop: '3px',
      marginLeft: !props?.labelPlacement || props?.labelPlacement === 'end' ? '8px' : 0,
      marginRight: props?.labelPlacement === 'start' ? '8px' : 0,
      cursor: 'pointer',
      userSelect: 'none'
    }
  }))
  const classes=useStyle()
  return (
    <FormControlLabel
      classes={{label:classes.root}}
      control={<StyledSwitch {...props} />} 
      label={!!label &&
        <CdaTypography label={label} color={!!props.disabled ? 'grey' : 'classic'} type={'s'} style={{cursor: !props.disabled && 'pointer'}} />
      }
      {...props}
    />
  )
}

export default CdaSwitch

CdaSwitch.propTypes = {
  color: PropTypes.oneOf(['default', 'theme']),
  label: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func
}
