export const defaultB = '#FFFFFF'
export const primaryB = '#1A5698'
export const primaryC = '#2B6BB2'
export const primaryD = '#6090C5'
export const secondaryB = '#E6ECF2'
export const secondaryC = '#D4E8FF'
export const darkB = '#04162C'
export const greyB = '#C0C5CA'
export const greyC = '#E6E8EA'
export const cloudB = '#F2F3F7'
export const positiveB = '#31D08D'
export const positiveC = '#E7FEED'
export const negativeB = '#FF2C2C'
export const negativeC = '#FEE4E2'
export const warningB = '#F1C40E'
export const warningC = '#fff8d9'
export const purpleB = '#BD6AF6'
export const purpleC = '#F6E7FA'
export const orangeB = '#F79009'
