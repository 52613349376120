import React from 'react'
import { darkB } from "../../../cda-UIkit/constants/colors"

const Telegram = ({size = '29', color = darkB, style}) => (
  <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 4H22.0053C25.316 4 28 6.684 28 9.99467V22.0067C28 25.316 25.316 28 22.0053 28H9.99467C6.684 28 4 25.316 4 22.0053V10C4 6.68667 6.68667 4 10 4V4Z" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.4588 20.6547L12.3761 17.0894C12.3748 17.088 9.27475 16.1214 9.27475 16.1214C8.92008 16.0107 8.90275 15.5134 9.25075 15.38L21.0521 10.828C21.4881 10.66 21.9374 11.0427 21.8414 11.5L19.8588 20.844C19.7428 21.392 19.0988 21.636 18.6494 21.3027L15.8334 19.2227L14.1734 20.8387C13.9401 21.0627 13.5534 20.9627 13.4588 20.6547V20.6547Z" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
);

export default Telegram;