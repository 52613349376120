import React from 'react'
import { darkB } from "../../cda-UIkit/constants/colors"

const LanguageIcon = ({size = '30', color = darkB, style}) => (
  <div style={style}>
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16 4.1333C22.2227 10.7026 22.2227 21.2973 16 27.8666" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M16 27.8666C9.77734 21.2973 9.77734 10.7026 16 4.1333" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M16 4C22.6427 4 28 9.35733 28 16C28 22.6427 22.6427 28 16 28" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M16 28C9.35733 28 4 22.6427 4 16C4 9.35733 9.35733 4 16 4" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M4.67969 12.0002H27.3197" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M4.67969 20.0002H27.3197" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </div>
);
  
export default LanguageIcon;