import React from 'react'
import { Avatar, Card, CardActionArea, CardContent, Grid, withStyles } from '@material-ui/core'
import {
	secondaryB,
	negativeC,
	positiveC,
	warningC,
	defaultB,
	cloudB,
  greyC,
  positiveB,
  primaryB,
  darkB,
  secondaryC,
  primaryC,
} from '../constants/colors'
import PropTypes from 'prop-types'
import '../fonts/style.css'
import CdaTypography from '../Typography/CdaTypography'
import CdaLinearProgress from '../Progresses/CdaLinearProgress'
import { useTranslation } from 'react-i18next'
import CheckIcon from '@material-ui/icons/Check';
import VisableIcon from '../../img/icons/VisableIcon';
import ClosedIcon from '../../img/icons/ClosedIcon';
import LibraryBooksOutlinedIcon from '@material-ui/icons/LibraryBooksOutlined';

const styles = {
	root: {
		background: defaultB,
    padding: '0px',
		borderRadius: '16px',
    borderBottomLeftRadius: '0px',
    border: 'none'
	},
  content: {
    padding: '0px'
  }
}

const CdaVideoCard = withStyles(styles)(({ classes, icon, label, clicable, complited, i, ii, ...other }) => {
  const { t, i18n } = useTranslation()

  const Content = () => (
    <CardContent className={classes.content}>
      <Avatar 
        style={{width: '100%', height: '144px', pointerEvents: 'none', backgroundColor: primaryC, 
          borderRadius: 16, filter: !clicable ? 'blur(3px) opacity(0.5)' : 'none'
        }}
        alt={''} 
        src={icon}
        disabled
      >
        <Grid container
          justifyContent={'center'}
          alignItems={'center'}
          style={{height: 160}}
        >
          <LibraryBooksOutlinedIcon style={{height: 40, width: 40}}/>
        </Grid>
      </Avatar>
      <CdaTypography 
        type={'s'} 
        color={!!clicable ? 'classic' : 'grey'} label={`${i + 1}.${ii + 1} ${label}`} 
        style={{marginTop: '8px', userSelect: 'none'}}
      />
      <Grid container alignItems={'center'} justify={'center'} style={{
        position: 'absolute',
        zIndex: 3,
        top: !!clicable ? '12px' : '20px', 
        left: !!clicable ? '12px' : '20px', 
        height: '32px', 
        width: '32px', 
        borderRadius: '16px',
        background: !!complited ? positiveB : (!!clicable ? primaryB : darkB)
      }}>
        {!!complited ?
          <CheckIcon style={{width: 24, height: 24, color: '#FFF'}} /> :
          !!clicable ?
            <VisableIcon color={'#FFF'} /> :
            <ClosedIcon color={'#FFF'} />
        }
      </Grid>
    </CardContent>
  )

  return(
		<Card
      elevation={0}
      clicable
			className={classes.root} 
			{...other} 
    >
      {!!clicable ?
        <CardActionArea>{Content()}</CardActionArea> :
        Content()
      } 
    </Card>
  );
})

export default CdaVideoCard;

CdaVideoCard.propTypes = {
  icon: PropTypes.node,
  clicable: PropTypes.bool,
  complited: PropTypes.bool,
  label: PropTypes.object,
  i: PropTypes.number,
  ii: PropTypes.number,
}
