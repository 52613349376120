import React, {useEffect, useState} from 'react'
import { Avatar, Grid } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import CdaTypography from '../cda-UIkit/Typography/CdaTypography';
import moment from 'moment/moment';
import { history } from '../lib/history';
import { cardLine } from '../lib/helpers'
import CdaButton from '../cda-UIkit/Buttons/CdaButton';
import HomeIcon from '../img/HomeIcon.svg';
import { getCoursesInfo, getProfsCoursesInfo, getProfsInfo, getTeachers } from '../requests/courses';
import 'moment/locale/ru';
import IconConnector from '../components/IconConnector';
import CdaCardHome from '../cda-UIkit/Cards/CdaCardHome';
import ShevronIcon from '../img/icons/ShevronIcon';
import { primaryB, primaryC, secondaryC } from '../cda-UIkit/constants/colors';
import MetaIcon from '../img/partners/MetaIcon';
import MetaSvg from '../img/partners/Meta.svg'
import CdaTextField from '../cda-UIkit/Inputs/CdaTextField';
import Footer from '../components/Footer';
import Prof from '../img/prof.png'
import PhoneInput from '../lib/universalPhones/PhoneInput';
import CdaInstructorCard from '../cda-UIkit/Cards/CdaInstructorCard'
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import InstructorsCarousel from '../components/InstructorsCarousel';
import ReviewCarousel from '../components/ReviewCarousel';
import FeedBackForm from '../components/FeedBackForm';
import { getReviewes } from '../requests/students';
import CdaCardDiscount from '../cda-UIkit/Cards/CdaCardDiscount';
import IconFree from '../img/forEmployer/IconFree';
import IconCv from '../img/forEmployer/IconCv';
import IconQuality from '../img/forEmployer/IconQuality';
import CdaBetterCard from '../cda-UIkit/Cards/CdaBetterCard';
import IconRating from '../img/forEmployer/IconRating';
import SnakeRoadmap from '../components/roadmap/SnakeRoadmap';
import theBest from '../img/whyWe/theBest.svg'
import grants from '../img/whyWe/grants.svg'
import rating from '../img/whyWe/rating.svg'
import teachers from '../img/whyWe/teachers.svg'
import brain from '../img/whyWe/brain.svg'
import book from '../img/whyWe/book.svg'
import { partners } from '../lib/constants';

const Home = ({ CW }) => {
  const { t } = useTranslation()
  const [courses, setCourses] = useState([])
  const [reviewesCarousel, setReviewesCarousel] = useState(0)
  const [instructorsCarousel, setInstructorsCarousel] = useState(0)
  const [reviewes, setReviewes] = useState([])
  const [instructors, setInstructors] = useState([])
  const betterAdds = [
    theBest,
    book,
    rating,
    grants,
    brain,
    teachers
  ]

  const reviewesSwitch = (orient) => !!reviewes && setReviewesCarousel(reviewesCarousel + (!!orient ? 1 : -1))
  const instructorsSwitch = (orient) => !!instructors && setInstructorsCarousel(instructorsCarousel + (!!orient ? 1 : -1))

  useEffect(() => {
    getProfsInfo().then(r => {
      if(!!r?.length) {
        setCourses(r/*?.sort((a,b) => a.id > b.id ? 1 : -1)*/)
      }
    })
    getReviewes().then(r => {
      if(!!r?.length) {
        setReviewes(r)
      }
    })
    moment.locale(localStorage.lang)
  }, [localStorage.lang])

  useEffect(() => {
    getTeachers().then(r => setInstructors(r))
  }, [])

  return (
    <Grid container 
      direction={'column'} 
      alignItems={'center'} 
      justify={'space-between'}
      style={{minHeight: '100vh', width: '100vw'}}
    >
      <Grid item container 
        direction={'row'} 
        alignItems={'flex-start'} 
        justify={'center'}
        style={{
          padding: CW > 1070 ? '64px' : '56px 8px', 
          overflowX: 'hidden', 
          overflowY: 'hidden', 
          maxWidth: 1600
        }}
        spacing={6}
      >
        <Grid item container 
          direction={'row'} 
          alignItems={'flex-start'}
          justify={'space-between'}
          style={{
            background: CW < 1175 && secondaryC,
            borderRadius: 24,
            margin: CW < 1070 && 24,
            marginBottom: CW < 1175 && 36,
            marginTop: CW < 1070 && 0
          }}
        >
          <Grid item xs={12} md={6}
            container
            direction={'column'}
            alignItems={'flex-start'} 
            justify={'flex-start'}
            spacing={3}
            style={{
              marginTop: CW > 1175 && 40
            }}
          >
            <Grid item>
              <CdaTypography type={CW > 850 ? 'h2' : 'subTitle'} 
                color={'classic'} 
                label={t('home.title')} 
                style={{fontWeight: 600}}
                mobile
              />
            </Grid>
            <Grid item>
              <CdaTypography type={'bm'} 
                color={'classic'} 
                label={t('home.subTitle')}
              />
            </Grid>
            <Grid item>
              <CdaButton size={'l'} 
                color={'primary'}
                onClick={() => history.push('/carear_test')}
              >
                {t('home.titleButton')}
              </CdaButton>
            </Grid>
          </Grid>
          {CW > 1175 &&
            <Grid item>
              <img src={HomeIcon} style={{height: 420, marginRight: CW > 1375 ? 40 : 0}} />
            </Grid>
          }
        </Grid>
        <Grid item xs={12} md={12}
          container 
          direction={'row'} 
          justify={'flex-start'} 
          alignItems={'flex-start'} 
          spacing={2}
          style={{marginBottom: CW < 1070 ? -44 : 0}}
        >
          <Grid item xs={12} md={12}>
            <CdaTypography type={'subTitle'} color={'classic'} style={{fontWeight: 600}} label={t('home.betterTitle')} />
          </Grid>
          <Grid item xs={12} md={12}
            container 
            direction={'row'} 
            alignItems={'flex-start'} 
            justify={'flex-start'}
            spacing={2}
            style={{marginBottom: 16}}
          >
            {betterAdds.map((b, i) => 
              <Grid item xs={12} md={4}>
                <CdaBetterCard icon={b} title={t('home.betterCardTitle.' + i)} text={t('home.betterCardText.' + i)} elevation={0} img />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} md={12}
          container 
          direction={'row'} 
          justify={'flex-start'} 
          alignItems={'flex-start'} 
          spacing={2}
          style={{marginBottom: CW < 1070 ? 0 : 32}}
        >
          <Grid item xs={12} md={12}>
            <CdaTypography type={'h3'} label={t('home.popCourses')} style={{fontWeight: 600, marginBottom: 12}} />
          </Grid>
          <Grid item xs={12} md={12} 
            container 
            direction={'row'} 
            justify={'center'} 
            alignItems={'center'} 
            spacing={CW < 650 ? 0 : 4}
          >
            {courses?.map((c, i) => 
              <Grid item 
              style={{marginBottom: (CW < 650 && i !== courses.length - 1) ? 12 : 0}}>
                <CdaCardHome
                  CW={CW}
                  elevation={0}
                  style={{
                    boxShadow: '0px 0px 15px rgba(74, 85, 104, 0.1)', 
                    width: CW < 1600 ? (CW > 850 ? '41vw' : '100%') : 640
                  }}
                  onClick={() => !!c?.is_course ? history.push('/course/' + c.id) : history.push('/profession/' + c.id)} 
                  icon={!!c?.logo && 
                    <IconConnector 
                      icon={JSON.parse(c?.logo)?.icon} 
                      color={JSON.parse(c?.logo)?.color}
                      size={CW > 1160 ? 68 : 40}
                    />
                  } 
                  color={!!c?.logo && JSON.parse(c?.logo)?.color} 
                  label={c?.name} 
                  info={!!c?.duration && (
                    (moment(c?.duration).from('1970-01-01').replace(/[^\d]/g, '') === '' ? '1 ' : '') +
                    (localStorage.lang !== 'kz' ?
                      moment(c?.duration).from('1970-01-01')
                        .replace('через ', '')
                        .replace('in ', '')
                        .replace('a ', '')
                      :
                      moment(c?.duration).from('1970-01-01')
                        .replace('через ', '')
                        .replace('in ', '')
                        .replace('года', 'жыл')
                        .replace('год', 'жыл')
                        .replace('лет', 'жыл')
                        .replace('месяцев', 'ай')
                        .replace('месяца', 'ай')
                        .replace('месяц', 'ай')
                        .replace('a year', 'жыл')
                        .replace('years', 'жыл')
                        .replace('a month', 'ай')
                        .replace('months', 'ай')
                    )
                  )}
                />
              </Grid>
            )}
            {/*
            <Grid item xs={12} md={12} container direction={'row'} justify={'center'}>
              <CdaButton size={'l'} color={'tertiary'} onClick={() => ''}>
                {t('home.viewAll')}
                <ShevronIcon color={primaryB} size={'20'} style={{marginLeft: 8, marginRight: -6}}/>
              </CdaButton>
            </Grid>*/}
          </Grid>
        </Grid>
        {/*CW < 1070 &&
          <Grid item xs={12}>
            <CdaCardDiscount
              mobile
              elevation={2}
              onClick={() => history.push('/grants')} 
              color={'white'} 
              label={t('header.grants')}
              info={''}
            />
          </Grid>
        */}
        {/*<SnakeRoadmap />*/}
        <Grid item xs={12} md={12}
          container 
          direction={'row'} 
          justify={'flex-start'} 
          alignItems={'flex-start'} 
          spacing={2}
          style={{marginBottom: CW < 1070 ? 24 : 64}}
        >
          <InstructorsCarousel
            title={t('home.instructors')}
            subTitle={t('home.instructorsHeader')}
            instructors={instructors}
            CW={CW}
          />
        </Grid>
        <Grid item xs={12} md={12}
          container 
          direction={'row'} 
          justify={'space-between'} 
          alignItems={'flex-start'}
          spacing={CW > 850 ? 8 : (CW > 369 ? 4 : 0)}
          style={{
            borderRadius: 24,
            backgroundColor: primaryC,
            margin: CW < 1070 && 24,
            marginTop: CW < 1070 && 0
          }}
        >
          <Grid item xs={12} md={6}
            container
            direction={'column'}
            alignItems={'flex-start'}
            justify={'space-between'}
            spacing={10}
            style={{minHeight: CW > 950 && '508px'}}
          >
            <Grid item>
              <CdaTypography 
                type={CW > 850 ? 'h2' : 'subTitle'} 
                color={'white'} 
                label={t('home.profOrientation')} 
                style={{fontWeight: 600}}
              />
            </Grid>
            {CW > 950 &&
              <Grid item>
                <CdaButton
                  color={'secondary'}
                  size={'l'}
                  onClick={() => history.push('carear_test')}
                >
                  {t('home.startTest')}
                  <ShevronIcon 
                    color={primaryB} 
                    size={'20'} 
                    style={{
                      transform: 'rotate(-90deg)', 
                      marginLeft: 8,
                      marginTop: 3, 
                      marginRight: -10
                    }}
                  />
                </CdaButton>
              </Grid>
            }
          </Grid>
          <Grid item>
            <img src={Prof} height={CW > 950 ? '400px' : (CW > 369 ? '280px' : '240px')} style={{marginRight: CW > 950 && '5vw'}}/>
          </Grid>
          {CW <= 950 &&
            <Grid item xs={12}>
              <CdaButton
                fullWidth
                color={'secondary'}
                size={'l'}
                onClick={() => history.push('carear_test')}
              >
                {t('home.startTest')}
                <ShevronIcon 
                  color={primaryB} 
                  size={'20'} 
                  style={{
                    transform: 'rotate(-90deg)', 
                    marginLeft: 8,
                    marginTop: 3, 
                    marginRight: -10
                  }}
                />
              </CdaButton>
            </Grid>
          }
        </Grid>
        <Grid item xs={12} md={12}
          container 
          direction={'row'} 
          justify={'flex-start'} 
          alignItems={'flex-start'}
          spacing={2}
          style={{marginTop: CW > 900 ? 64 : 12, marginBottom: 24}}
        >
          <Grid item xs={12} md={12}>
            <CdaTypography type={'h3'} label={t('home.partners')} style={{fontWeight: 600, marginBottom: 12}}/>
          </Grid>
          <Grid item xs={12} md={12}
            container 
            direction={'row'} 
            justify={'center'} 
            alignItems={'center'}
            spacing={CW > 950 ? 8 : (CW > 369 ? 4 : 2)}
            style={{padding: CW > 950 ? 36 : 0, paddingTop: CW > 950 ? 36 : 18}}
          >
            {partners.map(p =>
              <Grid item>
                <img src={p} height={CW > 950 ? '36px' : '24px'}/>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} md={12}
          container 
          direction={'row'} 
          justify={'flex-start'} 
          alignItems={'flex-start'}
          spacing={2}
          style={{marginBottom: CW < 1070 ? 24 : 64}}
        >
          <ReviewCarousel
            title={t('home.reviewes') + t('product')}
            reviewes={reviewes}
            CW={CW}
          />
        </Grid>
        <FeedBackForm
          title={t('home.chooseHelp')}
          subTitle={t('home.chooseHelpInfo')}
          CW={CW}
          page={'home'}
        />
      </Grid>
      <Footer CW={CW} />
    </Grid>
  );
}

export default Home;