import React from 'react'
import CdaTextField from './CdaTextField'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const CdaSelect = props => {
	return (
		<CdaTextField
			{...props}
			select
			SelectProps={{ IconComponent: ExpandMoreIcon }}
		/>
	)
}
export default CdaSelect