import React from 'react'
import { Typography, withStyles  } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import '../fonts/style.css'
import { greyB, darkB, negativeB, positiveB, primaryB, warningB, cloudB, defaultB } from '../constants/colors'


const styledBy = (property, mapping) => props => mapping[props[property]]

const styles = {
  root: {
    fontFamily: 'GT Eesti Pro Display',
    fontStyle: 'normal',
    minWidth: '0px', 
    letterSpacing: styledBy('type', {
			h1: '0.2px',
      labelUp: '1px',
		}),
    lineHeight: styledBy('type', {
			h1: '72px',
			h2: '56px',
			h3: '36px',
			subTitle: '36px',
			l: '26px',
      bm: '24px',
      br: '24px',
      s: '20px',
      c: '14px',
      label: '16px',
      labelUp: '16px',
		}) || '18px',
    fontWeight: styledBy('type', {
			h1: 500,
			h2: 500,
			h3: 500,
			subTitle: 500,
			l: 400,
      bm: 500,
      br: 400,
      s: 400,
      c: 400,
      label: 400,
      labelUp: 400,
		}) || 400,
    fontSize: styledBy('type', {
			h1: '64px',
			h2: '48px',
			h3: '32px',
			subTitle: '32px',
			l: '22px',
      bm: '18px',
      br: '18px',
      s: '16px',
      c: '12px',
      label: '14px',
      labelUp: '14px',
		}) || '18px',
		color: styledBy('color', {
			classic: darkB,
			primary: primaryB,
			positive: positiveB,
			negative: negativeB,
			warning: warningB,
      grey: greyB,
      cloud: cloudB,
      white: defaultB
		}) || darkB,
    cursor: 'default',
    textTransform: styledBy('type', {
			h1: 'none',
			h2: 'none',
			h3: 'none',
			subTitle: 'none',
			l: 'none',
      bm: 'none',
      br: 'none',
      s: 'none',
      c: 'none',
      label: 'none',
      labelUp: 'uppercase',
		}) || 'none',
    '&:hover': {
      textDecoration: styledBy('link', {
        [true]: 'underline',
        [false]: 'none',
      }) || 'none',
      cursor: styledBy('link', {
        [true]: 'pointer',
        [false]: 'default',
      }) || 'default',
    }
  }
}
const CdaTypography = withStyles(styles)(({ label, color, type, align, link, ihtml, ...props }) => {
  return (
    !!ihtml ?
      <Typography className={styles.root} align={align} {...props} dangerouslySetInnerHTML={!!ihtml && { __html: label }} />
    :
      <Typography className={styles.root} align={align} {...props} >
        {!ihtml ? label : ''}
      </Typography>
  )
})
export default CdaTypography
CdaTypography.propTypes = {
	color: PropTypes.oneOf([
		'classic',
		'primary',
		'positive',
		'negative',
		'warning',
		'grey',
		'cloud',
    'white'
	]),
  type: PropTypes.oneOf([
		'h1',
		'h2',
		'h3',
		'subTitle',
		'l',
		'bm',
		'br',
    's',
    'c',
    'label',
    'labelUp'
	]),
	label: PropTypes.string,
  align: PropTypes.string,
  link:  PropTypes.oneOf([
		true,
		false
	]),
}