import React, {useEffect, useState} from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Card, CardContent, Grid, withStyles } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import CdaTypography from '../cda-UIkit/Typography/CdaTypography';
import 'moment/locale/ru';
import { cloudB, defaultB, greyB, greyC, primaryC } from '../cda-UIkit/constants/colors';
import Footer from '../components/Footer';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = {
	root: {
		background: defaultB,
    padding: '0px',
		borderRadius: '24px',
    border: 'none',
    width: '100%'
	},
  content: {
    padding: '16px',
    paddingBottom: '16px !important'
  }
}

const Faq = withStyles(styles)(({ classes, CW }) => {
  const { t } = useTranslation()
  const [expanded, setExpanded] = useState(false)

  useEffect(() => {
    window.scroll(0,0)
  }, [])

  return (
    <Grid container 
      direction={'column'} 
      alignItems={'center'} 
      justify={'space-between'}
      style={{minHeight: '100vh', width: '100vw'}}
    >
      <Grid item container 
        direction={'row'} 
        alignItems={'flex-start'} 
        justify={'center'}
        style={{padding: CW > 1070 ? '64px' : '56px 36px', overflowX: 'hidden', overflowY: 'hidden', maxWidth: 1600}}
        spacing={6}
      >
        <Grid item xs={12} md={12}>
          <CdaTypography type={'h2'} label={t('faq.title')} style={{fontWeight: 600}} />
        </Grid>
        <Grid item xs={12} md={12}
          container 
          direction={'row'} 
          alignItems={'flex-start'} 
          justify={'center'}
        >
          {[0,1,2,3,4,5,6].map((l, i) =>
            <Grid item xs={12} md={12} 
              style={{
                borderBottom: '1px solid' + greyC,
                borderTop: i === 0 ? '1px solid' + greyC : 'none'
              }}
            >
              <Accordion elevation={0} expanded={expanded === i} onChange={() => setExpanded(expanded !== i ? i : false)}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                >
                  <CdaTypography type={'bm'} label={t('faq.accTitle1')} />
                </AccordionSummary>
                <AccordionDetails>
                  <Grid item xs={12} md ={12}>
                    <CdaTypography 
                      type={'bm'} 
                      label={t('faq.accContent1')} 
                      style={{
                        textIndent: '20px',
                      }}
                    />
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item style={{width: '100%'}}>
        <Footer CW={CW} />
      </Grid>
    </Grid>
  );
})

export default Faq;