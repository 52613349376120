import React, {useEffect, useState} from 'react'
import { Avatar, Card, CardContent, Grid, withStyles } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import CdaTypography from '../cda-UIkit/Typography/CdaTypography';
import 'moment/locale/ru';
import { defaultB, secondaryC } from '../cda-UIkit/constants/colors';
import Footer from '../components/Footer';
import FeedBackForm from '../components/FeedBackForm';
import CdaButton from '../cda-UIkit/Buttons/CdaButton';
import MetaIcon from '../img/partners/MetaIcon';
import profTestBg from '../img/profTestBg.jpg';
import b1 from '../img/b2b/b1.png';
import b2 from '../img/b2b/b2.png';
import b3 from '../img/b2b/b3.png';
import b4 from '../img/b2b/b4.png';
import b5 from '../img/b2b/b5.png';
import c1 from '../img/b2b/c1-transparent.jpg';
import c2 from '../img/b2b/c2-transparent.jpg';
import StudentsCarousel from '../components/StudentsCarousel';
import { getStudentsForCv } from '../requests/students';
import IconFree from '../img/forEmployer/IconFree';
import IconQuality from '../img/forEmployer/IconQuality';
import IconCv from '../img/forEmployer/IconCv';
import ShevronIcon from '../img/icons/ShevronIcon';
import { toElem } from '../lib/helpers';
import { partners } from '../lib/constants';

const styles = {
  root: {
    background: defaultB,
    padding: '0px',
    borderRadius: '12px',
    width: '100%',
  },
  content: {
    padding: '16px'
  },
  avatar: {
    borderRadius: 0
  },
  card: {
    background: 'transparent',
    padding: '24px',
    paddingBottom: '16px !important',
    height: '400px'
  }
}

const BToB = withStyles(styles)(({ classes, CW }) => {
  const { t } = useTranslation()
  const [students, setStudents] = useState([])

  useEffect(() => {
    window.scroll(0,0)
  }, [])

  useEffect(() => {
    getStudentsForCv().then(r => !!r?.length && setStudents(r))
  }, [localStorage.lang])

  return (
    
    <Grid container 
      direction={'column'} 
      alignItems={'center'} 
      justify={'space-between'}
      style={{minHeight: '100vh', width: '100vw'}}
    >
      <Grid item container 
        direction={'row'} 
        alignItems={'flex-start'} 
        justify={'center'}
        style={{padding: CW > 1070 ? '64px' : '56px 8px', overflowX: 'hidden', overflowY: 'hidden', maxWidth: 1600}}
        spacing={6}
      >
        <Grid item xs={12} md={12} container 
          direction={'row'} 
          alignItems={'center'} 
          justify={'space-around'} 
          style={{
            borderRadius: 24, 
            backgroundColor: secondaryC, 
            minHeight: CW > 960 && 400,
            position: 'relative',
            overflow: 'hidden',
            margin: '0 24px 0 24px'
          }}
        >
          <Grid item xs={12} md={6} container 
            direction={'row'} 
            alignItems={'center'} 
            justify={'flex-start'}
            spacing={2} 
          >
            <Grid item xs={12} md={12}>
              <CdaTypography type={CW > 850 ? 'h2' : 'subTitle'} color={'classic'} label={t('b2b.title')} style={{fontWeight: 600}} />
            </Grid>
            <Grid item xs={12} md={12}>
              <CdaTypography type={'bm'} color={'classic'} label={t('b2b.subTitle')} />
            </Grid>
            <Grid item>
              <CdaButton
                color={'primary'}
                size={'l'}
                onClick={() => toElem('feedBack')}
              >
                {t('b2b.getIt')}
                <ShevronIcon 
                  color={defaultB} 
                  size={'20'} 
                  style={{
                    transform: 'rotate(-90deg)', 
                    marginLeft: 8,
                    marginTop: 3, 
                    marginRight: -10
                  }}
                />
              </CdaButton>
            </Grid>
          </Grid>
          {CW > 960 &&
            <>
              <Grid item xs={12} md={4}>
                
              </Grid>
              <Grid container direction={'column'} spacing={2} 
                style={{position: 'absolute', zIndex: 0, top: -150, right: -(CW > 1600 ? (1600 / 1.7) : (CW > 1600 ? (1600 / 1.7) : (CW / 1.7)))}}
              >
                <Grid item>
                  <Avatar
                    alt={''} 
                    src={b1} 
                    style={{
                      height: 220, 
                      width: 200,
                      borderRadius: 16
                    }} 
                  />
                </Grid>
                <Grid item>
                  <Avatar
                    alt={''} 
                    src={b2} 
                    style={{
                      height: 220, 
                      width: 200,
                      borderRadius: 16
                    }} 
                  />
                </Grid>
                <Grid item>
                  <Avatar
                    alt={''} 
                    src={b3} 
                    style={{
                      height: 220, 
                      width: 200,
                      borderRadius: 16
                    }} 
                  />
                </Grid>
              </Grid>
              <Grid container direction={'column'} spacing={2} 
                style={{position: 'absolute', zIndex: 0, top: -50, right: -((CW > 1600 ? (1600 / 1.7) : (CW / 1.7)) + 224)}}
              >
                <Grid item>
                  <Avatar
                    alt={''} 
                    src={b4} 
                    style={{
                      height: 220, 
                      width: 200,
                      borderRadius: 16
                    }} 
                  />
                </Grid>
                <Grid item>
                  <Avatar
                    alt={''} 
                    src={b5} 
                    style={{
                      height: 220, 
                      width: 200,
                      borderRadius: 16
                    }} 
                  />
                </Grid>
                <Grid item>
                  <Avatar
                    alt={''} 
                    src={b1} 
                    style={{
                      height: 220, 
                      width: 200,
                      borderRadius: 16
                    }} 
                  />
                </Grid>
              </Grid>
            </>
          }
        </Grid>
        <Grid item xs={12} md={12}
          container
          direction={'row'}
          alignItems={'flex-start'}
          justify={'flex-start'}
          spacing={2}
          style={{marginTop: 24}}
        >
          <Grid item xs={12} md={7}>
            <CdaTypography type={CW > 850 ? 'h2' : 'subTitle'} color={'classic'} label={t('b2b.ourTaskTitle')} style={{fontWeight: 600}} />
          </Grid>
          <Grid item xs={12} md={7}>
            <CdaTypography type={'l'} color={'classic'} label={t('b2b.ourSubTaskTitle')} />
          </Grid>
        </Grid>
      </Grid>
        <Grid item container 
          direction={'row'} 
          alignItems={'center'} 
          justify={'center'}
          style={{
            overflowX: 'hidden', 
            overflowY: 'hidden', 
            maxWidth: '100vw',
            background: secondaryC
          }}
        >
          <Grid item container 
            direction={'row'} 
            alignItems={'flex-start'} 
            justify={'center'}
            style={{
              padding: CW > 1070 ? '36px 64px' : '36px 8px', 
              overflowX: 'hidden', 
              overflowY: 'hidden', 
              maxWidth: 1600,
            }}
            spacing={2}
          >
            <Grid item xs={12} md={12} style={{margin: CW > 1070 ? 0 : '0 8px 0 8px'}}>
              <CdaTypography type={'subTitle'} color={'classic'} style={{fontWeight: 600}} label={t('b2b.why')} />
            </Grid>
            <Grid item xs={12} md={12}
              container 
              direction={'row'} 
              alignItems={'flex-start'} 
              justify={'flex-start'}
              spacing={2}
            >
              <Grid item xs={12} md={3}>
                <Card
                  elevation={2}
                  className={classes.root}
                >
                  <CardContent className={classes.content}>
                    <Grid container
                      direction={'column'}
                      alignItems={'flex-start'}
                      justify={'center'}
                      spacing={2}
                      style={{minHeight: 280}}
                    >
                      <Grid item>
                        <CdaTypography type={'l'} color={'classic'} 
                          label={t('b2b.why1Title')}
                          style={{fontWeight: 600}}
                        />
                      </Grid>
                      <Grid item>
                        <CdaTypography type={'bm'} color={'classic'} label={t('b2b.why1Content')} />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={3}>
                <Card
                  elevation={2}
                  className={classes.root}
                >
                  <CardContent className={classes.content}>
                    <Grid container
                      direction={'column'}
                      alignItems={'flex-start'}
                      justify={'center'}
                      spacing={2}
                      style={{minHeight: 280}}
                    >
                      <Grid item>
                        <CdaTypography type={'l'} color={'classic'} 
                          label={t('b2b.why2Title')}
                          style={{fontWeight: 600}}
                        />
                      </Grid>
                      <Grid item>
                        <CdaTypography type={'bm'} color={'classic'} label={t('b2b.why2Content')} />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={3}>
                <Card
                  elevation={2}
                  className={classes.root}
                >
                  <CardContent className={classes.content}>
                    <Grid container
                      direction={'column'}
                      alignItems={'flex-start'}
                      justify={'center'}
                      spacing={2}
                      style={{minHeight: 280}}
                    >
                      <Grid item>
                        <CdaTypography type={'l'} color={'classic'} 
                          label={t('b2b.why3Title')}
                          style={{fontWeight: 600}}
                        />
                      </Grid>
                      <Grid item>
                        <CdaTypography type={'bm'} color={'classic'} label={t('b2b.why3Content')} />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={3}>
                <Card
                  elevation={2}
                  className={classes.root}
                >
                  <CardContent className={classes.content}>
                    <Grid container
                      direction={'column'}
                      alignItems={'flex-start'}
                      justify={'center'}
                      spacing={2}
                      style={{minHeight: 280}}
                    >
                      <Grid item>
                        <CdaTypography type={'l'} color={'classic'} 
                          label={t('b2b.why4Title')}
                          style={{fontWeight: 600}}
                        />
                      </Grid>
                      <Grid item>
                        <CdaTypography type={'bm'} color={'classic'} label={t('b2b.why4Content')} />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container 
          direction={'row'} 
          alignItems={'flex-start'} 
          justify={'center'}
          style={{padding: CW > 1070 ? '64px' : '56px 8px', overflowX: 'hidden', overflowY: 'hidden', maxWidth: 1600}}
          spacing={6}
        >
          <Grid item xs={12} md={12}
            container 
            direction={'row'} 
            alignItems={'flex-start'} 
            justify={'flex-start'}
            spacing={2}
          >
            <Grid item xs={12} md={12}>
              <CdaTypography type={'subTitle'} color={'classic'} style={{fontWeight: 600}} label={t('b2b.paymentTitle')} />
            </Grid>
            <Grid item xs={12} md={12}
              container
              direction={'row'}
              alignItems={'center'}
              justify={'center'}
              spacing={CW > 950 ? 8 : 4}
              style={{marginTop: -8}}
            >
              <Grid item xs={12} md={6} style={{marginRight: CW < 950 && -32}}>
                <Card
                  fullWidth
                  elevation={12}
                  className={classes.root}
                  style={{ borderRadius: 24, position: 'relative', background: `url(${profTestBg}) bottom`}}
                >
                  <Avatar
                    alt={''} 
                    src={c1}
                    style={{
                      height: '100%', 
                      width: '100%', 
                      position: 'absolute',
                      borderRadius: 16
                    }}
                  />
                  <CardContent className={classes.card} id={'onbuy'}>
                    <CdaTypography type={'subTitle'} color={'white'} style={{fontWeight: 600}} label={t('b2b.paymentCourseTitle')} />
                  </CardContent>
                </Card>
              </Grid>              
              <Grid item xs={12} md={6} style={{marginRight: CW < 950 && -32}}>
                <Card
                  fullWidth
                  elevation={12}
                  className={classes.root}
                  style={{ borderRadius: 24, position: 'relative', background: `url(${profTestBg}) bottom`}}
                >
                  <Avatar
                    alt={''} 
                    src={c2}
                    style={{
                      height: '100%', 
                      width: '100%', 
                      position: 'absolute',
                      borderRadius: 16 
                    }}
                  />
                  <CardContent className={classes.card} id={'onbuy'}>
                    <CdaTypography type={'subTitle'} color={'white'} style={{fontWeight: 600}} label={t('b2b.paymentProfTitle')} />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}
            container 
            direction={'row'} 
            alignItems={'flex-start'} 
            justify={'flex-start'}
            spacing={2}
          >
            <Grid item xs={12} md={12}>
              <CdaTypography type={'subTitle'} color={'classic'} style={{fontWeight: 600}} label={t('carearCentre.partnersTitle')} />
            </Grid>
            <Grid item xs={12} md={12}>
              <CdaTypography type={'bm'} color={'classic'} label={t('carearCentre.partnersSubTitle')} />
            </Grid>
            <Grid item xs={12} md={12}
              container 
              direction={'row'} 
              justify={'center'} 
              alignItems={'center'}
              spacing={CW > 950 ? 8 : (CW > 369 ? 4 : 2)}
              style={{padding: CW > 950 ? 36 : 0, paddingTop: CW > 950 ? 36 : 18, marginBottom: 36}}
            >
              {partners.map(p =>
                <Grid item>
                  <img src={p} height={CW > 950 ? '36px' : '24px'}/>
                </Grid>
              )}
            </Grid>
          </Grid>
          <FeedBackForm
            id={'feedBack'}
            title={t('chooseHelpForm.feedBack')}
            subTitle={t('home.chooseHelpInfo')}
            CW={CW}
            color={secondaryC}
            page={'b2b'}
          />
        </Grid>
      <Footer CW={CW} />
    </Grid>
  );
})

export default BToB;