import React from 'react'
import { darkB } from "../../../cda-UIkit/constants/colors"

const Instagram = ({size = '26', color = darkB, style}) => (
  <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.99467 1H19.0067C22.316 1 25 3.68267 25 6.99467V19.0067C25 22.316 22.3173 25 19.0053 25H6.99467C3.684 25 1 22.3173 1 19.0053V6.99467C1 3.684 3.68267 1 6.99467 1V1Z" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M19.5986 5.95068C19.3506 5.95201 19.1493 6.15334 19.1493 6.40134C19.1493 6.64934 19.3519 6.85068 19.5999 6.85068C19.8479 6.85068 20.0493 6.64934 20.0493 6.40134C20.0506 6.15201 19.8479 5.95068 19.5986 5.95068" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16.3943 9.60584C18.2688 11.4804 18.2688 14.5195 16.3943 16.3941C14.5198 18.2686 11.4806 18.2686 9.60609 16.3941C7.73157 14.5195 7.73157 11.4804 9.60609 9.60584C11.4806 7.73132 14.5198 7.73132 16.3943 9.60584" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
);

export default Instagram;